import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { toast, ToastContainer } from "react-toastify";
import FilterDashboardAdmin from "../../../component/FilterDashboardAdmin";
import NavBar from "../../../component/Navbar";
import {
  BASE_URL,
  E_AUTH_STATUS,
  E_FETCH_STATUS,
  MONTH_FULL,
} from "../../../constant";
import { DefaultSelection } from "../../../interfaces";
import { E_FILTER_STATUS } from "../../../interfaces/salesreport";
import {
  loadSalesReportList,
  setSalesReportCurrentPage,
} from "../../../redux/actions/salesreport";
import {
  salesReportCurrentPageSelector,
  salesReportListSelector,
  salesReportStatusLoadingSelector,
  salesReportTotalPageSelector,
} from "../../../redux/selectors/salesreport";
import SalesReportService from "../../../services/SalesReport";

interface DashboardAdminProps {
  statusUser: E_AUTH_STATUS;
}

const dateObj = new Date();
const currentYear = dateObj.getFullYear();
const currentMonth = dateObj.getMonth() + 1;
const maximumYear = currentYear - 25;

const DashboardAdmin: FC<DashboardAdminProps> = (props) => {
  const { statusUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Filter by Periode
  const [filterMonth, setFilterMonth] = useState<number>(currentMonth);
  const [filterYear, setFilterYear] = useState<number>(currentYear);
  //

  // Real Filter
  const [filterCreatedBy, setFilterCreatedBy] = useState<DefaultSelection>({
    value: 0,
    label: "All Sales",
  });
  const [filterStatus, setFilterStatus] = useState<E_FILTER_STATUS>(
    E_FILTER_STATUS.ALL
  );
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState<string>("");
  const [filterSalesReportNumber, setFilterSalesReportNumber] =
    useState<string>("");

  const [idSelected, setIdSelected] = useState<number>(0);
  const [approveModalShowing, setApproveModalShowing] =
    useState<boolean>(false);
  const [rejectModalShowing, setRejectModalShowing] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>("");
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [lightBoxSrc, setLightBoxSrc] = useState<string>("");
  const salesReportList = useSelector(salesReportListSelector);
  const currentPage = useSelector(salesReportCurrentPageSelector);
  const totalPage = useSelector(salesReportTotalPageSelector);
  const statusLoading = useSelector(salesReportStatusLoadingSelector);

  const viewSalesReport = (id: number) => {
    navigate(`/sales-report/${id}`);
  };

  const showRejectModal = (id: number) => {
    setIdSelected(id);
    setRejectModalShowing(true);
  };

  const showApproveModal = (id: number) => {
    setIdSelected(id);
    setApproveModalShowing(true);
  };

  const openLightBox = (src: string) => {
    setLightBoxOpen(true);
    setLightBoxSrc(src);
  };

  const closeLightBox = () => {
    setLightBoxOpen(false);
    setLightBoxSrc("");
  };

  const rejectSalesReport = async () => {
    if (rejectMessage === "") {
      toast.error("Harap isi alasan penolakan laporan penjualan!");
      return false;
    }
    const body = new FormData();
    body.append("rejectMessage", rejectMessage);
    await SalesReportService.rejectSalesReport(idSelected, body)
      .then((res) => {
        if (res.status === 1) {
          toast.success("Berhasil menolak laporan penjualan!");
          window.location.reload();
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const approveSalesReport = async () => {
    await SalesReportService.approveSalesReport(idSelected)
      .then((res) => {
        if (res.status === 1) {
          toast.success("Berhasil melakukan approval pada laporan penjualan!");
          window.location.reload();
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const handleRejectMessageChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setRejectMessage(value);
  };

  const renderYear = () => {
    let elementReturn: JSX.Element | JSX.Element[] = [];
    for (let i = currentYear; i >= maximumYear; i--) {
      elementReturn.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return elementReturn;
  };

  // Filter by Period Handler
  const changeFilterYear = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterYear(Number(value));
  };

  const changeFilterMonth = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterMonth(Number(value));
  };
  //

  // Real Filter Handler
  const handleCreatedByFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterCreatedBy(e);
    }
  };

  const handleStatusFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterStatus(Number(value) as E_FILTER_STATUS);
  };

  const handleInvoiceNumberFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterInvoiceNumber(value);
  };

  const handleSalesReportNumberFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterSalesReportNumber(value);
  };

  const applyFilter = () => {
    dispatch(
      loadSalesReportList(
        filterMonth,
        filterYear,
        filterCreatedBy?.value,
        filterInvoiceNumber,
        filterSalesReportNumber,
        filterStatus
      )
    );
  };

  const resetFilter = () => {
    resetFilterState();
    dispatch(loadSalesReportList(filterMonth, filterYear));
  };

  const resetFilterState = () => {
    setFilterCreatedBy({ value: 0, label: "All Sales" });
    setFilterStatus(E_FILTER_STATUS.ALL);
    setFilterInvoiceNumber("");
    setFilterSalesReportNumber("");
  };

  const renderSalesReportActionButton = (status: number, id: number) => {
    switch (status) {
      case 1:
        return (
          <>
            &nbsp;
            {statusUser === E_AUTH_STATUS.SUPERVISOR && (
              <>
                <Button variant="danger" onClick={() => showRejectModal(id)}>
                  Reject
                </Button>
                &nbsp;
                <Button variant="success" onClick={() => showApproveModal(id)}>
                  Approve
                </Button>
              </>
            )}
          </>
        );
      case 2:
        return (
          <>
            {statusUser === E_AUTH_STATUS.SUPERADMIN && (
              <>
                &nbsp;
                <Button variant="danger" onClick={() => showRejectModal(id)}>
                  Reject
                </Button>
                &nbsp;
                <Button variant="success" onClick={() => showApproveModal(id)}>
                  Approve
                </Button>
              </>
            )}
          </>
        );
      default:
        return (
          <>
            <p className="text-center">-</p>
          </>
        );
    }
  };

  const renderStatusButton = (status: E_FILTER_STATUS) => {
    switch (status) {
      case E_FILTER_STATUS.PENDING:
        return (
          <Button variant="outline-primary" disabled>
            Waiting For Supervisor Approval
          </Button>
        );
      case E_FILTER_STATUS.APPROVED_BY_SUPERVISOR:
        return (
          <Button variant="success" disabled>
            Approved by Supervisor
          </Button>
        );
      case E_FILTER_STATUS.APPROVED_BY_ADMIN:
        return (
          <Button variant="success" disabled>
            Approved by Admin
          </Button>
        );
      case E_FILTER_STATUS.REJECTED_BY_SUPERVISOR:
        return (
          <Button variant="danger" disabled>
            Rejected by Supervisor
          </Button>
        );
      case E_FILTER_STATUS.REJECTED_BY_ADMIN:
        return (
          <Button variant="danger" disabled>
            Rejected by Admin
          </Button>
        );
    }
  };

  const getTableSalesReport = () => {
    return salesReportList.map((salesReport, index) => {
      return (
        <tr key={salesReport.id}>
          <td>{index + 1}</td>
          <td>{salesReport.salesReportNumber}</td>
          <td>{salesReport.createdBy}</td>
          <td>{salesReport.invoiceNumber}</td>
          <td style={{ textAlign: "center" }}>
            {renderStatusButton(Number(salesReport.status) as E_FILTER_STATUS)}
          </td>
          <td className="text-center">
            <img
              alt={salesReport.attachmentFile}
              src={`${BASE_URL}${salesReport.attachmentFile}`}
              className="imageTable"
              onClick={() =>
                openLightBox(`${BASE_URL}${salesReport.attachmentFile}`)
              }
            />
          </td>
          <td>{Number(salesReport.status) === 1 ? "" : salesReport.message}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => viewSalesReport(salesReport.id)}
              >
                View Sales Report
              </Button>
              {renderSalesReportActionButton(
                Number(salesReport.status),
                salesReport.id
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableSalesReport = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          salesReportList.length > 0 ? (
            getTableSalesReport()
          ) : (
            <tr>
              <td colSpan={8} style={{ textAlign: "center" }}>
                No Sales Report found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={8}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={8} style={{ textAlign: "center" }}>
              No Sales Report Found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Nomor Sales Report</th>
            <th>Dibuat Oleh</th>
            <th>Nomor Invoice</th>
            <th>Status</th>
            <th>Attachment</th>
            <th>Message</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  useEffect(() => {
    dispatch(loadSalesReportList(filterMonth, filterYear));
  }, [filterYear, filterMonth]);

  return (
    <>
      {lightBoxOpen && (
        <Lightbox mainSrc={lightBoxSrc} onCloseRequest={closeLightBox} />
      )}
      <NavBar />
      <br />
      <Container fluid>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Periode */}
        <Row>
          <h3>Periode Sales Report </h3>
          <Col xs={6}>
            <Row className="align-items-center d-flex">
              <Col xs={4}>Tahun</Col>
              <Col xs={8}>
                <Form.Select onChange={changeFilterYear} value={filterYear}>
                  {renderYear()}
                </Form.Select>
              </Col>
            </Row>
          </Col>

          <Col xs={6}>
            <Row className="align-items-center d-flex">
              <Col xs={4}>Bulan</Col>
              <Col xs={8}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={changeFilterMonth}
                  value={filterMonth}
                >
                  {MONTH_FULL.filter((dat, index) =>
                    /* Kalau bulan ditahun terpilih belom sampe desember, ya gak usah ditampilin semua */
                    filterYear === currentYear ? index < currentMonth : dat
                  ).map((month, index) => (
                    <option value={index + 1} key={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Periode */}
        <hr />
        {/* Filter */}
        <FilterDashboardAdmin
          createdBy={filterCreatedBy}
          status={filterStatus}
          invoiceNumber={filterInvoiceNumber}
          salesReportNumber={filterSalesReportNumber}
          handleCreatedByFilterChange={handleCreatedByFilterChange}
          handleStatusFilterChange={handleStatusFilterChange}
          handleInvoiceNumberFilterChange={handleInvoiceNumberFilterChange}
          handleSalesReportNumberFilterChange={
            handleSalesReportNumberFilterChange
          }
          resetFilter={resetFilter}
          applyFilter={applyFilter}
        />

        {/* <Nav variant="tabs">
          <Nav.Item className="nav-item-report">
            <Nav.Link
              active={currentNav === DASHBOARD_NAV.NEW_SALES_REPORT}
              onClick={() => setCurrentNav(DASHBOARD_NAV.NEW_SALES_REPORT)}
            >
              New Sales Report
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item-report">
            <Nav.Link
              active={currentNav === DASHBOARD_NAV.APPROVED_BY_SUPERVISOR}
              onClick={() =>
                setCurrentNav(DASHBOARD_NAV.APPROVED_BY_SUPERVISOR)
              }
            >
              Supervisor Approved
            </Nav.Link>
          </Nav.Item>
        </Nav> */}
        <br />
        <Row>
          <Col>{renderTableSalesReport()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(1));
                    dispatch(
                      loadSalesReportList(
                        filterMonth,
                        filterYear,
                        filterCreatedBy?.value,
                        filterInvoiceNumber,
                        filterSalesReportNumber,
                        filterStatus
                      )
                    );
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(currentPage - 1));
                    dispatch(
                      loadSalesReportList(
                        filterMonth,
                        filterYear,
                        filterCreatedBy?.value,
                        filterInvoiceNumber,
                        filterSalesReportNumber,
                        filterStatus
                      )
                    );
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                    type="text"
                    className="pagination-number"
                    value={currentPage}
                    onChange={(e) => changeCurrentPageNumber(e)}
                  /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(currentPage + 1));
                    dispatch(
                      loadSalesReportList(
                        filterMonth,
                        filterYear,
                        filterCreatedBy?.value,
                        filterInvoiceNumber,
                        filterSalesReportNumber,
                        filterStatus
                      )
                    );
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(totalPage));
                    dispatch(
                      loadSalesReportList(
                        filterMonth,
                        filterYear,
                        filterCreatedBy?.value,
                        filterInvoiceNumber,
                        filterSalesReportNumber,
                        filterStatus
                      )
                    );
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        onHide={() => {
          setRejectMessage("");
          setRejectModalShowing(false);
        }}
        show={rejectModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Reject Sales Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Masukan Alasan Penolakan</Form.Label>
            <Form.Control
              as="textarea"
              value={rejectMessage}
              placeholder="Alasan Penolakan..."
              onChange={handleRejectMessageChange}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setRejectModalShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              rejectSalesReport();
            }}
          >
            Tolak
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        onHide={() => {
          setApproveModalShowing(false);
        }}
        show={approveModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Approve Sales Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Apakah anda yakin ingin mengapprove laporan penjualan ini?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setApproveModalShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              approveSalesReport();
            }}
          >
            Ya
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DashboardAdmin;

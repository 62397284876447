import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { SingleValue } from "react-select";
import FilterBrand from "../../component/FilterBrand";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { BrandData } from "../../interfaces/brand";
import BrandService from "../../services/Brand";

const Brand = () => {
  const [statusLoading, setStatusLoading] = useState<E_FETCH_STATUS>(
    E_FETCH_STATUS.INITIATE
  );
  const [brandList, setBrandList] = useState<BrandData[]>([]);
  const [deleteBrandModalShowing, setDeleteBrandModalShowing] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");

  const [addBrandModalShowing, setAddBrandModalShowing] =
    useState<boolean>(false);
  const [newNameBrand, setNewNameBrand] = useState<string>("");

  // Filter State
  const [filterBrandName, setFilterBrandName] = useState<DefaultSelection>({
    value: 0,
    label: "All Brand",
  });

  const openAddBrandModal = () => {
    setAddBrandModalShowing(true);
  };

  const deleteBrand = (id: number) => {
    BrandService.deleteBrand(id)
      .then((res) => {
        if (res.status === 1) {
          alert("Type has been deleted successfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const createNewBrand = () => {
    const bodyForm = new FormData();
    bodyForm.append("newNameType", newNameBrand);
    BrandService.createBrand(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("New type has been created successfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const changeNewNameBrand = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewNameBrand(value);
  };

  const openDeleteBrandModal = (id: number, name: string) => {
    setDeleteBrandModalShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
  };

  const getTableBrand = () => {
    return brandList.map((brand, index) => {
      return (
        <tr key={brand.id}>
          <td>{brand.id}</td>
          <td>{brand.name}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={() => openDeleteBrandModal(brand.id, brand.name)}
              >
                Delete Brand
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableBrand = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          brandList.length > 0 ? (
            getTableBrand()
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Brand Found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={3}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              No Brand Found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Brand Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const getBrandList = async () => {
    if (filterBrandName.value !== 0) {
      await BrandService.getBrand(filterBrandName.value).then((res) => {
        setStatusLoading(E_FETCH_STATUS.FETCHING);
        if (res.data) {
          setBrandList(res.data);
          setStatusLoading(E_FETCH_STATUS.FETCHED);
        }
      });
    } else {
      await BrandService.getBrand().then((res) => {
        setStatusLoading(E_FETCH_STATUS.FETCHING);
        if (res.data) {
          setBrandList(res.data);
          setStatusLoading(E_FETCH_STATUS.FETCHED);
        }
      });
    }
  };

  // Filter Handler
  const handleBrandNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterBrandName(e);
    }
  };

  const applyFilter = () => {
    getBrandList();
  };

  const resetFilter = () => {
    setFilterBrandName({
      value: 0,
      label: "All Brand",
    });
    getBrandList();
  };
  //

  useEffect(() => {
    getBrandList();
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="10" xs="12" className="d-flex justify-content-end">
            <Button onClick={openAddBrandModal}>
              <FaPlus /> Add New Brand
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterBrand
          brandName={filterBrandName}
          handleBrandNameFilterChange={handleBrandNameFilterChange}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableBrand()}</Col>
        </Row>
      </Container>
      <Modal
        onHide={() => {
          setNewNameBrand("");
          setAddBrandModalShowing(false);
        }}
        show={addBrandModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Nama Tipe</Form.Label>
          <Form.Control
            type="text"
            placeholder="Type Name"
            value={newNameBrand}
            onChange={(e) => changeNewNameBrand(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setNewNameBrand("");
              setAddBrandModalShowing(false);
            }}
            variant="outline-primary"
          >
            Close
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              createNewBrand();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Confirmation Delete */}
      <Modal
        onHide={() => {
          setDeleteBrandModalShowing(false);
        }}
        show={deleteBrandModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete {nameToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Brand?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteBrandModalShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              deleteBrand(idToBeDeleted);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Brand;

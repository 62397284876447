import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { FaFileExcel, FaPlus } from "react-icons/fa";
import { useDispatch } from "react-redux";
import NavBar from "../../component/Navbar";
import { PackageData } from "../../interfaces/package";
import { loadPackageList } from "../../redux/actions/packages";

const Packages: FC = () => {
  const [isModalUploadShowing, setIsModalUploadShowing] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const fileRef = useRef<HTMLInputElement>(null);
  const [isModalAddShowing, setIsModalAddShowing] = useState<boolean>(false);
  const [fileValue, setFileValue] = useState<string>("");
  const [packagesList, setPackagesList] = useState<PackageData[]>([]);

  const openUploadPackagesModal = () => {
    setIsModalUploadShowing(true);
  };

  const openAddPackagesModal = () => {};

  const closeUploadModal = () => {
    setIsModalUploadShowing(false);
  };

  const onFileChange = (event: any) => {
    event.preventDefault();
    if (fileRef.current) {
      setFileValue(fileRef.current.value);
    }
  };

  const triggerFileExplorer = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  useEffect(() => {
    dispatch(loadPackageList());
  }, []);

  const uploadFile = () => {
    const body = new FormData();
    if (fileRef.current && fileRef.current.files) {
      body.append("file", fileRef.current.files[0]);
      // ProductService.uploadProducts(body).then((data) => {
      //   if (data.status === 1) {
      //     alert("Data has been uploaded successfully");
      //     window.location.reload();
      //   }
      // });
    }
  };

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="10" xs="12" className="d-flex justify-content-end">
            <Button onClick={openUploadPackagesModal}>
              <FaPlus /> Upload Package
            </Button>
            &nbsp;
            <Button onClick={openAddPackagesModal}>
              <FaPlus /> Add New Package
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={isModalUploadShowing} onHide={closeUploadModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Package</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Button variant="primary" onClick={triggerFileExplorer}>
            <FaFileExcel />
            Upload Package
          </Button>
          <input
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={onFileChange}
            ref={fileRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeUploadModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={uploadFile}
            disabled={fileValue === ""}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Packages;

import { API_URL, BASE_URL } from "../constant";
import {
  ManagerGetResponse,
  SalesGetResponse,
  UserCreateResponse,
  UserDeactivateResponse,
  UserDetailGetResponse,
  UserEditResponse,
  UserGetResponse,
} from "../interfaces/user";
import { getCookie } from "../utils/cookies";

class UserService {
  public static async getUserList(params?: string): Promise<UserGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getUserList}${params || ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: UserGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getSalesList(): Promise<SalesGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.getSalesList}`, {
      method: "GET",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
    });

    const response: SalesGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getManagerList(
    currentUserId?: number
  ): Promise<ManagerGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getManagerList}${
        currentUserId ? `/${currentUserId}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ManagerGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getAreaSupervisorList(
    currentUserId?: number
  ): Promise<ManagerGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getAreaSupervisorList}${
        currentUserId ? `/${currentUserId}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ManagerGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getSupervisorList(
    currentUserId?: number
  ): Promise<ManagerGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getSupervisorList}${
        currentUserId ? `/${currentUserId}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ManagerGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createNewUser(
    body: FormData
  ): Promise<UserCreateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createNewUser}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });
    const response: UserCreateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    } else {
      return response;
    }
  }
  public static async getUserDetail(
    id: number
  ): Promise<UserDetailGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getDetailUser}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: UserDetailGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async editUser(
    id: number,
    body: FormData
  ): Promise<UserEditResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.editUser}/${id}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: UserEditResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteUser(
    idUser: number
  ): Promise<UserDeactivateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deactivateUser}/${idUser}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: UserDeactivateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default UserService;

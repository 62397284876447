import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { E_FILTER_STATUS } from "../../interfaces/salesreport";
import UserService from "../../services/User";

interface FilterDashboardAdminProps {
  createdBy: DefaultSelection;
  status: E_FILTER_STATUS;
  invoiceNumber: string;
  salesReportNumber: string;
  handleCreatedByFilterChange: (e: SingleValue<DefaultSelection>) => void;
  handleStatusFilterChange: (e: ChangeEvent) => void;
  handleInvoiceNumberFilterChange: (e: ChangeEvent) => void;
  handleSalesReportNumberFilterChange: (e: ChangeEvent) => void;
  resetFilter: () => void;
  applyFilter: () => void;
}

const FilterDashboardAdmin: FC<FilterDashboardAdminProps> = (props) => {
  const {
    createdBy,
    status,
    invoiceNumber,
    salesReportNumber,
    handleCreatedByFilterChange,
    handleStatusFilterChange,
    handleInvoiceNumberFilterChange,
    handleSalesReportNumberFilterChange,
    resetFilter,
    applyFilter,
  } = props;

  const [salesList, setSalesList] = useState<DefaultSelection[]>([]);

  const getSelection = async () => {
    await UserService.getSalesList().then((res) => {
      if (res.data) {
        const sales: DefaultSelection[] = res.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setSalesList(sales);
      }
    });
  };

  useEffect(() => {
    getSelection();
  }, []);

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <h3>Filter</h3>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Sales</Form.Label>
              <Select
                options={salesList}
                value={createdBy}
                onChange={handleCreatedByFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nomor Invoice</Form.Label>
              <Form.Control
                type="text"
                placeholder="Invoice Number"
                value={invoiceNumber}
                onChange={handleInvoiceNumberFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nomor Sales Report</Form.Label>
              <Form.Control
                type="text"
                placeholder="Sales Report Number"
                value={salesReportNumber}
                onChange={handleSalesReportNumberFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Status</Form.Label>
              <Form.Select value={status} onChange={handleStatusFilterChange}>
                <option value={E_FILTER_STATUS.ALL}>All</option>
                <option value={E_FILTER_STATUS.PENDING}>Processing</option>
                <option value={E_FILTER_STATUS.APPROVED_BY_ADMIN}>
                  Approved By Admin
                </option>
                <option value={E_FILTER_STATUS.APPROVED_BY_SUPERVISOR}>
                  Approved By Supervisor
                </option>
                <option value={E_FILTER_STATUS.REJECTED_BY_ADMIN}>
                  Rejected By Admin
                </option>
                <option value={E_FILTER_STATUS.REJECTED_BY_SUPERVISOR}>
                  Rejected By Supervisor
                </option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col
            md={{ offset: 2, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterDashboardAdmin;

import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { BrandSelectData } from "../../interfaces/brand";
import Select, { SingleValue } from "react-select";
import BrandService from "../../services/Brand";
import { ComissionUserSelection } from "../../interfaces/comission";
import UserService from "../../services/User";

interface FilterComissionTargetProps {
  filterBrand: number;
  filterUser: ComissionUserSelection;
  changeFilterBrand: (e: ChangeEvent) => void;
  changeFilterUser: (e: SingleValue<ComissionUserSelection>) => void;
  resetFilter: () => void;
  applyFilter: () => void;
}

const FilterComissionTarget: FC<FilterComissionTargetProps> = ({
  filterBrand,
  filterUser,
  changeFilterBrand,
  changeFilterUser,
  resetFilter,
  applyFilter,
}) => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState<BrandSelectData[]>([]);
  const [userList, setUserList] = useState<ComissionUserSelection[]>([]);

  const getSelection = async () => {
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrand(brandSelection);
      }
    });
    await UserService.getSalesList().then((res) => {
      if (res.data) {
        const userSelection: ComissionUserSelection[] = [
          {
            value: 0,
            label: "All User",
          },
        ];
        res.data.forEach((user) => {
          userSelection.push({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          });
        });
        setUserList(userSelection);
      }
    });
  };

  useEffect(() => {
    getSelection();
  }, []);

  const renderBrand = () => {
    return (
      brand.length > 0 &&
      brand.map((bran) => {
        return (
          <option value={bran.value} key={bran.value}>
            {bran.label}
          </option>
        );
      })
    );
  };

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={3}>
            <Form.Label>User</Form.Label>
            <Select
              options={userList}
              value={filterUser}
              onChange={changeFilterUser}
            />
          </Col>
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Brand</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={filterBrand}
                onChange={(e) => changeFilterBrand(e)}
              >
                <option value={0}>All</option>
                {renderBrand()}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 3, span: 3 }}
            xs={12}
            className="col align-self-end d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterComissionTarget;

import { FC } from "react";
import { Col } from "react-bootstrap";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { GrDocumentUpdate, GrDocumentPerformance } from "react-icons/gr";
import { HiOutlineClipboardList } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";

interface MenuItemProps {
  menuName: string;
  menuNavigation: string;
}
const MenuItem: FC<MenuItemProps> = ({ menuName, menuNavigation }) => {
  const navigate = useNavigate();
  const getImage = () => {
    switch (menuNavigation) {
      case "/create-sales-report":
        return <GrDocumentUpdate size="24px" />;
      case "/list-sales-report":
        return <HiOutlineClipboardList size="24px" />;
      case "/list-target":
        return <GrDocumentPerformance size="24px" />;
      case "/profile":
        return <CgProfile size="24px" />;
      default:
        break;
    }
  };
  return (
    <>
      <Col xs={6}>
        <div className="menu-item" onClick={() => navigate(menuNavigation)}>
          {getImage()}
          <p style={{ margin: 0 }}>{menuName}</p>
        </div>
      </Col>
    </>
  );
};

export default MenuItem;

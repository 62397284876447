import { E_FETCH_STATUS } from "../../constant";
import { CategoryData } from "../../interfaces/categories";
import { PackageData } from "../../interfaces/package";
import {
  PackageAction,
  PackageSetCurrentPage,
  PackageSetList,
  PackageSetStatusLoading,
  PackageSetTotalPage,
  PackageType,
} from "../types/packages";

interface PackageState {
  packagesList: PackageData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
}

const initialState: PackageState = {
  packagesList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  PACKAGE_SET_LIST,
  PACKAGE_SET_STATUS_LOADING,
  PACKAGE_SET_CURRENT_PAGE,
  PACKAGE_SET_TOTAL_PAGE,
} = PackageType;

const packages = (
  state: PackageState = initialState,
  action: PackageAction
): PackageState => {
  const { type } = action;

  const setPackageList = (): PackageState => {
    const { payload: packages } = action as PackageSetList;
    return {
      ...state,
      packagesList: packages,
    };
  };

  const setPackageStatusLoading = (): PackageState => {
    const { payload: statusLoading } = action as PackageSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setPackageCurrentPage = (): PackageState => {
    const { payload: currentPage } = action as PackageSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setPackageTotalPage = (): PackageState => {
    const { payload: totalPage } = action as PackageSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  switch (type) {
    case PACKAGE_SET_LIST:
      return setPackageList();
    case PACKAGE_SET_STATUS_LOADING:
      return setPackageStatusLoading();
    case PACKAGE_SET_CURRENT_PAGE:
      return setPackageCurrentPage();
    case PACKAGE_SET_TOTAL_PAGE:
      return setPackageTotalPage();
    default:
      return state;
  }
};

export default packages;

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  KabupatenGetResponse,
  KecamatanGetResponse,
  KelurahanGetResponse,
  ProvinceGetResponse,
  ZipCodeData,
  ZipCodeGetResponse,
} from "../../interfaces/util";
import UtilService from "../../services/Util";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { DaerahType } from "../types/daerah";

export const setProvinsiList = (provinsi: string[]) => {
  return {
    type: DaerahType.DAERAH_SET_PROVINSI,
    payload: provinsi,
  };
};

export const setKabupatenList = (kabupaten: string[]) => {
  return {
    type: DaerahType.DAERAH_SET_KABUPATEN,
    payload: kabupaten,
  };
};

export const setKecamatanList = (kecamatan: string[]) => {
  return {
    type: DaerahType.DAERAH_SET_KECAMATAN,
    payload: kecamatan,
  };
};

export const setKelurahanList = (kelurahan: string[]) => {
  return {
    type: DaerahType.DAERAH_SET_KELURAHAN,
    payload: kelurahan,
  };
};

export const setZipcode = (zipcode: ZipCodeData) => {
  return {
    type: DaerahType.DAERAH_SET_ZIPCODE,
    payload: zipcode,
  };
};

export const loadProvinsiList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ProvinceGetResponse = await UtilService.getProvince();
      if (res.data) {
        dispatch(setProvinsiList(res.data));
      } else {
        dispatch(setProvinsiList([]));
      }
    } catch (e: any) {
      dispatch(setProvinsiList([]));
    }
  };
};

export const loadKabupatenList = (provinsi: string) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: KabupatenGetResponse = await UtilService.getKabupaten(
        provinsi
      );
      if (res.data) {
        dispatch(setKabupatenList(res.data));
      } else {
        dispatch(setKabupatenList([]));
      }
    } catch (e: any) {
      dispatch(setKabupatenList([]));
    }
  };
};

export const loadKecamatanList = (provinsi: string, kabupaten: string) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: KecamatanGetResponse = await UtilService.getKecamatan(
        provinsi,
        kabupaten
      );
      if (res.data) {
        dispatch(setKecamatanList(res.data));
      } else {
        dispatch(setKecamatanList([]));
      }
    } catch (e: any) {
      dispatch(setKecamatanList([]));
    }
  };
};

export const loadKelurahanList = (
  provinsi: string,
  kabupaten: string,
  kecamatan: string
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: KelurahanGetResponse = await UtilService.getKelurahan(
        provinsi,
        kabupaten,
        kecamatan
      );
      if (res.data) {
        dispatch(setKelurahanList(res.data));
      } else {
        dispatch(setKelurahanList([]));
      }
    } catch (e: any) {
      dispatch(setKelurahanList([]));
    }
  };
};

export const loadZipcode = (
  provinsi: string,
  kabupaten: string,
  kecamatan: string,
  kelurahan: string
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ZipCodeGetResponse = await UtilService.getZipcode(
        provinsi,
        kabupaten,
        kecamatan,
        kelurahan
      );
      if (res.data) {
        dispatch(setZipcode(res.data));
      }
    } catch (e: any) {
      throw new Error("Error");
    }
  };
};

import React, { FC, useRef, useState } from "react";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { eraseCookie, getCookie } from "../../utils/cookies";
import UtilService from "../../services/Util";
import { E_AUTH_STATUS } from "../../constant";
import { GrClose } from "react-icons/gr";

interface NavbarProps {
  customBrand?: string;
}

const NavBar: FC<NavbarProps> = (props) => {
  const navigate = useNavigate();
  const cookiesUser = getCookie("user");
  const cookiesStatus = getCookie("userstatus");
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileValue, setFileValue] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [isModalShowing, setIsModalShowing] = useState<boolean>(false);
  const { customBrand } = props;

  const closeModal = () => {
    setIsModalShowing(false);
    setFileValue("");
    setFileName("");
  };

  const uploadFile = () => {
    const body = new FormData();
    if (fileRef.current && fileRef.current.files) {
      body.append("file", fileRef.current.files[0]);
      UtilService.uploadDaerah(body).then((data) => {
        if (data.status === 1) {
          alert("Data has been uploaded successfully");
          window.location.reload();
        }
      });
    }
  };

  const triggerFileExplorer = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onFileChange = (event: any) => {
    event.preventDefault();
    // const reader = new FileReader();
    if (fileRef.current) {
      setFileName(event.target.files[0].name);
      setFileValue(fileRef.current.value);
    }
  };

  const signOut = () => {
    eraseCookie("user");
    window.location.href = "/sign-in";
  };
  return (
    <>
      <Navbar className="navbar-bg-primary" expand="lg" variant="dark">
        <Container fluid>
          <Navbar.Brand
            className="d-lg-none"
            onClick={() => navigate("/dashboard")}
          >
            {customBrand ? customBrand : "Blanco Sales Report"}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => navigate("/dashboard")}
                active={
                  window.location.pathname === "/dashboard" ||
                  window.location.pathname.includes("/sales-report/")
                }
              >
                Dashboard
              </Nav.Link>
              {Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN && (
                <Nav.Link
                  onClick={() => navigate("/user")}
                  active={window.location.pathname.includes("/user")}
                >
                  List User
                </Nav.Link>
              )}
              {/* <Nav.Link
                onClick={() => navigate("/packages")}
                active={window.location.pathname === "/packages"}
              >
                List Package
              </Nav.Link> */}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/products")}
                  active={window.location.pathname.includes("/products")}
                >
                  List Product
                </Nav.Link>
              )}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/brand")}
                  active={window.location.pathname.includes("/brand")}
                >
                  List Brand
                </Nav.Link>
              )}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/categories")}
                  active={window.location.pathname.includes("/categories")}
                >
                  List Category
                </Nav.Link>
              )}

              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/finishing")}
                  active={window.location.pathname.includes("/finishing")}
                >
                  List Finishing
                </Nav.Link>
              )}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/colour")}
                  active={window.location.pathname.includes("/colour")}
                >
                  List Colour
                </Nav.Link>
              )}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.AREA_SUPERVISOR) && (
                <Nav.Link
                  onClick={() => navigate("/stores")}
                  active={window.location.pathname.includes("/stores")}
                >
                  List Toko
                </Nav.Link>
              )}
              {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                Number(cookiesStatus) === E_AUTH_STATUS.MANAGER ||
                Number(cookiesStatus) === E_AUTH_STATUS.SUPERVISOR) && (
                <NavDropdown
                  title="Comission"
                  active={window.location.pathname.includes("/comission")}
                >
                  {(Number(cookiesStatus) === E_AUTH_STATUS.SUPERVISOR ||
                    Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN ||
                    Number(cookiesStatus) === E_AUTH_STATUS.SUPERVISOR) && (
                    <NavDropdown.Item onClick={() => navigate("/comission")}>
                      List Comission SPG
                    </NavDropdown.Item>
                  )}
                  {Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => navigate("/comission-category")}
                      >
                        List Comission Category
                      </NavDropdown.Item>
                    </>
                  )}
                  {Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={() => navigate("/comission-package-cgs")}
                      >
                        List Comission CGS Package
                      </NavDropdown.Item>
                    </>
                  )}
                </NavDropdown>
              )}
              {Number(cookiesStatus) === E_AUTH_STATUS.SUPERADMIN && (
                <Nav.Link onClick={() => setIsModalShowing(true)}>
                  Upload provinsi
                </Nav.Link>
              )}
              {Number(cookiesStatus) === E_AUTH_STATUS.SALES && (
                <Nav.Link
                  onClick={() => navigate("/create-sales-report")}
                  active={window.location.pathname.includes(
                    "create-sales-report"
                  )}
                >
                  Buat Laporan Penjualan
                </Nav.Link>
              )}
              {Number(cookiesStatus) === E_AUTH_STATUS.SALES && (
                <Nav.Link
                  onClick={() => navigate("/list-sales-report")}
                  active={window.location.pathname.includes(
                    "list-sales-report"
                  )}
                >
                  List Laporan Penjualan
                </Nav.Link>
              )}
              {Number(cookiesStatus) === E_AUTH_STATUS.SALES && (
                <Nav.Link
                  onClick={() => navigate("/list-target")}
                  active={window.location.pathname.includes("list-target")}
                >
                  Informasi Target
                </Nav.Link>
              )}
              {Number(cookiesStatus) === E_AUTH_STATUS.SALES && (
                <Nav.Link
                  onClick={() => navigate("/profile")}
                  active={window.location.pathname.includes("profile")}
                >
                  Informasi Profile
                </Nav.Link>
              )}
            </Nav>
            <Nav>
              <NavDropdown title={cookiesUser} id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={signOut}>Sign Out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={isModalShowing} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Province Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {fileValue === "" ? (
            <Button variant="primary" onClick={triggerFileExplorer}>
              <FaFileExcel />
              Upload Province
            </Button>
          ) : (
            <>
              <div className="position-relative d-flex justify-content-center with-border-input w-100 pt-3">
                <div
                  className="d-flex flex-column align-items-center cursor-pointer"
                  onClick={triggerFileExplorer}
                >
                  <FaFileExcel size={60} color="green" className="mb-2" />
                  <p>{fileName}</p>
                </div>
                <div className="deleteIcon cursor-pointer" onClick={closeModal}>
                  <GrClose />
                </div>
              </div>
            </>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={onFileChange}
            ref={fileRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={uploadFile}
            disabled={fileValue === ""}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NavBar;

import { ChangeEvent, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../component/Navbar";
import RepetitiveDetailCGSPackage from "../../component/RepetitiveDetailCGSPackage";
import { DataComissionMapping } from "../../interfaces/comission";
import ComissionService from "../../services/Comission";
import checkNumericInput from "../../utils/checkNumericInput";

const ComissionCGSPackageDetail = () => {
  const { idComissionCGS } = useParams();
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [shopName, setShopName] = useState<string>("");
  const [comRateCGS, setComRateCGS] = useState<DataComissionMapping[]>([]);
  const [editComRate, setEditComRate] = useState<DataComissionMapping[]>([]);

  const addNewTargetForm = () => {
    setEditComRate([
      ...editComRate,
      {
        id: Number(editComRate[editComRate.length - 1].id) + 1,
        target: 0,
        comrate: 0,
      },
    ]);
  };

  const removeFormId = (id: number) => {
    const tempEditComRate = [...editComRate];
    tempEditComRate.splice(id, 1);
    setEditComRate(tempEditComRate);
  };

  const changeTargetComission = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    const tempEditComRate = [...editComRate];
    tempEditComRate[id].target = Number(newValue);
    setEditComRate(tempEditComRate);
  };

  const changeTargetComRate = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    const tempEditComRate = [...editComRate];
    tempEditComRate[id].comrate = Number(newValue);
    setEditComRate(tempEditComRate);
  };

  const revertEditState = () => {
    setEditComRate([...comRateCGS]);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    revertEditState();
  };

  const saveEditedData = () => {
    for (let i = 0; i < editComRate.length; i++) {
      if (editComRate[i].target === 0) {
        alert(`Please input target comission number #${i + 1}`);
        return false;
      }
      if (editComRate[i].comrate === 0) {
        alert(`Please input comission rate for target number #${i + 1}`);
        return false;
      }
      if (i > 0 && editComRate[i].target <= editComRate[i - 1].target) {
        alert(`Please input a bigger value for target #${i + 1}`);
        return false;
      }
      if (i > 0 && editComRate[i].comrate <= editComRate[i - 1].comrate) {
        alert(`Please input a bigger value for comrate #${i + 1}`);
        return false;
      }
    }
    const bodyForm = new FormData();
    bodyForm.append("comrate", JSON.stringify(editComRate));
    console.log(bodyForm);

    ComissionService.updateComissionCGSPackage(Number(idComissionCGS), bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully update CGS comission package");
          navigate("/comission-package-cgs");
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const renderCGSComission = () => {
    if (isEditing) {
      return editComRate.map((com, index) => {
        return (
          <RepetitiveDetailCGSPackage
            id={index}
            key={com.id}
            target={com.target}
            comrate={com.comrate}
            removeFormId={removeFormId}
            changeTargetComission={changeTargetComission}
            changeTargetComRate={changeTargetComRate}
          />
        );
      });
    }
    return comRateCGS.map((com, index) => {
      return (
        <RepetitiveDetailCGSPackage
          id={index}
          target={com.target}
          key={com.id}
          readonly={!isEditing}
          comrate={com.comrate}
          removeFormId={removeFormId}
          changeTargetComission={changeTargetComission}
          changeTargetComRate={changeTargetComRate}
        />
      );
    });
  };

  useEffect(() => {
    if (idComissionCGS) {
      ComissionService.getComissionCGSPackageDetail(Number(idComissionCGS))
        .then((res) => {
          if (res.data) {
            const { comrate, storeName } = res.data;
            setShopName(storeName);
            setComRateCGS(JSON.parse(JSON.stringify(comrate)));
            setEditComRate(JSON.parse(JSON.stringify(comrate)));
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
  }, [idComissionCGS]);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            {!isEditing && (
              <>
                <Button
                  onClick={() => setIsEditing(true)}
                  className="notPrinted"
                >
                  Edit Comission CGS Package
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={() => cancelEdit()} variant="outline-primary">
                  Cancel
                </Button>
                &nbsp;
                <Button onClick={saveEditedData}>Save</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Form.Label>Shop Name</Form.Label>
        <Form.Control type="text" value={shopName} readOnly />
        {renderCGSComission()}
        {isEditing && (
          <Button onClick={addNewTargetForm}>Add New Target</Button>
        )}
      </Container>
    </>
  );
};

export default ComissionCGSPackageDetail;

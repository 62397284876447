import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { E_GENDER, NEW_USER_STATUS } from "../../constant";
import { UserData } from "../../interfaces/user";
import UserService from "../../services/User";
import DatePicker from "react-datepicker";
import "./index.scss";
import "react-datepicker/dist/react-datepicker.css";

import Select, { SingleValue } from "react-select";
import checkNumericInput from "../../utils/checkNumericInput";
import { shopSelectionSelector } from "../../redux/selectors/shop";
import { GrClose } from "react-icons/gr";
import { ShopSelectData } from "../../interfaces/shop";

const DOBObject: Date = new Date();
const maxDOB: Date = new Date(
  DOBObject.getFullYear() - 17,
  DOBObject.getMonth(),
  DOBObject.getDay()
);

const minDOB: Date = new Date(
  DOBObject.getFullYear() - 70,
  DOBObject.getMonth(),
  DOBObject.getDay()
);

interface ModalAddNewUserProps {
  isShowing: boolean;
  onModalAddUserClose: () => void;
}

const ModalAddNewUser: FC<ModalAddNewUserProps> = (props) => {
  const shopSelection = useSelector(shopSelectionSelector);
  const ktpImageRef = useRef<HTMLInputElement>(null);

  const { isShowing, onModalAddUserClose } = props;
  const [areaSupervisorList, setAreaSupervisorList] = useState<UserData[]>([]);
  const [supervisorList, setSupervisorList] = useState<UserData[]>([]);
  const [managerList, setManagerList] = useState<UserData[]>([]);
  const [newFirstName, setNewFirstName] = useState<string>("");
  const [newLastName, setNewLastName] = useState<string>("");
  const [newUsername, setNewUsername] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [newStatus, setNewStatus] = useState<NEW_USER_STATUS>(
    NEW_USER_STATUS.SALES
  );
  const [newStoreId, setNewStoreId] = useState<SingleValue<ShopSelectData>>({
    value: 0,
    label: "Select Toko",
  });
  const [newGender, setNewGender] = useState<E_GENDER>(E_GENDER.INIT);
  const [newManagerId, setNewManagerId] = useState<number>(0);
  const [newAreaSupervisorId, setNewAreaSupervisorId] = useState<number>(0);
  const [newSupervisorId, setNewSupervisorId] = useState<number>(0);
  const [newAddress, setNewAddress] = useState<string>("");
  const [newDOB, setNewDOB] = useState(maxDOB);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [newBankName, setNewBankName] = useState<string>("");
  const [newBankAccountNumber, setNewBankAccountNumber] = useState<string>("");
  const [newNPWPNumber, setNewNPWPNumber] = useState<string>("");
  const [newKTPLink, setNewKTPLink] = useState<string>("");

  const revertAddModalState = () => {
    setNewFirstName("");
    setNewLastName("");
    setNewUsername("");
    setNewEmail("");
    setNewStatus(NEW_USER_STATUS.SALES);
    setNewGender(E_GENDER.INIT);
    setNewAddress("");
    setNewBankName("");
    setNewBankAccountNumber("");
    setNewNPWPNumber("");
    setNewKTPLink("");
    setNewDOB(new Date());
    setNewStoreId({
      value: 0,
      label: "",
    });
    setNewAreaSupervisorId(0);
    setNewSupervisorId(0);
    setNewManagerId(0);
  };
  const createNewUser = () => {
    if (newFirstName === "") {
      alert("Please fill the first name field");
      return false;
    }
    if (newLastName === "") {
      alert("Please fill the first name field");
      return false;
    }
    if (newUsername === "") {
      alert("Please fill the username field");
      return false;
    }

    if (newEmail === "") {
      alert("Please fill the email field");
      return false;
    }

    if (newPhoneNumber === "") {
      alert("Please fill the phone number field");
      return false;
    }

    if (newStatus === NEW_USER_STATUS.SALES && newAreaSupervisorId === 0) {
      alert("Please select area supervisor name for this sales");
      return false;
    }

    if (
      (newStatus === NEW_USER_STATUS.SALES && newSupervisorId === 0) ||
      (newStatus === NEW_USER_STATUS.AREA_SUPERVISOR && newSupervisorId === 0)
    ) {
      alert("Please select supervisor name for this sales");
      return false;
    }

    if (newStatus === NEW_USER_STATUS.SUPERVISOR && newManagerId === 0) {
      alert("Please select manager name for this sales");
      return false;
    }

    if (
      newStatus === NEW_USER_STATUS.SALES &&
      newStoreId &&
      newStoreId.value === 0
    ) {
      alert("Please select store name");
      return false;
    }

    if (newGender === E_GENDER.INIT) {
      alert("Please select the user's gender");
      return false;
    }

    if (newAddress === "") {
      alert("Please fill the address field");
      return false;
    }

    if (newBankName === "") {
      alert("Please fill the bank account name field");
      return false;
    }

    if (newBankAccountNumber === "") {
      alert("Please fill the back account number field");
      return false;
    }

    if (newKTPLink === "") {
      alert("Please select ktp image");
      return false;
    }

    const bodyForm = new FormData();
    bodyForm.append("firstName", newFirstName);
    bodyForm.append("lastName", newLastName);
    bodyForm.append("username", newUsername);
    bodyForm.append("email", newEmail);
    bodyForm.append("phoneNumber", newPhoneNumber);
    bodyForm.append("status", newStatus.toString());
    /* For Sales */
    if (newStatus === NEW_USER_STATUS.SALES) {
      bodyForm.append("areaSupervisorId", newAreaSupervisorId.toString());
      bodyForm.append("supervisorId", newSupervisorId.toString());
      bodyForm.append("storeId", newStoreId?.value.toString() || "");
    }
    //

    /* For Area Supervisor */
    if (newStatus === NEW_USER_STATUS.AREA_SUPERVISOR) {
      bodyForm.append("supervisorId", newSupervisorId.toString());
    }
    //

    /* For Supervisor */
    if (newStatus === NEW_USER_STATUS.SUPERVISOR) {
      bodyForm.append("managerId", newManagerId.toString());
    }
    //

    bodyForm.append("gender", newGender.toString());
    bodyForm.append("address", newAddress);
    let year = newDOB.getFullYear();
    let month = newDOB.getMonth() + 1;
    let date = newDOB.getDate();
    bodyForm.append("dob", `${year}-${month}-${date}`);
    bodyForm.append("bankName", newBankName);
    bodyForm.append("bankNumber", newBankAccountNumber);
    bodyForm.append("npwpNumber", newNPWPNumber);
    if (ktpImageRef.current && ktpImageRef.current.files && newKTPLink !== "") {
      bodyForm.append("ktpLink", ktpImageRef.current.files[0]);
    }

    UserService.createNewUser(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Sucessfully create new user");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    if (isShowing) {
      switch (newStatus) {
        case NEW_USER_STATUS.SALES:
          UserService.getAreaSupervisorList().then((res) => {
            if (res.data) {
              setAreaSupervisorList(res.data);
            }
          });
          UserService.getSupervisorList().then((res) => {
            if (res.data) {
              setSupervisorList(res.data);
            }
          });
          break;
        case NEW_USER_STATUS.AREA_SUPERVISOR:
          UserService.getSupervisorList().then((res) => {
            if (res.data) {
              setSupervisorList(res.data);
            }
          });

          break;
        case NEW_USER_STATUS.SUPERVISOR:
          UserService.getManagerList().then((res) => {
            if (res.data) {
              setManagerList(res.data);
            }
          });
          break;
      }
    }
  }, [isShowing, newStatus]);

  const changeNewFirstName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewFirstName(value);
  };

  const changeNewLastName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewLastName(value);
  };

  const changeNewUsername = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewUsername(value.toLowerCase());
  };

  const changeNewEmail = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewEmail(value);
  };

  const changeNewPhoneNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewPhoneNumber(newValue);
  };

  const changeNewStatus = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewManagerId(0);
    setNewAreaSupervisorId(0);
    setNewSupervisorId(0);
    setNewStatus(Number(value) as NEW_USER_STATUS);
  };

  const changeNewGender = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewGender(Number(value) as E_GENDER);
  };

  const changeAreaSupervisorId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewAreaSupervisorId(Number(value));
  };

  const changeSupervisorId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewSupervisorId(Number(value));
  };

  const changeManagerId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewManagerId(Number(value));
  };

  const changeStoreId = (e: SingleValue<ShopSelectData>) => {
    if (e) {
      setNewStoreId(e);
    }
  };

  const changeNewAddress = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewAddress(value);
  };

  const changeNewDOB = (e: any) => {
    setNewDOB(e);
  };

  const changeNewBankName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewBankName(value);
  };

  const changeNewBankAccountNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewBankAccountNumber(newValue);
  };

  const changeNewNPWPNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewNPWPNumber(newValue);
  };

  const openFileExplorer = () => {
    (ktpImageRef as any).current.click();
  };

  const changeIDCard = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const imageURL = (e as any).target.result;
      if (imageURL) {
        setNewKTPLink(imageURL);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <Modal
        onHide={() => {
          revertAddModalState();
          onModalAddUserClose();
        }}
        show={isShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={createNewUser}>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={newFirstName}
                  onChange={(e) => changeNewFirstName(e)}
                />
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={newLastName}
                  onChange={(e) => changeNewLastName(e)}
                />
              </Col>
            </Row>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Username"
              value={newUsername}
              onChange={(e) => changeNewUsername(e)}
            />
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={newEmail}
              onChange={(e) => changeNewEmail(e)}
            />
            <Form.Label>Phone Number</Form.Label>
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                placeholder="Phone Number"
                value={newPhoneNumber}
                onChange={(e) => changeNewPhoneNumber(e)}
              />
            </InputGroup>
            <Form.Label>Status</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={newStatus}
              onChange={(e) => changeNewStatus(e)}
            >
              <option value={NEW_USER_STATUS.SALES}>Sales</option>
              <option value={NEW_USER_STATUS.AREA_SUPERVISOR}>
                Area Supervisor
              </option>
              <option value={NEW_USER_STATUS.SUPERVISOR}>Supervisor</option>
              <option value={NEW_USER_STATUS.MANAGER}>Manager</option>
            </Form.Select>
            {newStatus === NEW_USER_STATUS.SALES && (
              <>
                <Form.Label>Area Supervisor</Form.Label>
                <Form.Select
                  value={newAreaSupervisorId}
                  onChange={(e) => changeAreaSupervisorId(e)}
                >
                  <option value={0}>Select Area Supervisor</option>
                  {areaSupervisorList.map((arsup) => {
                    return (
                      <option value={arsup.id} key={arsup.id}>
                        {arsup.firstName} {arsup.lastName}
                      </option>
                    );
                  })}
                </Form.Select>
                <Form.Label>Supervisor</Form.Label>
                <Form.Select
                  value={newSupervisorId}
                  onChange={(e) => changeSupervisorId(e)}
                >
                  <option value={0}>Select Supervisor</option>
                  {supervisorList.map((supervisor) => {
                    return (
                      <option value={supervisor.id} key={supervisor.id}>
                        {supervisor.firstName} {supervisor.lastName}
                      </option>
                    );
                  })}
                </Form.Select>
              </>
            )}

            {newStatus === NEW_USER_STATUS.AREA_SUPERVISOR && (
              <>
                <Form.Label>Supervisor</Form.Label>
                <Form.Select
                  value={newSupervisorId}
                  onChange={(e) => changeSupervisorId(e)}
                >
                  <option value={0}>Select Supervisor</option>
                  {supervisorList.map((supervisor) => {
                    return (
                      <option value={supervisor.id} key={supervisor.id}>
                        {supervisor.firstName} {supervisor.lastName}
                      </option>
                    );
                  })}
                </Form.Select>
              </>
            )}
            {newStatus === NEW_USER_STATUS.SUPERVISOR && (
              <>
                <Form.Label>Manager</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={newManagerId}
                  onChange={(e) => changeManagerId(e)}
                >
                  <option value={0}>Select Supervisor</option>
                  {managerList.map((manager) => {
                    return (
                      <option value={manager.id} key={manager.id}>
                        {manager.firstName} {manager.lastName}
                      </option>
                    );
                  })}
                </Form.Select>
              </>
            )}
            {newStatus === NEW_USER_STATUS.SALES && (
              <>
                <Form.Label>Toko</Form.Label>
                <Select
                  options={shopSelection}
                  value={newStoreId}
                  onChange={(e) => changeStoreId(e)}
                />
              </>
            )}
            <Form.Label>Gender</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={newGender}
              onChange={(e) => changeNewGender(e)}
            >
              <option value={E_GENDER.INIT}>Select Gender</option>
              <option value={E_GENDER.MALE}>Male</option>
              <option value={E_GENDER.FEMALE}>Female</option>
            </Form.Select>
            <Form.Label>Alamat</Form.Label>
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder="Address"
              value={newAddress}
              onChange={(e) => changeNewAddress(e)}
            />
            <Form.Label>Date of Birth</Form.Label>
            <DatePicker
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              dateFormat="dd-MM-yyyy"
              selected={newDOB}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={minDOB}
              maxDate={maxDOB}
              onChange={(date) => changeNewDOB(date)}
            />
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Bank Account Name</Form.Label>
                <Form.Control
                  placeholder="Bank Account Name"
                  value={newBankName}
                  onChange={(e) => changeNewBankName(e)}
                />
              </Col>

              <Col md="6" xs="6">
                <Form.Label>Bank Account Number</Form.Label>
                <Form.Control
                  placeholder="Bank Account Number"
                  value={newBankAccountNumber}
                  onChange={(e) => changeNewBankAccountNumber(e)}
                />
              </Col>
            </Row>
            <Form.Label>NPWP Number</Form.Label>
            <Form.Control
              placeholder="NPWP Number"
              value={newNPWPNumber}
              onChange={(e) => changeNewNPWPNumber(e)}
            />
            <Form.Label>ID Card</Form.Label>
            {/* <Form.Control placeholder="NPWP Number" type="file" accept="png" /> */}
            {newKTPLink === "" ? (
              <InputGroup className="cursor-pointer" onClick={openFileExplorer}>
                <InputGroup.Text>Choose File</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="No file choosen"
                  readOnly
                  className="cursor-pointer"
                />
              </InputGroup>
            ) : (
              <div className="position-relative d-flex justify-content-center with-border-input">
                <Image
                  src={newKTPLink}
                  className="imageKTP"
                  onClick={openFileExplorer}
                />
                <div
                  className="deleteIcon cursor-pointer"
                  onClick={() => setNewKTPLink("")}
                >
                  <GrClose />
                </div>
              </div>
            )}
            <input
              type="file"
              ref={ktpImageRef}
              style={{ display: "none" }}
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => changeIDCard(e)}
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              revertAddModalState();
              onModalAddUserClose();
            }}
            variant="outline-primary"
          >
            Close
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              createNewUser();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAddNewUser;

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import {
  BranchGetResponse,
  ShopData,
  ShopGetResponse,
  ShopSelectData,
  ShopSelectResponse,
} from "../../interfaces/shop";
import { UserData, UserGetResponse } from "../../interfaces/user";
import ShopService from "../../services/Shop";
import UserService from "../../services/User";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { ShopType } from "../types/shop";

export const setShopList = (shops: ShopData[]) => {
  return {
    type: ShopType.SHOP_SET_LIST,
    payload: shops,
  };
};

export const setBranchList = (branches: ShopData[]) => {
  return {
    type: ShopType.SHOP_SET_BRANCHES,
    payload: branches,
  };
};

export const setShopStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: ShopType.SHOP_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setShopCurrentPage = (currentPage: number) => {
  return {
    type: ShopType.SHOP_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setShopTotalPage = (totalPage: number) => {
  return {
    type: ShopType.SHOP_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const setShopSelectList = (shops: ShopSelectData[]) => {
  return {
    type: ShopType.SHOP_SET_SELECT,
    payload: shops,
  };
};

export const loadShopList = (
  filterStoreId?: number,
  storeOwnerName?: string,
  npwpToko?: string
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setShopStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        shop: { currentPage },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      if (filterStoreId) {
        params += `&storeId=${filterStoreId}`;
      }
      if (storeOwnerName) {
        params += `&storeOwnerName=${storeOwnerName}`;
      }
      if (npwpToko) {
        params += `&npwpToko=${npwpToko}`;
      }
      const res: ShopGetResponse = await ShopService.getShopList(params);
      if (res.data) {
        dispatch(setShopList(res.data));
        dispatch(setShopTotalPage(res.total_page));
        dispatch(setShopStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setShopStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setShopStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

export const loadShopSelect = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ShopSelectResponse = await ShopService.getShopSelectList();
      if (res.data) {
        const tempSelect: ShopSelectData[] = res.data.map((shop) => {
          return {
            value: shop.id,
            label: shop.storeName,
          };
        });
        dispatch(setShopSelectList(tempSelect));
      } else {
        dispatch(setShopSelectList([]));
      }
    } catch (e: any) {
      throw new Error(e);
    }
  };
};

export const loadBranches = (id?: number) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      // dispatch(setShopStatusLoading(E_FETCH_STATUS.FETCHING));
      let res: BranchGetResponse;
      if (id) {
        res = await ShopService.getBranchList(id);
      } else {
        res = await ShopService.getBranchList();
      }
      if (res.data) {
        dispatch(setBranchList(res.data));
        // dispatch(setShopStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        // dispatch(setShopStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      // dispatch(setShopStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

import { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { ComissionCategoryData } from "../../interfaces/comissioncategory";

interface FilterComissionCategoryProps {
  comissionCategoryName: DefaultSelection;
  comissionCategoryData: ComissionCategoryData[];
  handleComissionCategoryNameFilterChange: (
    e: SingleValue<DefaultSelection>
  ) => void;
  applyFilter: () => void;
  resetFilter: () => void;
}

const FilterComissionCategory: FC<FilterComissionCategoryProps> = (props) => {
  const {
    comissionCategoryName,
    comissionCategoryData,
    handleComissionCategoryNameFilterChange,
    applyFilter,
    resetFilter,
  } = props;
  const [comissionCategoryList, setComissionCategoryList] = useState<
    DefaultSelection[]
  >([]);

  const mapComissionCategoryDataToList = () => {
    const comissionCategoryListTemp: DefaultSelection[] =
      comissionCategoryData.map((comission) => {
        return {
          value: comission.id,
          label: comission.comissionCategoryName,
        };
      });
    setComissionCategoryList(comissionCategoryListTemp);
  };
  useEffect(() => {
    mapComissionCategoryDataToList();
  }, [comissionCategoryData]);
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama User</Form.Label>
              <Select
                options={comissionCategoryList}
                value={comissionCategoryName}
                onChange={handleComissionCategoryNameFilterChange}
              />
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 8, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterComissionCategory;

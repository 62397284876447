import { Container, Row } from "react-bootstrap";
import NavBar from "../../../component/Navbar";
import SalesComissionCount from "../../../component/SalesComissionCount";
import { getCookie } from "../../../utils/cookies";
import MenuItem from "./MenuItem";

const DashboardSales = () => {
  const name = getCookie("username");
  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <h2>
          Welcome,
          <br /> {name}
        </h2>
        <br />
        <SalesComissionCount />
        <br />
        <Row>
          <MenuItem
            menuName="Buat Laporan Penjualan"
            menuNavigation="/create-sales-report"
          ></MenuItem>
          <MenuItem
            menuName="List Laporan Penjualan"
            menuNavigation="/list-sales-report"
          ></MenuItem>
          <MenuItem
            menuName="Informasi Target"
            menuNavigation="/list-target"
          ></MenuItem>
          <MenuItem
            menuName="Informasi Profile"
            menuNavigation="/profile"
          ></MenuItem>
        </Row>
      </Container>
    </>
  );
};

export default DashboardSales;

import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import shop from "./shop";
import daerah from "./daerah";
import product from "./product";
import category from "./category";
import packages from "./packages";
import colour from "./colour";
import salesreport from "./salesreport";

export const reducers = combineReducers({
  auth,
  user,
  shop,
  daerah,
  product,
  category,
  packages,
  colour,
  salesreport,
});

export type AppStateType = ReturnType<typeof reducers>;

const checkNumericInputNumber = (value: any, max?: any): number => {
  if (!isNaN(parseInt(value, 10))) {
    if (value > max) {
      return Math.abs(parseInt(max, 10));
    }
    return Math.abs(parseInt(value, 10));
  }
  return 0;
};

export default checkNumericInputNumber;

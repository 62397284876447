import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import {
  E_FILTER_STATUS,
  SalesReportData,
  SalesReportFilterType,
  SalesReportGetResponse,
} from "../../interfaces/salesreport";
import SalesReportService from "../../services/SalesReport";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { SalesReportType } from "../types/salesreport";

export const setSalesReportList = (salesReports: SalesReportData[]) => {
  return {
    type: SalesReportType.SALESREPORT_SET_LIST,
    payload: salesReports,
  };
};

export const setSalesReportStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: SalesReportType.SALESREPORT_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setSalesReportCurrentPage = (currentPage: number) => {
  return {
    type: SalesReportType.SALESREPORT_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setSalesReportTotalPage = (totalPage: number) => {
  return {
    type: SalesReportType.SALESREPORT_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const setSalesReportFilter = (
  filterSalesReport: SalesReportFilterType
) => ({
  type: SalesReportType.SALESREPORT_SET_FILTER,
  payload: filterSalesReport,
});

export const loadSalesReportList = (
  filterMonth?: number,
  filterYear?: number,
  filterUserId?: number,
  filterInvoiceNumber?: string,
  filterSalesReportNo?: string,
  filterStatusSalesReport?: E_FILTER_STATUS
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setSalesReportStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        salesreport: {
          currentPage,
          filterSalesReport: { filterSalesReportNumber, filterStatus },
        },
      } = reduxState;
      let params: string = `?page_number=${currentPage}`;
      if (Number(filterStatus) !== E_FILTER_STATUS.ALL) {
        params += `&status=${filterStatus}`;
      }
      if (filterMonth) {
        params += `&month=${filterMonth}`;
      }
      if (filterYear) {
        params += `&year=${filterYear}`;
      }
      if (filterSalesReportNumber !== "") {
        params += `&salesreportnumber=${filterSalesReportNumber}`;
      }
      if (filterSalesReportNo) {
        params += `&salesreportnumber=${filterSalesReportNo}`;
      }
      if (filterUserId) {
        params += `&userId=${filterUserId}`;
      }
      if (filterInvoiceNumber) {
        params += `&invoiceNo=${filterInvoiceNumber}`;
      }
      if (filterStatusSalesReport) {
        params += `&status=${filterStatusSalesReport}`;
      }

      const res: SalesReportGetResponse =
        await SalesReportService.getSalesReport(params);
      if (res.data) {
        dispatch(setSalesReportList(res.data));
        dispatch(setSalesReportTotalPage(res.total_page));
        dispatch(setSalesReportStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setSalesReportStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setSalesReportStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

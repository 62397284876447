import { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import FilterComissionCGSPackage from "../../component/FilterComissionCGSPackage";
import NavBar from "../../component/Navbar";
import RepetitiveCGSPackageForm from "../../component/RepetitiveCGSPackageForm";
import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCGSData } from "../../interfaces/comission";
import { ShopSelectData } from "../../interfaces/shop";
import ComissionService from "../../services/Comission";
import ShopService from "../../services/Shop";
import checkNumericInput from "../../utils/checkNumericInput";

const ComissionCGSPackage = () => {
  const navigate = useNavigate();
  // For Filter
  const [filterShop, setFilterShop] = useState<DefaultSelection>({
    value: 0,
    label: "All Shop",
  });
  //
  const [comissionCGSData, setComissionCGSData] = useState<ComissionCGSData[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<E_FETCH_STATUS>(
    E_FETCH_STATUS.INITIATE
  );
  const [addModalCGSPackageShowing, setAddModalCGSPackageShowing] =
    useState<boolean>(false);
  const [deleteModalCGSPackageShowing, setDeleteModalCGSPackageShowing] =
    useState<boolean>(false);
  const [shopToBeDeleted, setShopToBeDeleted] = useState<string>("");
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [comissionTarget, setComissionTarget] = useState<number[]>([0]);
  const [comissionRate, setComissionRate] = useState<number[]>([0]);
  const [comissionShop, setComissionShop] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "Select Shop" });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [shopOptions, setShopOptions] = useState<ShopSelectData[]>([]);

  const resetFilter = () => {
    setFilterShop({ value: 0, label: "Select Shop" });
    fetchComissionCGSData(true);
  };

  const applyFilter = () => {
    fetchComissionCGSData();
  };

  const changeFilterShop = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterShop(e);
    }
  };

  const changeComissionShop = (e: SingleValue<ShopSelectData>) => {
    if (e) {
      setComissionShop(e);
    }
  };

  const openNewModalCGSPackageShowing = async () => {
    setAddModalCGSPackageShowing(true);
    setShopOptions([{ value: 0, label: "" }]);
    await ShopService.getShopSelectList().then((res) => {
      if (res.data) {
        const shopSelection: ShopSelectData[] = res.data.map((shop) => ({
          value: shop.id,
          label: shop.storeName,
        }));
        setShopOptions(shopSelection);
      }
    });
  };

  const renderComissionCGSPackageForm = () => {
    let temp: JSX.Element | JSX.Element[] = [];
    for (let id = 0; id < comissionTarget.length; id++) {
      temp.push(
        <RepetitiveCGSPackageForm
          id={id}
          comrate={comissionRate[id]}
          target={comissionTarget[id]}
          removeFormId={removeFormId}
          changeTargetComission={changeTargetComission}
          changeTargetComRate={changeTargetComRate}
        />
      );
    }
    return temp;
  };

  const removeFormId = (id: number) => {
    const tempTargetComission = [...comissionTarget];
    const tempTargetComissionRate = [...comissionRate];
    tempTargetComission.splice(id, 1);
    tempTargetComissionRate.splice(id, 1);
    setComissionTarget(tempTargetComission);
    setComissionRate(tempTargetComissionRate);
  };

  const changeTargetComission = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempTargetComission = [...comissionTarget];
    tempTargetComission[id] = Number(checkNumericInput(value));
    setComissionTarget(tempTargetComission);
  };

  const changeTargetComRate = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempTargetComissionRate = [...comissionRate];
    tempTargetComissionRate[id] = Number(checkNumericInput(value));
    setComissionRate(tempTargetComissionRate);
  };

  const addTarget = () => {
    setComissionTarget([...comissionTarget, 0]);
    setComissionRate([...comissionRate, 0]);
  };

  const openDetailComissionCGS = (id: number) => {
    navigate(`/comission-package-cgs/${id}`);
  };

  const openDeleteComissionCGSModal = (id: number, shopName: string) => {
    setShopToBeDeleted(shopName);
    setIdToBeDeleted(id);
    setDeleteModalCGSPackageShowing(true);
  };

  const getTableComissionCGS = () => {
    return comissionCGSData.map((com, index) => {
      return (
        <tr key={com.id}>
          <td>{index + 1}</td>
          <td>{com.storeName}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => openDetailComissionCGS(com.id)}
              >
                View Comission
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteComissionCGSModal(com.id, com.storeName)
                }
              >
                Delete Comission
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableComissionCGS = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (isLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          comissionCGSData.length > 0 ? (
            getTableComissionCGS()
          ) : (
            <tr>
              <td colSpan={3} style={{ textAlign: "center" }}>
                No Comission found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={3}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              No Comission found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Shop Name</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const fetchComissionCGSData = async (isReset?: boolean) => {
    let params = `?page_number=${currentPage}`;
    if(!isReset){
      if (filterShop?.value !== 0) {
        params = `${params}&shop=${filterShop.value}`;
      }
    }
    setIsLoading(E_FETCH_STATUS.FETCHING);
    await ComissionService.getComissionCGSPackage(encodeURI(params))
      .then((res) => {
        if (res.data) {
          setTotalPage(res.total_page);
          setComissionCGSData(res.data);
          setIsLoading(E_FETCH_STATUS.FETCHED);
        }
      })
      .catch((e) => {
        setIsLoading(E_FETCH_STATUS.ERROR);
      });
  };

  const addNewComissionCGSPackage = async () => {
    if (comissionShop.value === 0) {
      alert("Please select the shop that you want to be targeted");
      return false;
    }
    for (let i = 0; i < comissionTarget.length; i++) {
      if (comissionTarget[i] === 0) {
        alert(`Please input target comission number #${i + 1}`);
        return false;
      }
      if (comissionRate[i] === 0) {
        alert(`Please input comission rate for target number #${i + 1}`);
        return false;
      }
      if (i > 0 && comissionTarget[i] <= comissionTarget[i - 1]) {
        alert(`Please input a bigger value for target #${i + 1}`);
        return false;
      }
      if (i > 0 && comissionRate[i] <= comissionRate[i - 1]) {
        alert(`Please input a bigger value for comrate #${i + 1}`);
        return false;
      }
    }
    const bodyForm = new FormData();
    bodyForm.append("shopId", comissionShop.value.toString());
    bodyForm.append("comissionTarget", comissionTarget.toString());
    bodyForm.append("comissionRate", comissionRate.toString());
    await ComissionService.createComissionCGSPackage(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("You have successfully created the comission cgs package!");
          window.location.reload();
        } else {
          alert(res.error);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const deleteComissionCGSPackage = async () => {
    await ComissionService.deleteComissionCGSPackage(idToBeDeleted)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully delete the comission CGS Package");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchComissionCGSData();
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="2" xs="12" className="d-flex justify-content-end">
            <Button onClick={() => openNewModalCGSPackageShowing()}>
              <FaPlus /> Create New Comission
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterComissionCGSPackage
          filterShop={filterShop}
          resetFilter={resetFilter}
          applyFilter={applyFilter}
          changeFilterShop={changeFilterShop}
        />
        <br />
        {renderTableComissionCGS()}
        <hr />
        <Row>
          <Col className="pagination">
            <Pagination>
              <Pagination.First
                disabled={currentPage === 1}
                onClick={() => {
                  setCurrentPage(1);
                }}
              />
              <Pagination.Prev
                disabled={currentPage === 1}
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                }}
              />
              <Pagination.Item active>{currentPage}</Pagination.Item>
              <Pagination.Next
                disabled={currentPage === totalPage}
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                }}
              />
              <Pagination.Last
                disabled={currentPage === totalPage}
                onClick={() => {
                  setCurrentPage(totalPage);
                }}
              />
            </Pagination>
          </Col>
        </Row>
      </Container>

      {/* Add Modal */}
      <Modal
        onHide={() => {
          setAddModalCGSPackageShowing(false);
        }}
        show={addModalCGSPackageShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Comission CGS Package
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Shop Name</Form.Label>
          <Select
            options={shopOptions}
            value={comissionShop}
            onChange={(e) => changeComissionShop(e)}
          />
          {renderComissionCGSPackageForm()}
          <Button onClick={addTarget}>Add New Target</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setAddModalCGSPackageShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              addNewComissionCGSPackage();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal  */}
      <Modal
        onHide={() => {
          setDeleteModalCGSPackageShowing(false);
        }}
        show={deleteModalCGSPackageShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete comission cgs package for Store: {shopToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this comission?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteModalCGSPackageShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteComissionCGSPackage();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComissionCGSPackage;

import React, { ChangeEvent, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CategorySelectData } from "../../interfaces/categories";
import { BrandSelectData } from "../../interfaces/brand";
import CategoryService from "../../services/Category";
import Select, { SingleValue } from "react-select";
import {
  loadProductList,
  setProductCurrentPage,
  setProductFilter,
} from "../../redux/actions/product";
import { FilterProductInterface } from "../../interfaces/product";
import BrandService from "../../services/Brand";
import { E_PROMO_TYPE } from "../../constant";
import { ColourSelectData } from "../../interfaces/colour";
import ColourService from "../../services/Colour";

const FilterProducts = () => {
  const dispatch = useDispatch();
  const [brand, setBrand] = useState<BrandSelectData[]>([]);
  const [itemCategories, setItemCategories] = useState<CategorySelectData[]>(
    []
  );
  const [itemColours, setItemColours] = useState<ColourSelectData[]>([]);
  const [filterProductName, setFilterProductName] = useState<string>("");
  // const [filterProductColour, setFilterProductColour] = useState<string>("");
  const [filterProductColour, setFilterProductColour] = useState<
    SingleValue<ColourSelectData>
  >({
    value: 0,
    label: "Select Colour",
  });
  const [filterProductArticleNo, setFilterProductArticleNo] =
    useState<string>("");
  const [filterProductBrand, setFilterProductBrand] = useState<number>(0);
  const [filterProductCategory, setFilterProductCategory] = useState<
    SingleValue<CategorySelectData>
  >({
    value: 0,
    label: "Select Category",
  });
  const [filterProductPromoType, setFilterProductPromoType] =
    useState<E_PROMO_TYPE>(E_PROMO_TYPE.ALL);

  const resetFilter = () => {
    setFilterProductName("");
    setFilterProductColour({ value: 0, label: "Select Colour" });
    setFilterProductArticleNo("");
    setFilterProductBrand(0);
    setFilterProductCategory({
      value: 0,
      label: "Select Category",
    });
    setFilterProductPromoType(0);
    const filterTemp: FilterProductInterface = {
      filterProductName: "",
      filterProductArticleNo: "",
      filterProductColour: 0,
      filterProductCategory: 0,
      filterProductBrand: 0,
      filterProductPromoType: 0,
    };
    dispatch(setProductFilter(filterTemp));
    dispatch(loadProductList());
  };

  const applyFilter = () => {
    const filterTemp: FilterProductInterface = {
      filterProductName,
      filterProductArticleNo,
      filterProductColour: filterProductColour?.value || 0,
      filterProductCategory: filterProductCategory?.value || 0,
      filterProductBrand,
      filterProductPromoType,
    };
    dispatch(setProductCurrentPage(1));
    dispatch(setProductFilter(filterTemp));
    dispatch(loadProductList());
  };

  const changeFilterProductName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterProductName(value);
  };

  const changeFilterProductColour = (e: SingleValue<ColourSelectData>) => {
    if (e) {
      setFilterProductColour(e);
    }
  };

  const changeFilterProductArticleNo = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterProductArticleNo(value);
  };

  const changeFilterProductBrand = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterProductBrand(Number(value));
  };

  const changeFilterProductPromoType = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterProductPromoType(Number(value));
  };

  const changeFilterProductCategory = (e: SingleValue<CategorySelectData>) => {
    if (e) {
      setFilterProductCategory(e);
    }
  };

  const getSelection = async () => {
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrand(brandSelection);
      }
    });
    await CategoryService.getCategorySelect(filterProductBrand).then((res) => {
      if (res.data) {
        const categorySelection: CategorySelectData[] = [
          { label: "All Category", value: 0 },
        ];
        res.data.forEach((cat) => {
          categorySelection.push({
            value: cat.id,
            label: cat.categoryName,
          });
        });
        setItemCategories(categorySelection);
      }
    });
    await ColourService.getColourSelect(filterProductBrand).then((res) => {
      if (res.data) {
        const colourSelection: ColourSelectData[] = [
          { label: "All Colour", value: 0 },
        ];
        res.data.forEach((col) => {
          colourSelection.push({
            value: col.id,
            label: col.colourName,
          });
        });
        setItemColours(colourSelection);
      }
    });
  };

  useEffect(() => {
    getSelection();
    return () => {
      resetFilter();
    };
  }, []);

  useEffect(() => {
    setFilterProductCategory({
      value: 0,
      label: "Select Category",
    });
  }, [filterProductBrand]);

  const renderBrand = () => {
    return (
      brand.length > 0 &&
      brand.map((bran) => {
        return (
          <option value={bran.value} key={bran.value}>
            {bran.label}
          </option>
        );
      })
    );
  };

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={1}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Brand</Form.Label>
              <Form.Select
                aria-label="Default select example"
                value={filterProductBrand}
                onChange={(e) => changeFilterProductBrand(e)}
              >
                <option value={0}>All</option>
                {renderBrand()}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={1}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Promo Type</Form.Label>
              <Form.Select
                value={filterProductPromoType}
                onChange={(e) => changeFilterProductPromoType(e)}
              >
                <option value={0}>All</option>
                <option value={E_PROMO_TYPE.PROMO_TOKO}>Promo Toko</option>
                <option value={E_PROMO_TYPE.PROMO_ONLINE}>Promo Online</option>
                <option value={E_PROMO_TYPE.NON_PROMO}>Non Promo</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Category</Form.Label>
              <Select
                options={itemCategories}
                value={filterProductCategory}
                onChange={changeFilterProductCategory}
                // isDisabled={filterProductBrand === 0}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Colour</Form.Label>
              <Select
                options={itemColours}
                value={filterProductColour}
                onChange={changeFilterProductColour}
                // isDisabled={filterProductBrand === 0}
              />
              {/* <Form.Control
                type="text"
                placeholder="Colour"
                value={filterProductColour}
                onChange={changeFilterProductColour}
              /> */}
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Product Name"
                value={filterProductName}
                onChange={changeFilterProductName}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Article Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Article Number"
                value={filterProductArticleNo}
                onChange={changeFilterProductArticleNo}
              />
            </Form.Group>
          </Col>

          <Col
            md={2}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterProducts;

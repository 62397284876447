import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import { CategoryData, GetCategoryResponse } from "../../interfaces/categories";
import { PackageData } from "../../interfaces/package";
import CategoryService from "../../services/Category";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { PackageType } from "../types/packages";

export const setPackageList = (packages: PackageData[]) => {
  return {
    type: PackageType.PACKAGE_SET_LIST,
    payload: packages,
  };
};

export const setPackageStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: PackageType.PACKAGE_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setPackageCurrentPage = (currentPage: number) => {
  return {
    type: PackageType.PACKAGE_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setPackageTotalPage = (totalPage: number) => {
  return {
    type: PackageType.PACKAGE_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const loadPackageList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setPackageStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        packages: { currentPage },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      //   const res: GetPackageResponse = await PackageService.getPackages(params);
      //   if (res.data) {
      //     dispatch(setPackageList(res.data));
      //     dispatch(setPackageTotalPage(res.total_page));
      //     dispatch(setPackageStatusLoading(E_FETCH_STATUS.FETCHED));
      //   } else {
      //     dispatch(setPackageStatusLoading(E_FETCH_STATUS.ERROR));
      //   }
    } catch (e: any) {
      dispatch(setPackageStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

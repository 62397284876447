import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import {
  FilterProductInterface,
  ProductData,
  ProductGetResponse,
} from "../../interfaces/product";
import ProductService from "../../services/Products";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { ProductType } from "../types/product";

export const setProductList = (products: ProductData[]) => {
  return {
    type: ProductType.PRODUCT_SET_LIST,
    payload: products,
  };
};

export const setProductStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: ProductType.PRODUCT_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setProductCurrentPage = (currentPage: number) => {
  return {
    type: ProductType.PRODUCT_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setProductTotalPage = (totalPage: number) => {
  return {
    type: ProductType.PRODUCT_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const setProductFilter = (productFilter: FilterProductInterface) => {
  return {
    type: ProductType.PRODUCT_SET_FILTER,
    payload: productFilter,
  };
};

export const loadProductList = () => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setProductStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        product: {
          currentPage,
          filterProduct: {
            filterProductArticleNo,
            filterProductCategory,
            filterProductColour,
            filterProductBrand,
            filterProductName,
            filterProductPromoType,
          },
        },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      if (filterProductName !== "") {
        params += `&product_name=${filterProductName}`;
      }
      if (filterProductColour !== 0) {
        params += `&product_colour=${filterProductColour}`;
      }
      if (filterProductArticleNo !== "") {
        params += `&article_no=${filterProductArticleNo}`;
      }
      if (filterProductBrand !== 0) {
        params += `&brand=${filterProductBrand}`;
      }
      if (filterProductCategory !== 0) {
        params += `&product_category=${filterProductCategory}`;
      }
      if (filterProductPromoType !== 0) {
        params += `&promo_type=${filterProductPromoType}`;
      }
      const res: ProductGetResponse = await ProductService.getProductsList(
        params
      );
      if (res.data) {
        dispatch(setProductList(res.data));
        dispatch(setProductTotalPage(res.total_page));
        dispatch(setProductStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setProductStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setProductStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

import { AppStateType } from "../reducers";

export const listProvinsiSelector = (state: AppStateType) => {
  return state.daerah.provinsi;
};

export const listKabupatenSelector = (state: AppStateType) => {
  return state.daerah.kabupaten;
};

export const listKelurahanSelector = (state: AppStateType) => {
  return state.daerah.kelurahan;
};

export const listKecamatanSelector = (state: AppStateType) => {
  return state.daerah.kecamatan;
};

export const zipcodeSelector = (state: AppStateType) => {
  return state.daerah.zipcode;
};

import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { SingleValue } from "react-select";
import Select from "react-select";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import CategoryService from "../../services/Category";
import {
  loadCategoryList,
  setCategoryCurrentPage,
} from "../../redux/actions/category";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryCurrentPageSelector,
  categoryListSelector,
  categoryStatusLoadingSelector,
  categoryTotalPageSelector,
} from "../../redux/selectors/category";
import { BrandSelectData } from "../../interfaces/brand";
import BrandService from "../../services/Brand";
import FilterCategory from "../../component/FilterCategory";
import { DefaultSelection } from "../../interfaces";

const Category = () => {
  const categoriesList = useSelector(categoryListSelector);
  const statusLoading = useSelector(categoryStatusLoadingSelector);
  const currentPage = useSelector(categoryCurrentPageSelector);
  const totalPage = useSelector(categoryTotalPageSelector);

  // Filter State
  const [filterBrandName, setFilterBrandName] = useState<DefaultSelection>({
    label: "All Brand",
    value: 0,
  });
  const [filterCategoryName, setFilterCategoryName] =
    useState<DefaultSelection>({ label: "All Category", value: 0 });
  //
  const [addCategoryModalShowing, setAddCategoryModalShowing] =
    useState<boolean>(false);
  const [deleteCategoryModalShowing, setDeleteCategoryModalShowing] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [newBrand, setNewBrand] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "Select Item Type" });
  const [brandOptions, setBrandOptions] = useState<BrandSelectData[]>([]);
  const dispatch = useDispatch();

  const getBrandSelection = async () => {
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrandOptions(brandSelection);
      }
    });
  };

  const openAddCategoryModal = () => {
    setAddCategoryModalShowing(true);
    getBrandSelection();
  };

  const deleteCategory = async () => {
    await CategoryService.deleteCategory(idToBeDeleted)
      .then((res) => {
        if (res.status !== 0) {
          alert("You have deleted one category succesfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const openDeleteCategoryModal = (id: number, name: string) => {
    setDeleteCategoryModalShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
  };

  const createNewCategory = async () => {
    if (newBrand.value === 0) {
      alert("Please select this category item type");
      return false;
    }

    if (newCategoryName === "") {
      alert("Please fill the category name field");
    }
    const bodyForm = new FormData();
    bodyForm.append("categoryName", newCategoryName);
    bodyForm.append("brandId", newBrand.value.toString());
    await CategoryService.createCategory(bodyForm)
      .then((res) => {
        if (res.status !== 0) {
          alert("You have created the new category succesfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const changeNewCategoryName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewCategoryName(value);
  };

  const changeNewBrand = (e: SingleValue<BrandSelectData>) => {
    if (e) {
      setNewBrand(e);
    }
  };

  const getTableCategory = () => {
    return categoriesList.map((category, index) => {
      return (
        <tr key={category.id}>
          <td>{category.id}</td>
          <td>{category.brand}</td>
          <td>{category.categoryName}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteCategoryModal(category.id, category.categoryName)
                }
              >
                Delete Category
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableCategory = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          categoriesList.length > 0 ? (
            getTableCategory()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No Category Found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              No Category Found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Brand</th>
            <th>Category Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  // Filter Handler
  const handleBrandNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterBrandName(e);
    }
  };

  const handleCategoryNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterCategoryName(e);
    }
  };

  const applyFilter = () => {
    dispatch(
      loadCategoryList(filterBrandName?.value, filterCategoryName?.value)
    );
  };

  const resetFilter = () => {
    resetStateFilter();
    dispatch(loadCategoryList());
  };

  const resetStateFilter = () => {
    setFilterBrandName({ label: "All Brand", value: 0 });
    setFilterCategoryName({ label: "All Category", value: 0 });
  };
  //

  useEffect(() => {
    getBrandSelection();
    dispatch(loadCategoryList());
  }, []);

  useEffect(() => {
    setFilterCategoryName({ label: "All Category", value: 0 });
  }, [filterBrandName]);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="10" xs="12" className="d-flex justify-content-end">
            <Button onClick={openAddCategoryModal}>
              <FaPlus /> Add New Category
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterCategory
          brandName={filterBrandName}
          categoryName={filterCategoryName}
          handleBrandNameFilterChange={handleBrandNameFilterChange}
          handleCategoryNameFilterChange={handleCategoryNameFilterChange}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableCategory()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCategoryCurrentPage(1));
                    dispatch(
                      loadCategoryList(
                        filterBrandName?.value,
                        filterCategoryName?.value
                      )
                    );
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setCategoryCurrentPage(currentPage - 1));
                    dispatch(
                      loadCategoryList(
                        filterBrandName?.value,
                        filterCategoryName?.value
                      )
                    );
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                    type="text"
                    className="pagination-number"
                    value={currentPage}
                    onChange={(e) => changeCurrentPageNumber(e)}
                  /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setCategoryCurrentPage(currentPage + 1));
                    dispatch(
                      loadCategoryList(
                        filterBrandName?.value,
                        filterCategoryName?.value
                      )
                    );
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setCategoryCurrentPage(totalPage));
                    dispatch(
                      loadCategoryList(
                        filterBrandName?.value,
                        filterCategoryName?.value
                      )
                    );
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        onHide={() => {
          // setNewNameType("");
          setAddCategoryModalShowing(false);
        }}
        show={addCategoryModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Category
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Brand</Form.Label>
          <Select
            options={brandOptions}
            value={newBrand}
            onChange={(e) => changeNewBrand(e)}
          />
          <Form.Label>Nama Category</Form.Label>
          <Form.Control
            type="text"
            placeholder="Product Name"
            value={newCategoryName}
            onChange={(e) => changeNewCategoryName(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setAddCategoryModalShowing(false);
            }}
            variant="outline-primary"
          >
            Close
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              createNewCategory();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Confirmation Delete */}
      <Modal
        onHide={() => {
          // setNewNameType("");
          setDeleteCategoryModalShowing(false);
        }}
        show={deleteCategoryModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete {nameToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Category?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteCategoryModalShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteCategory();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Category;

import { E_FETCH_STATUS, E_PROMO_TYPE } from "../../constant";
import { FilterProductInterface, ProductData } from "../../interfaces/product";
import {
  ProductAction,
  ProductSetCurrentPage,
  ProductSetFilter,
  ProductSetList,
  ProductSetStatusLoading,
  ProductSetTotalPage,
  ProductType,
} from "../types/product";

interface ProductState {
  filterProduct: FilterProductInterface;
  productList: ProductData[];
  statusLoading: E_FETCH_STATUS;
  currentPage: number;
  totalPage: number;
}

const initialState: ProductState = {
  filterProduct: {
    filterProductName: "",
    filterProductArticleNo: "",
    filterProductColour: 0,
    filterProductCategory: 0,
    filterProductBrand: 0,
    filterProductPromoType: E_PROMO_TYPE.ALL,
  },
  productList: [],
  statusLoading: E_FETCH_STATUS.INITIATE,
  currentPage: 1,
  totalPage: 0,
};

const {
  PRODUCT_SET_LIST,
  PRODUCT_SET_STATUS_LOADING,
  PRODUCT_SET_CURRENT_PAGE,
  PRODUCT_SET_TOTAL_PAGE,
  PRODUCT_SET_FILTER,
} = ProductType;

const product = (
  state: ProductState = initialState,
  action: ProductAction
): ProductState => {
  const { type } = action;

  const setProductList = (): ProductState => {
    const { payload: products } = action as ProductSetList;
    return {
      ...state,
      productList: products,
    };
  };

  const setProductStatusLoading = (): ProductState => {
    const { payload: statusLoading } = action as ProductSetStatusLoading;
    return {
      ...state,
      statusLoading,
    };
  };

  const setProductCurrentPage = (): ProductState => {
    const { payload: currentPage } = action as ProductSetCurrentPage;
    return {
      ...state,
      currentPage,
    };
  };

  const setProductTotalPage = (): ProductState => {
    const { payload: totalPage } = action as ProductSetTotalPage;
    return {
      ...state,
      totalPage,
    };
  };

  const setProductFilter = (): ProductState => {
    const { payload: filterProduct } = action as ProductSetFilter;
    return {
      ...state,
      filterProduct,
    };
  };

  switch (type) {
    case PRODUCT_SET_LIST:
      return setProductList();
    case PRODUCT_SET_STATUS_LOADING:
      return setProductStatusLoading();
    case PRODUCT_SET_CURRENT_PAGE:
      return setProductCurrentPage();
    case PRODUCT_SET_TOTAL_PAGE:
      return setProductTotalPage();
    case PRODUCT_SET_FILTER:
      return setProductFilter();
    default:
      return state;
  }
};

export default product;

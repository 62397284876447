import { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { CategoryData } from "../../interfaces/categories";
import BrandService from "../../services/Brand";
import CategoryService from "../../services/Category";

interface FilterCategoryProps {
  brandName: DefaultSelection;
  categoryName: DefaultSelection;
  handleBrandNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  handleCategoryNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  applyFilter: () => void;
  resetFilter: () => void;
}

const FilterCategory: FC<FilterCategoryProps> = (props) => {
  const {
    brandName,
    categoryName,
    handleBrandNameFilterChange,
    handleCategoryNameFilterChange,
    applyFilter,
    resetFilter,
  } = props;
  const [categoryList, setCategoryList] = useState<DefaultSelection[]>([]);
  const [brandList, setBrandList] = useState<DefaultSelection[]>([]);
  const getBrandList = async () => {
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandListTemp: DefaultSelection[] = res.data.map((brand) => {
          return {
            label: brand.name,
            value: brand.id,
          };
        });
        setBrandList(brandListTemp);
      }
    });
  };

  const getCategoryList = async () => {
    await CategoryService.getCategorySelect(brandName?.value).then((res) => {
      if (res.data) {
        const categoryListTemp: DefaultSelection[] = res.data.map(
          (category) => {
            return {
              label: category.categoryName,
              value: category.id,
            };
          }
        );
        setCategoryList(categoryListTemp);
      }
    });
  };

  useEffect(() => {
    getBrandList();
    getCategoryList();
  }, []);

  useEffect(() => {
    getCategoryList();
  }, [brandName]);
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={4}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Brand</Form.Label>
              <Select
                options={brandList}
                value={brandName}
                onChange={handleBrandNameFilterChange}
              />
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Category</Form.Label>
              <Select
                options={categoryList}
                value={categoryName}
                onChange={handleCategoryNameFilterChange}
              />
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 2, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterCategory;

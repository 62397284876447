import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ModalAddNewShop from "../../component/ModalAddNewShop";
import ModalEditShop from "../../component/ModalEditShop";
import ModalDeleteShop from "../../component/ModalDeleteShop";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import { loadShopList, setShopCurrentPage } from "../../redux/actions/shop";
import {
  shopCurrentPageSelector,
  shopListSelector,
  shopStatusLoadingSelector,
  shopTotalPageSelector,
} from "../../redux/selectors/shop";
import { useNavigate } from "react-router";
import { DefaultSelection } from "../../interfaces";
import { SingleValue } from "react-select";
import FilterStore from "../../component/FilterStore";

const Shops: FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const shopList = useSelector(shopListSelector);
  const currentPage = useSelector(shopCurrentPageSelector);
  const totalPage = useSelector(shopTotalPageSelector);
  const statusLoading = useSelector(shopStatusLoadingSelector);

  // Filter State
  const [filterStoreName, setFilterStoreName] = useState<DefaultSelection>({
    label: "All Store",
    value: 0,
  });
  const [filterStoreOwnerName, setFilterStoreOwnerName] = useState<string>("");
  const [filterStoreNPWP, setFilterStoreNPWP] = useState<string>("");
  //

  const [showAddShopModal, setShowAddShopModal] = useState<boolean>(false);
  const [showEditShopModal, setShowEditShopModal] = useState<boolean>(false);
  const [showDeleteShopModal, setShowDeleteShopModal] =
    useState<boolean>(false);
  const [idShopEdited, setIdShopEdited] = useState<number>(0);
  const [idShopDeleted, setIdShopDeleted] = useState<number>(0);

  const openNewShopModal = () => {
    setShowAddShopModal(true);
  };

  const navigateToDetail = (shopId: number) => {
    navigate(shopId);
  };

  const onModalAddShopClose = () => {
    setShowAddShopModal(false);
  };

  const openDeleteShopModal = (shopId: number) => {
    setIdShopDeleted(shopId);
    setShowDeleteShopModal(true);
  };

  const onModalEditShopClose = () => {
    setShowEditShopModal(false);
    setIdShopEdited(0);
  };

  const onModalDeleteShopClose = () => {
    setShowDeleteShopModal(false);
  };

  const getTableShop = () => {
    return shopList.map((shop, index) => {
      return (
        <tr key={shop.id}>
          <td>{index + 1}</td>
          <td>{shop.storeName}</td>
          <td>{shop.pemilikToko}</td>
          <td>{`+62${shop.contactToko}`}</td>
          <td>{shop.alamatToko}</td>
          <td>{shop.npwpToko}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => navigateToDetail(shop.id)}
              >
                Edit Shop
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() => openDeleteShopModal(shop.id)}
              >
                Delete Shop
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableShop = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          shopList.length > 0 ? (
            getTableShop()
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: "center" }}>
                No Shops found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={7}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
              No Shops found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Nama Toko</th>
            <th>Nama Pemilik Toko</th>
            <th>Kontak Pemilik Toko</th>
            <th>Alamat Toko</th>
            <th style={{ width: "20%" }}>NPWP Toko</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  // Filter Handler
  const handleStoreNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) setFilterStoreName(e);
  };

  const handleStoreOwnerNameFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterStoreOwnerName(value);
  };

  const handleStoreNPWPFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterStoreNPWP(value);
  };

  const applyFilter = () => {
    dispatch(
      loadShopList(
        filterStoreName?.value,
        filterStoreOwnerName,
        filterStoreNPWP
      )
    );
  };

  const resetFilter = () => {
    resetState();
    dispatch(loadShopList());
  };

  const resetState = () => {
    setFilterStoreName({ label: "All Store", value: 0 });
    setFilterStoreOwnerName("");
    setFilterStoreNPWP("");
  };

  useEffect(() => {
    dispatch(loadShopList());
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="2" xs="12" className="d-flex justify-content-end">
            <Button onClick={openNewShopModal}>
              <FaPlus /> Create New Shop
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterStore
          storeName={filterStoreName}
          storeOwnerName={filterStoreOwnerName}
          storeNPWP={filterStoreNPWP}
          handleStoreNameFilterChange={handleStoreNameFilterChange}
          handleStoreOwnerNameFilterChange={handleStoreOwnerNameFilterChange}
          handleStoreNPWPFilterChange={handleStoreNPWPFilterChange}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableShop()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setShopCurrentPage(1));
                    dispatch(loadShopList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setShopCurrentPage(currentPage - 1));
                    dispatch(loadShopList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setShopCurrentPage(currentPage + 1));
                    dispatch(loadShopList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setShopCurrentPage(totalPage));
                    dispatch(loadShopList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>

      <ModalAddNewShop
        isShowing={showAddShopModal}
        onModalAddShopClose={onModalAddShopClose}
      />
      <ModalEditShop
        isShowing={showEditShopModal}
        idEdited={idShopEdited}
        onModalEditShopClose={onModalEditShopClose}
      />
      <ModalDeleteShop
        isShowing={showDeleteShopModal}
        idDeleted={idShopDeleted}
        onModalDeleteShopClose={onModalDeleteShopClose}
      />
    </>
  );
};

export default Shops;

import React, { ChangeEvent, FC, Fragment, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { E_PROMO_TYPE } from "../../constant";
import Select, { SingleValue } from "react-select";
import ProductService from "../../services/Products";
import {
  ProductColourSelectData,
  ProductSelectData,
} from "../../interfaces/product";
import "./index.scss";
import { FaTrashAlt } from "react-icons/fa";
import BrandService from "../../services/Brand";
import { BrandSelectData } from "../../interfaces/brand";
import thousandSeparator from "../../utils/thousandSeparator";
import ColourService from "../../services/Colour";

interface RepetitiveFormSalesReportProps {
  id: number;
  promoType: E_PROMO_TYPE;
  productChosen: SingleValue<ProductSelectData>;
  productCount: number;
  productBrandChosen: number;
  productPrice: number;
  productColourChosen: SingleValue<ProductColourSelectData>;
  changePromoType: (e: ChangeEvent, id: number) => void;
  changeProductChosen: (e: SingleValue<ProductSelectData>, id: number) => void;
  changeProductColourChosen: (
    e: SingleValue<ProductColourSelectData>,
    id: number
  ) => void;
  changeProductCount: (e: ChangeEvent, id: number) => void;
  changeProductBrandChosen: (e: ChangeEvent, id: number) => void;
  removeFormId: (id: number) => void;
}

const RepetitiveFormSalesReport: FC<RepetitiveFormSalesReportProps> = ({
  id,
  promoType,
  productChosen,
  productCount,
  productBrandChosen,
  productColourChosen,
  productPrice,
  changePromoType,
  changeProductBrandChosen,
  changeProductChosen,
  changeProductCount,
  changeProductColourChosen,
  removeFormId,
}) => {
  console.log(productChosen);
  const [productList, setProductList] = useState<ProductSelectData[]>([]);
  const [productColourList, setProductColourList] = useState<
    ProductColourSelectData[]
  >([]);
  const [brandList, setBrandList] = useState<BrandSelectData[]>([]);

  useEffect(() => {
    if (productBrandChosen !== 0) {
      ProductService.getFormProductSelection(
        promoType,
        productBrandChosen
      ).then((res) => {
        if (res.data) {
          console.log(res.data);
          const tempProductSelection: ProductSelectData[] = res.data.map(
            (product) => {
              return {
                value: product.id,
                label: product.productName,
                price: product.price,
                colourAvailable: product.colourAvailable,
              };
            }
          );
          setProductList(tempProductSelection);
        }
      });
    }
  }, [promoType, productBrandChosen]);

  const getSilgranitColour = async () => {
    const body = new FormData();
    body.append("productId", productChosen?.value.toString() || "");
    body.append("promoType", promoType.toString());
    await ColourService.getSilgranitColour(body).then((res) => {
      if (res.data) {
        const tempColourSelection: ProductColourSelectData[] = res.data.map(
          (colour) => {
            return {
              value: colour.id,
              label: colour.colourName,
            };
          }
        );
        setProductColourList(tempColourSelection);
      }
    });
  };

  useEffect(() => {
    if (productChosen?.colourAvailable) {
      getSilgranitColour();
    }
  }, [productChosen]);

  useEffect(() => {
    getSelection();
  }, []);

  const getSelection = () => {
    BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrandList(brandSelection);
      }
    });
  };

  const renderBrand = () => {
    return (
      brandList.length > 0 &&
      brandList.map((brand) => {
        return (
          <option value={brand.value} key={brand.value}>
            {brand.label}
          </option>
        );
      })
    );
  };

  return (
    <Fragment key={id}>
      <div className="repetitive-form-wrapper">
        {id >= 1 && (
          <div
            className="icon-delete-repetitive-form"
            onClick={() => removeFormId(id)}
          >
            <FaTrashAlt />
          </div>
        )}
        <Form.Label>Brand Produk #{id + 1}</Form.Label>
        <Form.Select
          aria-label="Default select example"
          value={productBrandChosen}
          onChange={(e) => changeProductBrandChosen(e, id)}
        >
          <option value={0}>All</option>
          {renderBrand()}
        </Form.Select>
        <Form.Label>Tipe Barang #{id + 1}</Form.Label>
        <Form.Select value={promoType} onChange={(e) => changePromoType(e, id)}>
          <option value={E_PROMO_TYPE.PROMO_TOKO}>Promo Toko</option>
          <option value={E_PROMO_TYPE.PROMO_ONLINE}>Promo Online</option>
          <option value={E_PROMO_TYPE.NON_PROMO}>Non Promo</option>
        </Form.Select>
        <Form.Label>Product #{id + 1}</Form.Label>
        <Select
          options={productList}
          value={productChosen}
          onChange={(e) => changeProductChosen(e, id)}
          isDisabled={productBrandChosen === 0}
        />
        {productChosen && productChosen.colourAvailable && (
          <>
            <Form.Label>Product Colour #{id + 1}</Form.Label>
            <Select
              options={productColourList}
              value={productColourChosen}
              onChange={(e) => changeProductColourChosen(e, id)}
            />
          </>
        )}
        <Form.Label>Harga Satuan (Brosur) #{id + 1}</Form.Label>
        <Form.Control
          type="text"
          placeholder="Harga Satuan Product"
          value={`Rp. ${thousandSeparator(productPrice)}`}
          readOnly
        />
        <Form.Label>Jumlah Product #{id + 1}</Form.Label>
        <Form.Control
          type="text"
          placeholder="Jumlah Product"
          value={productCount}
          onChange={(e) => changeProductCount(e, id)}
        />
      </div>
    </Fragment>
  );
};

export default RepetitiveFormSalesReport;

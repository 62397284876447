import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { FaPlus } from "react-icons/fa";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import BrandService from "../../services/Brand";
import { UserSelectData } from "../../interfaces/user";
import { BrandSelectData } from "../../interfaces/brand";
import { SalesTargetData } from "../../interfaces/salestarget";
import RepetitiveSalesTargetForm from "../../component/RepetitiveSalesTargetForm";
import UserService from "../../services/User";
import SalesTargetService from "../../services/SalesTarget";
import FilterComissionTarget from "../../component/FilterComissionTarget";
import checkNumericInput from "../../utils/checkNumericInput";
import thousandSeparator from "../../utils/thousandSeparator";
import { useNavigate } from "react-router";
import { ComissionUserSelection } from "../../interfaces/comission";
import {
  setProductCurrentPage,
  loadProductList,
} from "../../redux/actions/product";

const Comission = () => {
  const navigate = useNavigate();
  const [statusLoading, setStatusLoading] = useState<E_FETCH_STATUS>(
    E_FETCH_STATUS.INITIATE
  );
  const [deleteModalComissionShowing, setDeleteModalComissionShowing] =
    useState<boolean>(false);
  const [addModalComissionShowing, setAddModalComissionShowing] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");
  const [brandToBeDeleted, setBrandToBeDeleted] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [brandOptions, setBrandOptions] = useState<BrandSelectData[]>([]);
  const [userOptions, setUserOptions] = useState<UserSelectData[]>([]);
  const [comissionBrand, setComissionBrand] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "Select Brand" });

  const [comissionUser, setComissionUser] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "Select Sales Name" });
  const [comissionList, setComissionList] = useState<SalesTargetData[]>([]);
  const [comissionTarget, setComissionTarget] = useState<number[]>([0]);
  const [comissionRate, setComissionRate] = useState<number[]>([0]);
  const [filterBrand, setFilterBrand] = useState<number>(0);
  const [filterUser, setFilterUser] = useState<ComissionUserSelection>({
    value: 0,
    label: "All User",
  });

  const resetFilter = () => {
    setFilterBrand(0);
    setFilterUser({ value: 0, label: "All User" });
    loadComissionTarget(true);
  };

  const applyFilter = () => {
    loadComissionTarget();
  };

  const changeFilterBrand = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterBrand(Number(value));
  };

  const changeFilterUser = (e: SingleValue<ComissionUserSelection>) => {
    if (e) {
      setFilterUser(e);
    }
  };

  const changeComissionBrand = (e: SingleValue<BrandSelectData>) => {
    if (e) {
      setComissionBrand(e);
    }
  };

  const changeComissionUser = (e: SingleValue<BrandSelectData>) => {
    if (e) {
      setComissionUser(e);
    }
  };

  const changeTargetComission = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempTargetComission = [...comissionTarget];
    tempTargetComission[id] = Number(checkNumericInput(value));
    setComissionTarget(tempTargetComission);
  };

  const changeTargetComRate = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempTargetComissionRate = [...comissionRate];
    tempTargetComissionRate[id] = Number(checkNumericInput(value));
    setComissionRate(tempTargetComissionRate);
  };

  const removeFormId = (id: number) => {
    const tempTargetComission = [...comissionTarget];
    const tempTargetComissionRate = [...comissionRate];
    tempTargetComission.splice(id, 1);
    tempTargetComissionRate.splice(id, 1);
    setComissionTarget(tempTargetComission);
    setComissionRate(tempTargetComissionRate);
  };

  const addTarget = () => {
    setComissionTarget([...comissionTarget, 0]);
    setComissionRate([...comissionRate, 0]);
  };

  const addNewComission = () => {
    if (comissionUser.value === 0) {
      alert("Please select the user that you want to be targeted");
      return false;
    }
    if (comissionBrand.value === 0) {
      alert("Please select the brand that the user want to be targeted with");
      return false;
    }
    for (let i = 0; i < comissionTarget.length; i++) {
      if (comissionTarget[i] === 0) {
        alert(`Please input target comission number #${i + 1}`);
        return false;
      }
      if (comissionRate[i] === 0) {
        alert(`Please input comission rate for target number #${i + 1}`);
        return false;
      }
      if (i > 0 && comissionTarget[i] <= comissionTarget[i - 1]) {
        alert(`Please input a bigger value for target #${i + 1}`);
        return false;
      }
      if (i > 0 && comissionRate[i] <= comissionRate[i - 1]) {
        alert(`Please input a bigger value for comrate #${i + 1}`);
        return false;
      }
    }
    const bodyForm = new FormData();
    bodyForm.append("comissionUser", comissionUser.value.toString());
    bodyForm.append("comissionBrand", comissionBrand.value.toString());
    bodyForm.append("comissionTarget", comissionTarget.toString());
    bodyForm.append("comissionRate", comissionRate.toString());
    SalesTargetService.createSalesTarget(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully create a new sales target");
          window.location.reload();
          return true;
        }
      })
      .catch((e) => alert(e));
  };

  const openNewModalComissionShowing = async () => {
    setAddModalComissionShowing(true);
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrandOptions(brandSelection);
      }
    });
    await UserService.getSalesList().then((res) => {
      if (res.data) {
        const userSelection: UserSelectData[] = res.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        setUserOptions(userSelection);
      }
    });
  };

  const openDetailComission = (id: number) => {
    navigate(`/comission-detail/${id}`);
  };

  const openDeleteComissionModal = (
    id: number,
    name: string,
    brand: string
  ) => {
    setDeleteModalComissionShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
    setBrandToBeDeleted(brand);
  };

  const deleteComission = () => {
    SalesTargetService.deleteSalesTarget(idToBeDeleted).then((res) => {
      if (res.status === 1) {
        alert(
          `Successfully delete the comission for ${nameToBeDeleted}(${brandToBeDeleted})`
        );
        window.location.reload();
      }
    });
  };

  const getTableComission = () => {
    return comissionList.map((com, index) => {
      return (
        <tr key={com.id}>
          <td>{index + 1}</td>
          <td>{com.name}</td>
          <td>{com.brand}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => openDetailComission(com.id)}
              >
                View Comission
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteComissionModal(com.id, com.name, com.brand)
                }
              >
                Delete Comission
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableComission = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          comissionList.length > 0 ? (
            getTableComission()
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No Comission found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Comission found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>User</th>
            <th>Comission Brand</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const renderSalesTargetForm = () => {
    let temp: JSX.Element | JSX.Element[] = [];
    for (let id = 0; id < comissionTarget.length; id++) {
      temp.push(
        <RepetitiveSalesTargetForm
          id={id}
          brandName={comissionBrand.label}
          comrate={comissionRate[id]}
          target={comissionTarget[id]}
          removeFormId={removeFormId}
          changeTargetComission={changeTargetComission}
          changeTargetComRate={changeTargetComRate}
        />
      );
    }

    return temp;
  };

  const loadComissionTarget = async (isReset?: boolean) => {
    setStatusLoading(E_FETCH_STATUS.FETCHING);
    let params = `?page_number=${currentPage}`;
    if(!isReset){
      if (filterUser?.value !== 0) {
        params = `${params}&user=${filterUser.value}`;
      }
      if (filterBrand !== 0) {
        params = `${params}&brand=${filterBrand}`;
      }
    }
    await SalesTargetService.getSalesTarget(params)
      .then((res) => {
        if (res.data) {
          const salesTargetTemp: SalesTargetData[] = res.data.map(
            (salestarget) => ({
              id: salestarget.id,
              name: salestarget.name,
              brand: salestarget.brand,
            })
          );
          setComissionList(salesTargetTemp);
          setTotalPage(res.total_page);
          setStatusLoading(E_FETCH_STATUS.FETCHED);
        }
      })
      .catch((e) => {
        alert(e);
        setStatusLoading(E_FETCH_STATUS.ERROR);
      });
  };

  useEffect(() => {
    loadComissionTarget();
  }, [currentPage]);

  useEffect(() => {
    console.log(comissionList);
  }, [comissionList]);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="2" xs="12" className="d-flex justify-content-end">
            <Button onClick={() => openNewModalComissionShowing()}>
              <FaPlus /> Create New Comission
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterComissionTarget
          filterBrand={filterBrand}
          filterUser={filterUser}
          resetFilter={resetFilter}
          applyFilter={applyFilter}
          changeFilterBrand={changeFilterBrand}
          changeFilterUser={changeFilterUser}
        />
        <br />
        <Row>
          <Col>{renderTableComission()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                />
                <Pagination.Item active>{currentPage}</Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    setCurrentPage(totalPage);
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      {/* Add Modal */}
      <Modal
        onHide={() => {
          // setNewNameType("");
          setAddModalComissionShowing(false);
        }}
        show={addModalComissionShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Comission Mapping
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Sales Name</Form.Label>
          <Select
            options={userOptions}
            value={comissionUser}
            onChange={(e) => changeComissionUser(e)}
          />
          <Form.Label>Brand</Form.Label>
          <Select
            options={brandOptions}
            value={comissionBrand}
            onChange={(e) => changeComissionBrand(e)}
          />
          {renderSalesTargetForm()}
          <Button onClick={addTarget}>Add New Target</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setAddModalComissionShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              addNewComission();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Modal  */}
      <Modal
        onHide={() => {
          setDeleteModalComissionShowing(false);
        }}
        show={deleteModalComissionShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete comission {nameToBeDeleted} for {brandToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this comission?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteModalComissionShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteComission();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Comission;

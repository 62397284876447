const checkNumericInput = (value: any, max?: any): string => {
  if (!isNaN(parseInt(value, 10))) {
    if (value > max) {
      return Math.abs(parseInt(max, 10)).toString();
    }
    return Math.abs(parseInt(value, 10)).toString();
  }
  return "";
};

export default checkNumericInput;

import { API_URL, BASE_URL } from "../constant";
import {
  CreateBrandResponse,
  DeleteBrandResponse,
  GetBrandResponse,
  GetBrandSalesReportResponse,
} from "../interfaces/brand";
import { getCookie } from "../utils/cookies";

class BrandService {
  public static async getBrand(
    filterBrandId?: number
  ): Promise<GetBrandResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getBrand}${
        filterBrandId ? `?brandId=${filterBrandId}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetBrandResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getBrandSalesReport(): Promise<GetBrandSalesReportResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getBrandSalesReport}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetBrandSalesReportResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Brand not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createBrand(
    body: FormData
  ): Promise<CreateBrandResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createNewBrand}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: CreateBrandResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteBrand(id: number): Promise<DeleteBrandResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteBrand}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DeleteBrandResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default BrandService;

import { API_URL, BASE_URL } from "../constant";
import { getCookie } from "../utils/cookies";
import {
  CountComissionResponse,
  CreateComissionCGSPackageResponse,
  GetComissionCGSPackageDetailResponse,
  GetComissionCGSPackageResponse,
  GetComissionMappingResponse,
} from "../interfaces/comission";
import { DefaultDeleteResponse, DefaultUpdateResponse } from "../interfaces";

class ComissionService {
  public static async getComissions(
    filterMonth: number,
    filterYear: number
  ): Promise<CountComissionResponse> {
    const cookiesUser = getCookie("user");
    const params = `?month=${filterMonth}&year=${filterYear}`;
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.countComission}${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: CountComissionResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getComissionMapping(): Promise<GetComissionMappingResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getComissionMapping}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionMappingResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createComissionCGSPackage(
    body: FormData
  ): Promise<CreateComissionCGSPackageResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.createComissionCGSPackage}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: CreateComissionCGSPackageResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteComissionCGSPackage(
    id: number
  ): Promise<DefaultDeleteResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteComissionCGSPackage}/${id}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DefaultDeleteResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async updateComissionCGSPackage(
    id: number,
    body: FormData
  ): Promise<DefaultUpdateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.updateComissionCGSPackage}/${id}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: DefaultUpdateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getComissionCGSPackage(
    params?: string
  ): Promise<GetComissionCGSPackageResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getComissionCGSPackage}${params ? params : null}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionCGSPackageResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getComissionCGSPackageDetail(
    id: number
  ): Promise<GetComissionCGSPackageDetailResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getComissionCGSPackageDetail}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionCGSPackageDetailResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ComissionService;

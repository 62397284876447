const checkDecimalInput = (value: string, max?: number): string => {
  if (!isNaN(Number(value))) {
    if (max && Number(value) > max) {
      return max.toString();
    }
    if (value === "") {
      return "0";
    }
    if (value.startsWith("0") && value[1] !== ".") {
      return value[1];
    }
    return value;
  }
  return value.slice(0, value.length - 1).toString();
};

export default checkDecimalInput;

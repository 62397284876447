import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import NavBar from "../../component/Navbar";
import { E_AUTH_STATUS, E_FETCH_STATUS, UserStatus } from "../../constant";
import { loadUserList, setUserCurrentPage } from "../../redux/actions/user";
import {
  userCurrentPageSelector,
  userListSelector,
  userStatusLoadingSelector,
  userTotalPageSelector,
} from "../../redux/selectors/user";
import { FaPlus } from "react-icons/fa";
import { compose } from "redux";
import ModalAddNewUser from "../../component/ModalAddNewUser";
import { useNavigate } from "react-router";
import withShop from "../../component/withShop";
import UserService from "../../services/User";
import FilterUser from "../../component/FilterUser";
import { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";

const Users: FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [deleteUserModalShowing, setDeleteUserModalShowing] =
    useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");

  // Filter
  const [filterUserFullName, setFilterUserFullName] =
    useState<DefaultSelection>({ value: 0, label: "All User" });
  const [filterUserStatus, setFilterUserStatus] = useState<E_AUTH_STATUS>(
    E_AUTH_STATUS.INIT
  );
  //
  const statusLoading = useSelector(userStatusLoadingSelector);
  const userList = useSelector(userListSelector);
  const currentPage = useSelector(userCurrentPageSelector);
  const totalPage = useSelector(userTotalPageSelector);

  const openNewUserModal = () => {
    setShowAddUserModal(true);
  };

  const onModalAddUserClose = () => {
    setShowAddUserModal(false);
  };

  const deactivateUser = async (userId: number) => {
    await UserService.deleteUser(userId)
      .then((data) => {
        if (data) {
          alert("Successfully deactivate the user");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const openDeleteUserModal = (userId: number, name: string) => {
    setDeleteUserModalShowing(true);
    setIdToBeDeleted(userId);
    setNameToBeDeleted(name);
  };

  const getTableUser = () => {
    return userList.map((user, index) => {
      return (
        <tr key={user.id}>
          <td>{index + 1}</td>
          <td>{user.username}</td>
          <td>{`${user.firstName} ${user.lastName}`}</td>
          <td>{user.email}</td>
          <td>{UserStatus[user.status]}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button variant="primary" onClick={() => navigate(user.id)}>
                View User
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteUserModal(
                    user.id,
                    `${user.firstName} ${user.lastName}`
                  )
                }
              >
                Deactivate User
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableUser = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          userList.length > 0 ? (
            getTableUser()
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No users found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={6}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No users found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Username</th>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  // Filter Handler
  const handleUserFullNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) {
      setFilterUserFullName(e);
    }
  };

  const handleUserStatusFilterChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterUserStatus(Number(value) as E_AUTH_STATUS);
  };

  const resetFilter = () => {
    resetFilterState();
    dispatch(loadUserList());
  };

  const applyFilter = () => {
    dispatch(loadUserList(filterUserFullName?.value, filterUserStatus));
  };

  const resetFilterState = () => {
    setFilterUserFullName({ value: 0, label: "All User" });
    setFilterUserStatus(E_AUTH_STATUS.INIT);
  };
  //

  useEffect(() => {
    dispatch(loadUserList());
  }, []);
  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="2" xs="12" className="d-flex justify-content-end">
            <Button onClick={() => openNewUserModal()}>
              <FaPlus /> Create New User
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterUser
          userFullName={filterUserFullName}
          userStatus={filterUserStatus}
          handleUserFullNameFilterChange={handleUserFullNameFilterChange}
          handleUserStatusFilterChange={handleUserStatusFilterChange}
          resetFilter={resetFilter}
          applyFilter={applyFilter}
        />
        <br />
        <Row>
          <Col>{renderTableUser()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setUserCurrentPage(1));
                    dispatch(
                      loadUserList(filterUserFullName?.value, filterUserStatus)
                    );
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setUserCurrentPage(currentPage - 1));
                    dispatch(
                      loadUserList(filterUserFullName?.value, filterUserStatus)
                    );
                  }}
                />
                <Pagination.Item active>{currentPage}</Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setUserCurrentPage(currentPage + 1));
                    dispatch(
                      loadUserList(filterUserFullName?.value, filterUserStatus)
                    );
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setUserCurrentPage(totalPage));
                    dispatch(
                      loadUserList(filterUserFullName?.value, filterUserStatus)
                    );
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
        <ModalAddNewUser
          isShowing={showAddUserModal}
          onModalAddUserClose={onModalAddUserClose}
        />
        {/* Modal Confirmation Delete */}
        <Modal
          onHide={() => {
            // setNewNameType("");
            setDeleteUserModalShowing(false);
          }}
          show={deleteUserModalShowing}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete {nameToBeDeleted}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this User?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setDeleteUserModalShowing(false);
              }}
              variant="outline-primary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="danger"
              onClick={(e) => {
                e.preventDefault();
                deactivateUser(idToBeDeleted);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};

export default compose(withShop())(Users);

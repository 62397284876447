import { AppStateType } from "../reducers";

export const shopStatusLoadingSelector = (state: AppStateType) => {
  return state.shop.statusLoading;
};

export const shopListSelector = (state: AppStateType) => {
  return state.shop.shops;
};

export const shopCurrentPageSelector = (state: AppStateType) => {
  return state.shop.currentPage;
};

export const shopTotalPageSelector = (state: AppStateType) => {
  return state.shop.totalPage;
};

export const branchListSelector = (state: AppStateType) => {
  return state.shop.branches;
};

export const shopSelectionSelector = (state: AppStateType) => {
  return state.shop.shopSelect;
};

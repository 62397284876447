import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { E_PROMO_TYPE } from "../../constant";
import { BrandSelectData } from "../../interfaces/brand";
import { CategorySelectData } from "../../interfaces/categories";
import { ColourSelectData } from "../../interfaces/colour";
import { ComissionCategorySelectData } from "../../interfaces/comissioncategory";
import BrandService from "../../services/Brand";
import CategoryService from "../../services/Category";
import ColourService from "../../services/Colour";
import ComissionCategoryService from "../../services/ComissionCategory";
import ProductService from "../../services/Products";
import checkNumericInput from "../../utils/checkNumericInput";

interface ModalAddNewProductProps {
  isShowing: boolean;
  onModalAddProductClose: () => void;
}
const ModalAddNewProduct: FC<ModalAddNewProductProps> = (props) => {
  const { isShowing, onModalAddProductClose } = props;
  const [brandSelection, setBrandSelection] = useState<BrandSelectData[]>([]);
  const [itemCategories, setItemCategories] = useState<CategorySelectData[]>(
    []
  );
  const [itemColours, setItemColours] = useState<ColourSelectData[]>([]);
  const [comissionCategories, setComissionCategories] = useState<
    ComissionCategorySelectData[]
  >([]);
  const [newProductPromoType, setNewProductPromoType] = useState<E_PROMO_TYPE>(
    E_PROMO_TYPE.ALL
  );
  const [newProductName, setNewProductName] = useState<string>("");
  const [newProductBrand, setNewProductBrand] = useState<number>(0);
  const [newProductCategory, setNewProductCategory] = useState<
    SingleValue<CategorySelectData>
  >({
    value: 0,
    label: "Select Category",
  });
  const [newProductColour, setNewProductColour] = useState<
    SingleValue<ColourSelectData>
  >({
    value: 0,
    label: "Select Colour",
  });
  const [newComissionCategory, setNewComissionCategory] = useState<
    SingleValue<ComissionCategorySelectData>
  >({
    value: 0,
    label: "Select Comission Category",
  });
  const [newProductArticleNumber, setNewProductArticleNumber] =
    useState<string>("");
  const [newProductPrice, setNewProductPrice] = useState<number>(0);

  const createNewProduct = () => {
    if (newProductName === "") {
      alert("Please fill the product name field");
      return false;
    }
    if (newProductBrand === 0) {
      alert("Please Fill the product brand field");
      return false;
    }
    if (newProductColour?.value === 0) {
      alert("Please fill the product colour field");
      return false;
    }
    if (newProductArticleNumber === "") {
      alert("Please fill the product article number field");
      return false;
    }

    if (newProductCategory?.value === 0) {
      alert("Please fill the product category field");
      return false;
    }

    if (newProductPrice === 0) {
      alert("Please fill the product price field");
      return false;
    }

    if (newComissionCategory?.value === 0) {
      alert("Please fill the comission category field");
      return false;
    }

    const bodyForm = new FormData();
    bodyForm.append("productName", newProductName);
    if (newProductColour)
      bodyForm.append("colour", newProductColour.value.toString());
    bodyForm.append("articleNo", newProductArticleNumber);
    bodyForm.append("brandId", newProductBrand.toString());
    bodyForm.append("promoType", newProductPromoType.toString());
    if (newProductCategory)
      bodyForm.append("category", newProductCategory?.value.toString());
    if (newComissionCategory)
      bodyForm.append(
        "comissionCategory",
        newComissionCategory?.value.toString()
      );
    bodyForm.append("price", newProductPrice.toString());
    ProductService.createNewProduct(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Sucessfully create new product");
          window.location.reload();
        }
      })
      .catch((e: any) => {
        alert(e);
      });
  };

  const revertNewState = () => {
    setNewProductName("");
    setNewProductColour({
      value: 0,
      label: "Select Colour",
    });
    setNewProductArticleNumber("");
    setNewProductCategory({
      value: 0,
      label: "Select Category",
    });
    setNewComissionCategory({
      value: 0,
      label: "Select Comission Category",
    });
    setNewProductPrice(0);
  };

  const changeNewProductName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewProductName(value);
  };

  const changeNewProductColour = (e: SingleValue<ColourSelectData>) => {
    if (e) {
      setNewProductColour(e);
    }
  };

  const changeNewProductArticleNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewProductArticleNumber(value);
  };

  const changeNewProductPromoType = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewProductPromoType(Number(value) as E_PROMO_TYPE);
  };

  const changeNewProductCategory = (e: SingleValue<CategorySelectData>) => {
    if (e) {
      setNewProductCategory(e);
    }
  };

  const changeNewComissionCategory = (
    e: SingleValue<ComissionCategorySelectData>
  ) => {
    if (e) {
      setNewComissionCategory(e);
    }
  };

  const changeNewProductPrice = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewProductPrice(Number(newValue));
  };

  const changeNewProductBrand = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewProductBrand(Number(value));
  };

  const renderBrandSelection = () => {
    return (
      brandSelection.length > 0 &&
      brandSelection.map((brand) => {
        return (
          <option value={brand.value} key={brand.value}>
            {brand.label}
          </option>
        );
      })
    );
  };

  const getSelection = () => {
    BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandSelection: BrandSelectData[] = res.data.map((brand) => ({
          value: brand.id,
          label: brand.name,
        }));
        setBrandSelection(brandSelection);
      }
    });
    ComissionCategoryService.getComissionCategorySelection().then((res) => {
      if (res.data) {
        const comissionCategorySelection: ComissionCategorySelectData[] =
          res.data.map((comissionCategory) => ({
            value: comissionCategory.id,
            label: comissionCategory.comissionCategoryName,
          }));
        setComissionCategories(comissionCategorySelection);
      }
    });
  };

  useEffect(() => {
    setNewProductCategory({
      value: 0,
      label: "Select Category",
    });
    setNewProductColour({
      value: 0,
      label: "Select Colour",
    });
    CategoryService.getCategorySelect(newProductBrand).then((res) => {
      if (res.data) {
        const categorySelection: CategorySelectData[] = res.data.map((cat) => ({
          value: cat.id,
          label: cat.categoryName,
        }));
        setItemCategories(categorySelection);
      }
    });
    ColourService.getColourSelect(newProductBrand).then((res) => {
      if (res.data) {
        const colourSelection: ColourSelectData[] = res.data.map((col) => ({
          value: col.id,
          label: col.colourName,
        }));
        setItemColours(colourSelection);
      }
    });
  }, [newProductBrand]);

  useEffect(() => {
    getSelection();
  }, []);

  return (
    <>
      <Form onSubmit={createNewProduct}>
        <Modal
          onHide={() => {
            revertNewState();
            onModalAddProductClose();
          }}
          show={isShowing}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add New Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Brand</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={newProductBrand}
              onChange={(e) => changeNewProductBrand(e)}
            >
              <option value={0}>All</option>
              {renderBrandSelection()}
            </Form.Select>
            <Form.Label>Category</Form.Label>
            <Select
              options={itemCategories}
              value={newProductCategory}
              onChange={(e) => changeNewProductCategory(e)}
              isDisabled={newProductBrand === 0}
            />
            <Form.Label>Nama Product</Form.Label>
            <Form.Control
              type="text"
              placeholder="Product Name"
              value={newProductName}
              onChange={(e) => changeNewProductName(e)}
            />
            <Form.Label>Warna Product</Form.Label>
            <Select
              options={itemColours}
              value={newProductColour}
              onChange={(e) => changeNewProductColour(e)}
              isDisabled={newProductBrand === 0}
            />
            {/* <Form.Control
              type="text"
              placeholder="Product Colour"
              value={newProductColour}
              onChange={(e) => changeNewProductColour(e)}
            /> */}
            <Form.Label>Article Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Article Number"
              value={newProductArticleNumber}
              onChange={(e) => changeNewProductArticleNumber(e)}
            />
            <Form.Label>Promo Type</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={newProductPromoType}
              onChange={(e) => changeNewProductPromoType(e)}
            >
              <option value={E_PROMO_TYPE.ALL}>Select Promo Type</option>
              <option value={E_PROMO_TYPE.PROMO_TOKO}>Promo Toko</option>
              <option value={E_PROMO_TYPE.PROMO_ONLINE}>Promo Online</option>
              <option value={E_PROMO_TYPE.NON_PROMO}>Non Promo </option>
            </Form.Select>
            <Form.Label>Comission Category</Form.Label>
            <Select
              options={comissionCategories}
              value={newComissionCategory}
              onChange={(e) => changeNewComissionCategory(e)}
            />
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Product Price"
              value={newProductPrice}
              onChange={(e) => changeNewProductPrice(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                revertNewState();
                onModalAddProductClose();
              }}
              variant="outline-primary"
            >
              Close
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                createNewProduct();
              }}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default ModalAddNewProduct;

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import { CategoryData, GetCategoryResponse } from "../../interfaces/categories";
import CategoryService from "../../services/Category";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { CategoryType } from "../types/category";

export const setCategoryList = (categories: CategoryData[]) => {
  return {
    type: CategoryType.CATEGORY_SET_LIST,
    payload: categories,
  };
};

export const setCategoryStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: CategoryType.CATEGORY_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setCategoryCurrentPage = (currentPage: number) => {
  return {
    type: CategoryType.CATEGORY_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setCategoryTotalPage = (totalPage: number) => {
  return {
    type: CategoryType.CATEGORY_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const loadCategoryList = (
  filterBrandId?: number,
  filterCategoryId?: number
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setCategoryStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        category: { currentPage },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      if (filterBrandId) {
        params += `&brandId=${filterBrandId}`;
      }
      if (filterCategoryId) {
        params += `&categoryId=${filterCategoryId}`;
      }
      const res: GetCategoryResponse = await CategoryService.getCategory(
        params
      );
      if (res.data) {
        dispatch(setCategoryList(res.data));
        dispatch(setCategoryTotalPage(res.total_page));
        dispatch(setCategoryStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setCategoryStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setCategoryStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

import { E_AUTH_STATUS } from "../../constant";
import { AuthData } from "../../interfaces/auth";

export enum AuthType {
  AUTH_SET_DATA = "AUTH_SET_DATA",
}

export interface AuthSetData {
  type: AuthType.AUTH_SET_DATA;
  payload: AuthData;
}

export type AuthAction = AuthSetData;

import { AppStateType } from "../reducers";

export const productListSelector = (state: AppStateType) => {
  return state.product.productList;
};

export const productTotalPageSelector = (state: AppStateType) => {
  return state.product.totalPage;
};

export const productCurrentPageSelector = (state: AppStateType) => {
  return state.product.currentPage;
};

export const productStatusLoadingSelector = (state: AppStateType) => {
  return state.product.statusLoading;
};

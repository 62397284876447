import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { E_AUTH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { UserData } from "../../interfaces/user";
import { loadUserList } from "../../redux/actions/user";
import UserService from "../../services/User";

interface FilterUserProps {
  userFullName: DefaultSelection;
  userStatus: E_AUTH_STATUS;
  handleUserFullNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  handleUserStatusFilterChange: (e: ChangeEvent) => void;
  resetFilter: () => void;
  applyFilter: () => void;
}

const FilterUser: FC<FilterUserProps> = (props) => {
  const {
    userFullName,
    userStatus,
    handleUserFullNameFilterChange,
    handleUserStatusFilterChange,
    resetFilter,
    applyFilter,
  } = props;
  const [nameList, setNameList] = useState<DefaultSelection[]>([]);

  const getUserList = async () => {
    await UserService.getUserList().then((res) => {
      if (res.data) {
        const nameListTemp: DefaultSelection[] = res.data.map((user) => {
          return {
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          };
        });
        setNameList(nameListTemp);
      }
    });
  };
  useEffect(() => {
    getUserList();
  }, []);

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama User</Form.Label>
              <Select
                options={nameList}
                value={userFullName}
                onChange={handleUserFullNameFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={userStatus}
                onChange={handleUserStatusFilterChange}
              >
                <option value={E_AUTH_STATUS.INIT}>All</option>
                <option value={E_AUTH_STATUS.MANAGER}>Manager</option>
                <option value={E_AUTH_STATUS.SUPERVISOR}>Supervisor</option>
                <option value={E_AUTH_STATUS.AREA_SUPERVISOR}>
                  Area Supervisor
                </option>
                <option value={E_AUTH_STATUS.SALES}>Sales</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col
            md={{ offset: 4, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterUser;

import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadShopList, loadShopSelect } from "../../redux/actions/shop";

type HOCFuncType = (WrappedComponent: FC<{}>) => FC<{}>;

export default (): HOCFuncType =>
  (WrappedComponent: FC): FC =>
  (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadShopSelect());
    }, []);

    return <WrappedComponent {...props} />;
  };

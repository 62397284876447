import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  InputGroup,
} from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import Select, { components, SingleValue } from "react-select";
import { compose } from "redux";
import { NEW_USER_STATUS, E_GENDER, BASE_URL } from "../../../constant";
import { ShopSelectData } from "../../../interfaces/shop";
import { shopSelectionSelector } from "../../../redux/selectors/shop";
import UserService from "../../../services/User";
import "./index.scss";
import {
  userAreaSupervisorListSelector,
  userManagerListSelector,
  userSupervisorListSelector,
} from "../../../redux/selectors/user";
import checkNumericInput from "../../../utils/checkNumericInput";
import {
  setUserAreaSupervisorList,
  setUserSupervisorList,
  setUserManagerList,
} from "../../../redux/actions/user";
import RepetitiveComissionUserForm from "../../RepetitiveComissionUserForm";

const maximumDOBObject: Date = new Date();
const realMaxDOB: Date = new Date(
  maximumDOBObject.getFullYear() - 17,
  maximumDOBObject.getMonth(),
  maximumDOBObject.getDay()
);

interface UserDetailDetailInterface {
  userId: string;
}

const UserDetailDetail: FC<UserDetailDetailInterface> = (props) => {
  const { userId } = props;
  const dispatch = useDispatch();
  const ktpImageRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const shopSelection = useSelector(shopSelectionSelector);
  const managerList = useSelector(userManagerListSelector);
  const areaSupervisorList = useSelector(userAreaSupervisorListSelector);
  const supervisorList = useSelector(userSupervisorListSelector);

  // View Purpose
  const [userFirstName, setUserFirstName] = useState<string>("");
  const [userLastName, setUserLastName] = useState<string>("");
  const [userUsername, setUserUsername] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userStatus, setUserStatus] = useState<NEW_USER_STATUS>(
    NEW_USER_STATUS.SALES
  );
  const [userManageStoreId, setUserManageStoreId] = useState<
    SingleValue<ShopSelectData>
  >({
    value: 0,
    label: "Select Toko",
  });
  const [userGender, setUserGender] = useState<E_GENDER>(E_GENDER.INIT);
  const [userAreaSupervisorId, setUserAreaSupervisorId] = useState<number>(0);
  const [userSupervisorId, setUserSupervisorId] = useState<number>(0);
  const [userManagerId, setUserManagerId] = useState<number>(0);
  const [userAddress, setUserAddress] = useState<string>("");
  const [userDOB, setUserDOB] = useState(realMaxDOB);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const [userBankName, setUserBankName] = useState<string>("");
  const [userBankAccountNumber, setUserBankAccountNumber] =
    useState<string>("");
  const [userNPWPNumber, setUserNPWPNumber] = useState<string>("");
  const [userKTPLink, setUserKTPLink] = useState<string>("");

  // Edit Purpose
  const [editUserFirstName, setEditUserFirstName] = useState<string>("");
  const [editUserLastName, setEditUserLastName] = useState<string>("");
  const [editUserEmail, setEditUserEmail] = useState<string>("");
  const [editUserStatus, setEditUserStatus] = useState<NEW_USER_STATUS>(
    NEW_USER_STATUS.SALES
  );
  const [editUserManageStoreId, setEditUserManageStoreId] = useState<
    SingleValue<ShopSelectData>
  >({
    value: 0,
    label: "Select Toko",
  });
  const [editUserAreaSupervisorId, setEditUserAreaSupervisorId] =
    useState<number>(0);
  const [editUserSupervisorId, setEditUserSupervisorId] = useState<number>(0);
  const [editUserManagerId, setEditUserManagerId] = useState<number>(0);
  const [editUserAddress, setEditUserAddress] = useState<string>("");
  const [editUserPhoneNumber, setEditUserPhoneNumber] = useState<string>("");
  const [editUserBankName, setEditUserBankName] = useState<string>("");
  const [editUserBankAccountNumber, setEditUserBankAccountNumber] =
    useState<string>("");
  const [editUserNPWPNumber, setEditUserNPWPNumber] = useState<string>("");
  const [editUserKTPLink, setEditUserKTPLink] = useState<string>("");

  const changeFirstName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserFirstName(value);
  };

  const changeLastName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserLastName(value);
  };

  const changeEmail = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserEmail(value);
  };

  const changeUserPhoneNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditUserPhoneNumber(newValue);
  };

  const changeStatus = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserStatus(Number(value) as NEW_USER_STATUS);
  };

  const changeAreaSupervisorId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserAreaSupervisorId(Number(value));
  };

  const changeSupervisorId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserSupervisorId(Number(value));
  };

  const changeManagerId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserManagerId(Number(value));
  };

  const changeUserManageStoreId = (e: SingleValue<ShopSelectData>) => {
    if (e) {
      setEditUserManageStoreId(e);
    }
  };

  const changeAddress = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserAddress(value);
  };

  const changeBankName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditUserBankName(value);
  };

  const changeBankNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditUserBankAccountNumber(newValue);
  };

  const changeNPWPNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditUserNPWPNumber(newValue);
  };

  const openFileExplorer = () => {
    if (isEditing) {
      (ktpImageRef as any).current.click();
    }
  };

  const changeIDCard = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const imageURL = (e as any).target.result;
      if (imageURL) {
        setEditUserKTPLink(imageURL);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const printPage = () => {
    window.print();
  };

  const revertEditState = () => {
    setEditUserFirstName(userFirstName);
    setEditUserLastName(userLastName);
    setEditUserEmail(userEmail);
    setEditUserStatus(userStatus);
    setEditUserAddress(userAddress);
    setEditUserBankName(userBankName);
    setEditUserAreaSupervisorId(userAreaSupervisorId);
    setEditUserSupervisorId(userSupervisorId);
    setEditUserManagerId(userManagerId);
    setEditUserManageStoreId(userManageStoreId);
    setEditUserBankAccountNumber(userBankAccountNumber);
    setEditUserNPWPNumber(userNPWPNumber);
    setEditUserKTPLink(userKTPLink);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    revertEditState();
  };

  const saveEditedData = () => {
    if (editUserFirstName === "") {
      alert("Please fill the first name field");
      return false;
    }

    if (editUserLastName === "") {
      alert("Please fill the last name field");
      return false;
    }

    if (editUserEmail === "") {
      alert("Please fill the email field");
      return false;
    }

    if (editUserPhoneNumber === "") {
      alert("Please fill the phone number field");
      return false;
    }
    if (
      editUserStatus === NEW_USER_STATUS.SALES &&
      editUserAreaSupervisorId === 0
    ) {
      alert("Please select area supervisor name");
      return false;
    }
    // return false;
    if (
      (editUserStatus === NEW_USER_STATUS.SALES &&
        editUserSupervisorId === 0) ||
      (editUserStatus === NEW_USER_STATUS.AREA_SUPERVISOR &&
        editUserSupervisorId === 0)
    ) {
      alert("Please select supervisor name");
      return false;
    }

    if (
      editUserStatus === NEW_USER_STATUS.SALES &&
      (!editUserManageStoreId || editUserManageStoreId.value === 0)
    ) {
      alert("Please select store name");
      return false;
    }

    if (
      editUserStatus === NEW_USER_STATUS.SUPERVISOR &&
      editUserManagerId === 0
    ) {
      alert("Please select manager name");
      return false;
    }

    if (editUserAddress === "") {
      alert("Please fill the address field");
      return false;
    }

    if (editUserBankName === "") {
      alert("Please fill the bank name field");
      return false;
    }

    if (editUserBankAccountNumber === "") {
      alert("Please fill the bank account number field");
      return false;
    }

    if (editUserKTPLink === "") {
      alert("Please select ktp image");
      return false;
    }
    const bodyForm = new FormData();
    bodyForm.append("firstName", editUserFirstName);
    bodyForm.append("lastName", editUserLastName);
    bodyForm.append("email", editUserEmail);
    bodyForm.append("phoneNumber", editUserPhoneNumber);
    bodyForm.append("status", editUserStatus.toString());

    /* For Sales */
    if (editUserStatus === NEW_USER_STATUS.SALES) {
      bodyForm.append("areaSupervisorId", editUserAreaSupervisorId.toString());
      bodyForm.append("supervisorId", editUserSupervisorId.toString());
      if (editUserManageStoreId) {
        bodyForm.append("storeId", editUserManageStoreId.value.toString());
      }
    }
    //

    /* For Area Supervisor */
    if (editUserStatus === NEW_USER_STATUS.AREA_SUPERVISOR) {
      bodyForm.append("supervisorId", editUserSupervisorId.toString());
    }
    //

    /* For Supervisor */
    if (editUserStatus === NEW_USER_STATUS.SUPERVISOR) {
      bodyForm.append("managerId", editUserManagerId.toString());
    }
    //

    bodyForm.append("alamat", editUserAddress);
    bodyForm.append("bankName", editUserBankName);
    bodyForm.append("bankNumber", editUserBankAccountNumber);
    bodyForm.append("npwpNumber", editUserNPWPNumber);
    if (
      ktpImageRef.current &&
      ktpImageRef.current.files &&
      userKTPLink !== editUserKTPLink
    ) {
      bodyForm.append("ktpLink", ktpImageRef.current.files[0]);
    }
    UserService.editUser(Number(userId) || 0, bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Sucessfully edit the user");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  // const renderTargetComissionUser = () => {
  //   let temp: JSX.Element | JSX.Element[] = [];
  //   if (isEditing) {
  //     for (let id = 0; id < editTargetComission.length; id++) {
  //       temp.push(
  //         <RepetitiveComissionUserForm
  //           id={id}
  //           target={editTargetComission[id]}
  //           comrate={editTargetComRate[id]}
  //           removeFormId={removeFormId}
  //           changeTargetComission={changeTargetComission}
  //           changeTargetComRate={changeTargetComRate}
  //         />
  //       );
  //     }
  //   } else {
  //     for (let id = 0; id < targetComission.length; id++) {
  //       temp.push(
  //         <RepetitiveComissionUserForm
  //           id={id}
  //           target={targetComission[id]}
  //           comrate={targetComRate[id]}
  //           readonly={true}
  //           removeFormId={removeFormId}
  //           changeTargetComission={changeTargetComission}
  //           changeTargetComRate={changeTargetComRate}
  //         />
  //       );
  //     }
  //   }
  //   return temp;
  // };

  const renderKTPField = () => {
    if (isEditing) {
      return editUserKTPLink === "" ? (
        <InputGroup className="cursor-pointer" onClick={openFileExplorer}>
          <InputGroup.Text>Choose File</InputGroup.Text>
          <Form.Control
            type="text"
            placeholder="No file choosen"
            readOnly
            className="cursor-pointer"
          />
        </InputGroup>
      ) : (
        <>
          <div className="position-relative d-flex justify-content-center with-border-input">
            <Image
              src={editUserKTPLink}
              className="imageKTP"
              onClick={openFileExplorer}
            />
            <div
              className="deleteIcon cursor-pointer"
              onClick={() => setEditUserKTPLink("")}
            >
              <GrClose />
            </div>
          </div>
        </>
      );
    }
    return userKTPLink === "" ? (
      <InputGroup className="cursor-pointer">
        <InputGroup.Text>Choose File</InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="No file choosen"
          readOnly
          className="cursor-pointer"
        />
      </InputGroup>
    ) : (
      <>
        <div className="position-relative d-flex justify-content-center with-border-input">
          <Image src={userKTPLink} className="imageKTP" />
        </div>
      </>
    );
  };

  const renderManagerNameField = () => {
    if (isEditing) {
      if (editUserStatus === NEW_USER_STATUS.SALES) {
        return (
          <>
            <Form.Label>Area Supervisor</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={editUserAreaSupervisorId}
              onChange={(e) => changeAreaSupervisorId(e)}
            >
              <option value={0}>Select Area Supervisor</option>
              {areaSupervisorList.length > 0 &&
                areaSupervisorList.map((areaSupervisor) => {
                  return (
                    <option value={areaSupervisor.id} key={areaSupervisor.id}>
                      {areaSupervisor.firstName} {areaSupervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Supervisor</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={editUserSupervisorId}
              onChange={(e) => changeSupervisorId(e)}
            >
              <option value={0}>Select Supervisor</option>
              {supervisorList.length > 0 &&
                supervisorList.map((supervisor) => {
                  return (
                    <option value={supervisor.id} key={supervisor.id}>
                      {supervisor.firstName} {supervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else if (editUserStatus === NEW_USER_STATUS.AREA_SUPERVISOR) {
        return (
          <>
            <Form.Label>Supervisor</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={editUserSupervisorId}
              onChange={(e) => changeSupervisorId(e)}
            >
              <option value={0}>Select Supervisor</option>
              {supervisorList.length > 0 &&
                supervisorList.map((supervisor) => {
                  return (
                    <option value={supervisor.id} key={supervisor.id}>
                      {supervisor.firstName} {supervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else if (editUserStatus === NEW_USER_STATUS.SUPERVISOR) {
        return (
          <>
            <Form.Label>Manager</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={editUserManagerId}
              onChange={(e) => changeManagerId(e)}
            >
              <option value={0}>Select Manager Name</option>
              {managerList.length > 0 &&
                managerList.map((manager) => {
                  return (
                    <option value={manager.id} key={manager.id}>
                      {manager.firstName} {manager.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else {
        return null;
      }
    } else {
      if (userStatus === NEW_USER_STATUS.SALES) {
        return (
          <>
            <Form.Label>Area Supervisor</Form.Label>
            <Form.Select value={userAreaSupervisorId} disabled>
              <option value={0}>Select Area Supervisor</option>
              {areaSupervisorList.length > 0 &&
                areaSupervisorList.map((areaSupervisor) => {
                  return (
                    <option value={areaSupervisor.id} key={areaSupervisor.id}>
                      {areaSupervisor.firstName} {areaSupervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Supervisor</Form.Label>
            <Form.Select value={userSupervisorId} disabled>
              <option value={0}>Select Supervisor</option>
              {supervisorList.length > 0 &&
                supervisorList.map((supervisor) => {
                  return (
                    <option value={supervisor.id} key={supervisor.id}>
                      {supervisor.firstName} {supervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else if (userStatus === NEW_USER_STATUS.AREA_SUPERVISOR) {
        return (
          <>
            <Form.Label>Supervisor</Form.Label>
            <Form.Select value={userSupervisorId} disabled>
              <option value={0}>Select Supervisor</option>
              {supervisorList.length > 0 &&
                supervisorList.map((supervisor) => {
                  return (
                    <option value={supervisor.id} key={supervisor.id}>
                      {supervisor.firstName} {supervisor.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else if (userStatus === NEW_USER_STATUS.SUPERVISOR) {
        return (
          <>
            <Form.Label>Manager</Form.Label>
            <Form.Select value={userManagerId} disabled>
              <option value={0}>Select Manager Name</option>
              {managerList.length > 0 &&
                managerList.map((manager) => {
                  return (
                    <option value={manager.id} key={manager.id}>
                      {manager.firstName} {manager.lastName}
                    </option>
                  );
                })}
            </Form.Select>
          </>
        );
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    if (shopSelection.length > 0) {
      UserService.getUserDetail(Number(userId) || 0).then((res) => {
        if (res.data) {
          const {
            firstName,
            lastName,
            status,
            username,
            email,
            managerId,
            gender,
            alamat,
            dob,
            phoneNumber,
            bankName,
            bankNumber,
            ktpLink,
            npwpNumber,
            storeId,
            areaSupervisorId,
            supervisorId,
          } = res.data;
          const shopSelectionIdTemp = shopSelection.map((shop) => shop.value);
          const indexShop = shopSelectionIdTemp.indexOf(storeId);
          // View
          setUserFirstName(firstName);
          setUserLastName(lastName);
          setUserUsername(username);
          setUserStatus(Number(status) as NEW_USER_STATUS);
          setUserManageStoreId(shopSelection[indexShop]);
          setUserAreaSupervisorId(Number(areaSupervisorId) || 0);
          setUserSupervisorId(Number(supervisorId) || 0);
          setUserManagerId(Number(managerId) || 0);
          setUserEmail(email);
          setUserPhoneNumber(phoneNumber);
          setUserGender(gender);
          setUserAddress(alamat);
          setUserDOB(new Date(dob));
          setUserBankName(bankName);
          setUserBankAccountNumber(bankNumber);
          if (ktpLink) {
            setUserKTPLink(`${BASE_URL}${ktpLink}`);
          }
          setUserNPWPNumber(npwpNumber);
          //

          // Edit
          setEditUserFirstName(firstName);
          setEditUserLastName(lastName);
          setEditUserStatus(Number(status) as NEW_USER_STATUS);
          setEditUserManageStoreId(shopSelection[indexShop]);
          setEditUserAreaSupervisorId(Number(areaSupervisorId) || 0);
          setEditUserSupervisorId(Number(supervisorId) || 0);
          setEditUserManagerId(Number(managerId) || 0);
          setEditUserEmail(email);
          setEditUserPhoneNumber(phoneNumber);
          setEditUserAddress(alamat);
          setEditUserBankName(bankName);
          setEditUserBankAccountNumber(bankNumber);
          setEditUserNPWPNumber(npwpNumber);
          setEditUserKTPLink(`${BASE_URL}${ktpLink}`);
          //
        }
      });
    }
  }, [userId, shopSelection]);

  useEffect(() => {
    if (isEditing) {
      switch (editUserStatus) {
        case NEW_USER_STATUS.SALES:
          UserService.getAreaSupervisorList(Number(userId) || 0).then((res) => {
            if (res.data) {
              dispatch(setUserAreaSupervisorList(res.data));
            }
          });
          UserService.getSupervisorList(Number(userId) || 0).then((res) => {
            if (res.data) {
              dispatch(setUserSupervisorList(res.data));
            }
          });
          break;
        case NEW_USER_STATUS.AREA_SUPERVISOR:
          UserService.getSupervisorList(Number(userId) || 0).then((res) => {
            if (res.data) {
              dispatch(setUserSupervisorList(res.data));
            }
          });
          break;
        case NEW_USER_STATUS.SUPERVISOR:
          UserService.getManagerList(Number(userId) || 0).then((res) => {
            if (res.data) {
              dispatch(setUserManagerList(res.data));
            }
          });
          break;
      }
    }
  }, [isEditing, editUserStatus]);

  return (
    <>
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            {!isEditing && (
              <>
                <Button
                  onClick={() => setIsEditing(true)}
                  className="notPrinted"
                >
                  Edit User Data
                </Button>
                &nbsp;
                <Button onClick={printPage} className="notPrinted">
                  Print Data
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={() => cancelEdit()} variant="outline-primary">
                  Cancel
                </Button>
                &nbsp;
                <Button onClick={saveEditedData}>Save</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Form onSubmit={saveEditedData}>
          <Row>
            <Col md="6" xs="6">
              <Form.Label>First Name</Form.Label>
              {!isEditing ? (
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={userFirstName}
                  readOnly
                />
              ) : (
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  value={editUserFirstName}
                  onChange={(e) => changeFirstName(e)}
                />
              )}
            </Col>
            <Col md="6" xs="6">
              <Form.Label>Last Name</Form.Label>
              {!isEditing ? (
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={userLastName}
                  readOnly
                />
              ) : (
                <Form.Control
                  type="text"
                  placeholder="Last Name"
                  value={editUserLastName}
                  onChange={(e) => changeLastName(e)}
                />
              )}
            </Col>
          </Row>
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="Username"
            value={userUsername}
            disabled
          />
          <Form.Label>Email</Form.Label>
          {!isEditing ? (
            <Form.Control
              type="email"
              placeholder="Email"
              value={userEmail}
              readOnly
            />
          ) : (
            <Form.Control
              type="email"
              placeholder="Email"
              value={editUserEmail}
              onChange={(e) => changeEmail(e)}
            />
          )}
          <Form.Label>Phone Number</Form.Label>
          {!isEditing ? (
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                placeholder="Phone Number"
                value={userPhoneNumber}
                readOnly
              />
            </InputGroup>
          ) : (
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                placeholder="Phone Number"
                value={editUserPhoneNumber}
                onChange={(e) => changeUserPhoneNumber(e)}
              />
            </InputGroup>
          )}
          <Form.Label>Status</Form.Label>
          {!isEditing ? (
            <Form.Select value={userStatus} disabled>
              <option value={NEW_USER_STATUS.SALES}>Sales</option>
              <option value={NEW_USER_STATUS.SUPERVISOR}>Supervisor</option>
              <option value={NEW_USER_STATUS.AREA_SUPERVISOR}>
                Area Supervisor
              </option>
              <option value={NEW_USER_STATUS.MANAGER}>Manager</option>
            </Form.Select>
          ) : (
            <Form.Select
              value={editUserStatus}
              onChange={(e) => changeStatus(e)}
            >
              <option value={NEW_USER_STATUS.SALES}>Sales</option>
              <option value={NEW_USER_STATUS.SUPERVISOR}>Supervisor</option>
              <option value={NEW_USER_STATUS.AREA_SUPERVISOR}>
                Area Supervisor
              </option>
              <option value={NEW_USER_STATUS.MANAGER}>Manager</option>
            </Form.Select>
          )}
          {renderManagerNameField()}
          {((userStatus === NEW_USER_STATUS.SALES && !isEditing) ||
            (editUserStatus === NEW_USER_STATUS.SALES && isEditing)) && (
            <>
              <Form.Label>Toko</Form.Label>
              {!isEditing ? (
                <Select
                  options={shopSelection}
                  isDisabled={true}
                  value={userManageStoreId}
                />
              ) : (
                <Select
                  options={shopSelection}
                  value={editUserManageStoreId}
                  onChange={(e) => changeUserManageStoreId(e)}
                />
              )}
            </>
          )}

          <Form.Label>Gender</Form.Label>
          <Form.Select
            aria-label="Default select example"
            value={userGender}
            disabled
          >
            <option value={E_GENDER.INIT}>Select Gender</option>
            <option value={E_GENDER.MALE}>Male</option>
            <option value={E_GENDER.FEMALE}>Female</option>
          </Form.Select>
          <Form.Label>Alamat</Form.Label>
          {!isEditing ? (
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder="Address"
              value={userAddress}
              readOnly
            />
          ) : (
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder="Address"
              value={editUserAddress}
              onChange={(e) => changeAddress(e)}
            />
          )}

          <Form.Label>Date of Birth</Form.Label>
          <DatePicker
            selected={userDOB}
            // readOnly
            disabled
            dateFormat="dd-MM-yyyy"
            onChange={(e: any) => e.preventDefault()}
          />
          <Row>
            <Col md="6" xs="6">
              <Form.Label>Bank Name</Form.Label>
              {!isEditing ? (
                <Form.Control
                  placeholder="Bank Name"
                  value={userBankName}
                  readOnly
                />
              ) : (
                <Form.Control
                  placeholder="Bank Name"
                  value={editUserBankName}
                  onChange={(e) => changeBankName(e)}
                />
              )}
            </Col>

            <Col md="6" xs="6">
              <Form.Label>Bank Account Number</Form.Label>
              {!isEditing ? (
                <Form.Control
                  placeholder="Bank Account Number"
                  value={userBankAccountNumber}
                  readOnly
                />
              ) : (
                <Form.Control
                  placeholder="Bank Account Number"
                  value={editUserBankAccountNumber}
                  onChange={(e) => changeBankNumber(e)}
                />
              )}
            </Col>
          </Row>
          <Form.Label>NPWP Number</Form.Label>
          {!isEditing ? (
            <Form.Control
              placeholder="NPWP Number"
              value={userNPWPNumber}
              readOnly
            />
          ) : (
            <Form.Control
              placeholder="NPWP Number"
              value={editUserNPWPNumber}
              onChange={(e) => changeNPWPNumber(e)}
            />
          )}
          <Form.Label>ID Card</Form.Label>
          {renderKTPField()}
          <input
            type="file"
            ref={ktpImageRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => changeIDCard(e)}
          />
        </Form>
      </Container>
      <br />
    </>
  );
};

export default UserDetailDetail;

import { API_URL, BASE_URL } from "../constant";
import {
  CreateColourResponse,
  DeleteColourResponse,
  GetColourResponse,
  GetColourSelectResponse,
} from "../interfaces/colour";
import { getCookie } from "../utils/cookies";

class ColourService {
  public static async getColour(params?: string): Promise<GetColourResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getColour}${params ? params : ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetColourResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getColourSelect(
    finsihing?: number
  ): Promise<GetColourSelectResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getColourSelect}${
        finsihing ? `?finsihing=${finsihing}` : ``
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetColourResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createColour(
    body: FormData
  ): Promise<CreateColourResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createColour}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: CreateColourResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
  public static async deleteColour(id: number): Promise<DeleteColourResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteColour}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DeleteColourResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getSilgranitColour(
    body: FormData
  ): Promise<GetColourResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getSilgranitColour}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: GetColourResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ColourService;

import { useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavBar from "../../component/Navbar";
import { USER_DETAIL_NAV } from "../../constant";
import UserDetailDetail from "../../component/UserDetail/Detail";
import UserDetailReport from "../../component/UserDetail/SalesReport";
import withShop from "../../component/withShop";
import withManagerList from "../../component/withManagerList";
import { compose } from "redux";

const UserDetail = () => {
  const { idUser } = useParams();
  const [currentNav, setCurrentNav] = useState<USER_DETAIL_NAV>(
    USER_DETAIL_NAV.DETAIL
  );
  return (
    <>
      <NavBar customBrand="Info User" />
      <br />
      <Container fluid>
        <Nav variant="tabs" className="notPrinted">
          <Nav.Item className="nav-item-report">
            <Nav.Link
              active={currentNav === USER_DETAIL_NAV.DETAIL}
              onClick={() => setCurrentNav(USER_DETAIL_NAV.DETAIL)}
            >
              Detail
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="nav-item-report">
            <Nav.Link
              active={currentNav === USER_DETAIL_NAV.REPORT}
              onClick={() => setCurrentNav(USER_DETAIL_NAV.REPORT)}
            >
              Sales Report
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <br />
        {currentNav === USER_DETAIL_NAV.DETAIL ? (
          <UserDetailDetail userId={idUser || ""} />
        ) : (
          <UserDetailReport userId={idUser || ""} />
        )}
      </Container>
      <style>
        {`
          @media print {
            @page {
              margin: 0;
            }
            .notPrinted {
              display: none;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
        `}
      </style>
    </>
  );
};

export default compose(withShop(), withManagerList())(UserDetail);

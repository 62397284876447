import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NEW_USER_STATUS } from "../../constant";
import { UserData } from "../../interfaces/user";
import { loadBranches } from "../../redux/actions/shop";
import {
  branchListSelector,
  shopListSelector,
} from "../../redux/selectors/shop";
import ShopService from "../../services/Shop";
import UserService from "../../services/User";
import checkNumericInput from "../../utils/checkNumericInput";

interface ModalDeleteShopProps {
  isShowing: boolean;
  onModalDeleteShopClose: () => void;
  idDeleted: number;
}
const ModalDeleteShop: FC<ModalDeleteShopProps> = (props) => {
  const { isShowing, onModalDeleteShopClose, idDeleted } = props;
  const branchesList = useSelector(branchListSelector);

  const [shopName, setShopName] = useState<string>("");
  const deleteShop = () => {
    ShopService.deleteShop(idDeleted).then((res) => {
      if (res.status == 1) {
        alert("Shop has been sucessfully deleted");
        window.location.reload();
      }
    });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (idDeleted !== 0) {
      ShopService.getShopDetail(idDeleted).then((res) => {
        if (res.data) {
          setShopName(res.data.storeName);
        }
      });
    }
  }, [idDeleted]);

  return (
    <>
      <Modal
        onHide={onModalDeleteShopClose}
        show={isShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Shop
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this shop?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onModalDeleteShopClose} variant="outline-primary">
            Close
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              e.preventDefault();
              deleteShop();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDeleteShop;

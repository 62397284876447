import { E_FETCH_STATUS } from "../../constant";
import { AppStateType } from "../reducers";

export const userStatusLoadingSelector = (state: AppStateType) => {
  return state.user.statusLoading;
  // return E_FETCH_STATUS.FETCHING;
};

export const userListSelector = (state: AppStateType) => {
  return state.user.users;
};

export const userCurrentPageSelector = (state: AppStateType) => {
  return state.user.currentPage;
};

export const userTotalPageSelector = (state: AppStateType) => {
  return state.user.totalPage;
};
export const userAreaSupervisorListSelector = (state: AppStateType) => {
  return state.user.areaSupervisorList;
};

export const userSupervisorListSelector = (state: AppStateType) => {
  return state.user.supervisorList;
};

export const userManagerListSelector = (state: AppStateType) => {
  return state.user.managerList;
};

import { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import NavBar from "../../component/Navbar";
import { SalesTargetUserData } from "../../interfaces/salestarget";
import SalesTargetService from "../../services/SalesTarget";
import thousandSeparator from "../../utils/thousandSeparator";
import "./index.scss";

const ListTarget = () => {
  const [salesTargetList, setSalesTargetList] = useState<SalesTargetUserData>();
  const getSalesTargetList = async () => {
    await SalesTargetService.getUserSalesTarget()
      .then((res) => {
        if (res.data) {
          setSalesTargetList(res.data);
        }
      })
      .catch((e) => alert(e));
  };
  useEffect(() => {
    getSalesTargetList();
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <h4>Informasi Target</h4>
        <br />
        {salesTargetList?.blanco && (
          <>
            <Row>
              <div className="big-box">
                <Row>
                  <Col>
                    <p className="header-target">
                      <strong>Penjualan Produk Blanco</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Target Ke</th>
                          <th>Target</th>
                          <th>Jumlah Perolehan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesTargetList.blanco.map((salesTarget, index) => {
                          return (
                            <tr>
                              <td>Target {index + 1}</td>
                              <td>
                                Rp {thousandSeparator(salesTarget.target)}
                              </td>
                              <td>
                                Rp {thousandSeparator(salesTarget.comrate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Row>
            <br />
          </>
        )}
        {salesTargetList?.cgs && (
          <>
            <Row>
              <div className="big-box">
                <Row>
                  <Col>
                    <p className="header-target">
                      <strong>Penjualan Produk CGS</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Target Ke</th>
                          <th>Target</th>
                          <th>Jumlah Perolehan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesTargetList.cgs.map((salesTarget, index) => {
                          return (
                            <tr>
                              <td>Target {index + 1}</td>
                              <td>
                                Rp {thousandSeparator(salesTarget.target)}
                              </td>
                              <td>
                                Rp {thousandSeparator(salesTarget.comrate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Row>
            <br />
          </>
        )}
        {salesTargetList?.hg && (
          <>
            <Row>
              <div className="big-box">
                <Row>
                  <Col>
                    <p className="header-target">
                      <strong>Penjualan Produk HG</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Target Ke</th>
                          <th>Target</th>
                          <th>Jumlah Perolehan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesTargetList.hg.map((salesTarget, index) => {
                          return (
                            <tr>
                              <td>Target {index + 1}</td>
                              <td>
                                Rp {thousandSeparator(salesTarget.target)}
                              </td>
                              <td>
                                Rp {thousandSeparator(salesTarget.comrate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Row>
            <br />
          </>
        )}
        {salesTargetList?.cgspackage && (
          <>
            <Row>
              <div className="big-box">
                <Row>
                  <Col>
                    <p className="header-target">
                      <strong>Penjualan CGS Package</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Target Ke</th>
                          <th>Target</th>
                          <th>Jumlah Perolehan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salesTargetList.cgspackage.map(
                          (salesTarget, index) => {
                            return (
                              <tr>
                                <td>Target {index + 1}</td>
                                <td>
                                  Rp {thousandSeparator(salesTarget.target)}
                                </td>
                                <td>
                                  Rp {thousandSeparator(salesTarget.comrate)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            </Row>
            <br />
          </>
        )}
      </Container>
    </>
  );
};

export default ListTarget;

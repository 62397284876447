import React, { FC, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  loadAreaSupervisorList,
  loadManagerList,
  loadSupervisorList,
} from "../../redux/actions/user";

type HOCFuncType = (WrappedComponent: FC<{}>) => FC<{}>;

export default (): HOCFuncType =>
  (WrappedComponent: FC): FC =>
  (props) => {
    const { idUser } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(loadAreaSupervisorList(Number(idUser) || 0));
      dispatch(loadSupervisorList(Number(idUser) || 0));
      dispatch(loadManagerList(Number(idUser) || 0));
    }, []);

    return <WrappedComponent {...props} />;
  };

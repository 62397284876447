import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Image,
  Button,
} from "react-bootstrap";
import { useParams } from "react-router";
import NavBar from "../../component/Navbar";
import { SalesReportProductData } from "../../interfaces/salesreport";
import SalesReportService from "../../services/SalesReport";
import RepetitiveDetailSalesReport from "../../component/RepetitiveDetailSalesReport";
import { BASE_URL } from "../../constant";
import Lightbox from "react-image-lightbox";
import thousandSeparator from "../../utils/thousandSeparator";

const SalesReportDetail = () => {
  const { idSalesReport } = useParams();
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");
  const [tglPenjualan, setTglPenjualan] = useState<string>("");
  const [alamatPembeli, setAlamatPembeli] = useState<string>("");
  const [nomorPembeli, setNomorPembeli] = useState<string>("");
  const [emailPembeli, setEmailPembeli] = useState<string>("");
  const [products, setProducts] = useState<SalesReportProductData[]>([]);
  const [statusSalesReport, setStatusSalesReport] = useState<number>(0);
  const [attachmentFile, setAttachmentFile] = useState<string>("");
  const [rejectionMessage, setRejectionMessage] = useState<string>("");
  const [lightBoxOpen, setLightBoxOpen] = useState<boolean>(false);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const getSalesReportDetail = async () => {
    await SalesReportService.getSalesReportDetail(Number(idSalesReport))
      .then((res) => {
        if (res.data) {
          setInvoiceNumber(res.data.invoiceNumber);
          setTglPenjualan(res.data.invoiceDate);
          setCustomerName(res.data.customerName);
          setAlamatPembeli(res.data.customerAddress);
          setNomorPembeli(res.data.customerPhone);
          setEmailPembeli(res.data.customerEmail);
          setProducts(res.data.products);
          setAttachmentFile(res.data.attachmentFile);
          let tempTotal = 0;
          res.data.products.forEach((prod) => {
            let productCount = prod.count;
            let price = prod.productPrice;
            tempTotal += price * productCount;
          });
          setStatusSalesReport(Number(res.data.status));
          setRejectionMessage(res.data.message);
          setTotalPrice(tempTotal);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  useEffect(() => {
    if (idSalesReport) {
      getSalesReportDetail();
    }
  }, [idSalesReport]);

  const renderProducts = () => {
    return products.map((product, index) => {
      return (
        <RepetitiveDetailSalesReport
          key={product.id}
          id={product.id}
          index={index}
          productName={product.productName}
          brand={product.productBrandName}
          categoryName={product.productCategory}
          price={product.productPrice}
          promoType={product.productPromoType}
          articleNo={product.productArticleNo}
          count={product.count}
        />
      );
    });
  };

  const closeLightBox = () => {
    setLightBoxOpen(false);
  };

  const renderSalesReportButton = () => {
    switch (statusSalesReport) {
      case 2:
        return (
          <>
            <Button variant="success" disabled>
              Approved by Supervisor
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <Button variant="success" disabled>
              Approved by Admin
            </Button>
          </>
        );
      case 4:
        return (
          <>
            <Button variant="danger" disabled>
              Rejected by Supervisor
            </Button>
          </>
        );
      case 5:
        return (
          <>
            <Button variant="danger" disabled>
              Rejected by Admin
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {lightBoxOpen && (
        <Lightbox
          mainSrc={`${BASE_URL}${attachmentFile}`}
          onCloseRequest={closeLightBox}
        />
      )}
      <NavBar />
      <br />
      <Container fluid>
        <br />
      </Container>
      <Container>
        <Row>
          <Col>
            <Form>
              <Row className="align-items-center">
                <Col>
                  <h2>Data Pelanggan</h2>
                </Col>
                <Col className="text-end">{renderSalesReportButton()}</Col>
              </Row>
              <Col>
                {(statusSalesReport === 4 || statusSalesReport === 5) && (
                  <>
                    <Form.Label>Alasan Penolakan</Form.Label>
                    <Form.Control
                      as="textarea"
                      readOnly
                      value={rejectionMessage}
                    />
                    <hr />
                  </>
                )}
              </Col>
              <Row></Row>

              <Form.Label>Nomor Invoice</Form.Label>
              <Form.Control type="text" readOnly value={invoiceNumber} />
              <Form.Label>Tanggal Penjualan</Form.Label>
              <Form.Control type="text" readOnly value={tglPenjualan} />
              <Form.Label>Nama Pembeli</Form.Label>
              <Form.Control type="text" readOnly value={customerName} />
              <Form.Label>Alamat Pembeli</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={alamatPembeli}
                as="textarea"
              />
              <Form.Label>No. HP Pembeli</Form.Label>
              <InputGroup>
                <InputGroup.Text>+62</InputGroup.Text>
                <Form.Control
                  placeholder="No. HP Pembeli"
                  value={nomorPembeli}
                  readOnly
                />
              </InputGroup>
              <Form.Label>Email Pembeli</Form.Label>
              <Form.Control type="text" readOnly value={emailPembeli} />

              <h2>Data Item</h2>
              {renderProducts()}
              <Form.Label>Total Harga</Form.Label>
              <Form.Control
                type="text"
                readOnly
                value={`Rp. ${thousandSeparator(totalPrice)}`}
              />
              <Form.Label>Attachment</Form.Label>
              <div className="position-relative d-flex justify-content-center with-border-input">
                <Image
                  onClick={() => setLightBoxOpen(true)}
                  src={`${BASE_URL}${attachmentFile}`}
                  className="imageInvoice"
                />
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SalesReportDetail;

import { ChangeEvent, useState, MouseEvent, useEffect } from "react";
import { Form, Row, Col, Button, Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { E_FILTER_STATUS } from "../../../interfaces/salesreport";
import {
  loadSalesReportList,
  setSalesReportFilter,
} from "../../../redux/actions/salesreport";

const FilterDashboardSales = () => {
  const dispatch = useDispatch();
  const [filterStatus, setFilterStatus] = useState<E_FILTER_STATUS>(
    E_FILTER_STATUS.ALL
  );
  const [filterSalesReportNumber, setFilterSalesReportNumber] =
    useState<string>("");

  const changeFilterSalesReportStatus = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterStatus(value as unknown as E_FILTER_STATUS);
  };

  const changeFilterSalesReportNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setFilterSalesReportNumber(value);
  };

  const resetFilter = () => {
    setFilterStatus(E_FILTER_STATUS.ALL);
    setFilterSalesReportNumber("");
    dispatch(
      setSalesReportFilter({
        filterStatus: E_FILTER_STATUS.ALL,
        filterSalesReportNumber: "",
      })
    );
    dispatch(loadSalesReportList());
  };

  const handleApplyClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(
      setSalesReportFilter({
        filterStatus,
        filterSalesReportNumber,
      })
    );
    dispatch(loadSalesReportList());
  };

  useEffect(() => {
    return resetFilter();
  }, []);

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter</Accordion.Header>
          <Accordion.Body>
            <Form autoComplete="off">
              <Row>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={filterStatus}
                      onChange={(e) => changeFilterSalesReportStatus(e)}
                    >
                      <option value={E_FILTER_STATUS.ALL}>All</option>
                      <option value={E_FILTER_STATUS.PENDING}>Pending</option>
                      <option value={E_FILTER_STATUS.APPROVED_BY_SUPERVISOR}>
                        Approved by Supervisor
                      </option>
                      <option value={E_FILTER_STATUS.APPROVED_BY_ADMIN}>
                        Approved by Admin
                      </option>
                      <option value={E_FILTER_STATUS.REJECTED_BY_SUPERVISOR}>
                        Rejected by Supervisor
                      </option>
                      <option value={E_FILTER_STATUS.REJECTED_BY_ADMIN}>
                        Rejected by Admin
                      </option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nomor Sales Report</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nomor Sales Report"
                      value={filterSalesReportNumber}
                      onChange={(e) => changeFilterSalesReportNumber(e)}
                    />
                  </Form.Group>
                </Col>

                <Col
                  md={4}
                  xs={12}
                  className="d-flex align-items-end btn-action mt-2 mt-md-0"
                >
                  <Button
                    variant="outline-primary"
                    onClick={resetFilter}
                    className="flex-grow-1"
                  >
                    Reset
                  </Button>
                  &nbsp;
                  <Button
                    className="flex-grow-1"
                    variant="primary"
                    onClick={(e) => {
                      handleApplyClick(e);
                    }}
                    type="submit"
                  >
                    Apply
                  </Button>{" "}
                </Col>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
    </>
  );
};

export default FilterDashboardSales;

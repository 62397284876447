import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavBar from "../../component/Navbar";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import checkNumericInput from "../../utils/checkNumericInput";
import ProductService from "../../services/Products";
import { BrandSelectData } from "../../interfaces/brand";
import BrandService from "../../services/Brand";
import { E_PROMO_TYPE, PROMO_TYPE } from "../../constant";
import thousandSeparator from "../../utils/thousandSeparator";
import { CategorySelectData } from "../../interfaces/categories";
import { ColourSelectData } from "../../interfaces/colour";
import { ComissionCategorySelectData } from "../../interfaces/comissioncategory";
import CategoryService from "../../services/Category";
import Select, { SingleValue } from "react-select";
import ColourService from "../../services/Colour";
import ComissionCategoryService from "../../services/ComissionCategory";

const ProductDetail: FC = () => {
  let { idProduct } = useParams();
  const dispatch = useDispatch();
  const [categorySelection, setCategorySelection] = useState<
    CategorySelectData[]
  >([]);
  const [itemColours, setItemColours] = useState<ColourSelectData[]>([]);
  const [comissionCategories, setComissionCategories] = useState<
    ComissionCategorySelectData[]
  >([]);

  // For View
  const [productBrand, setProductBrand] = useState<number>(0);
  const [productName, setProductName] = useState<string>("");
  const [productPromoType, setProductPromoType] = useState<E_PROMO_TYPE>(
    E_PROMO_TYPE.ALL
  );
  const [productColour, setProductColour] = useState<
    SingleValue<ColourSelectData>
  >({
    value: 0,
    label: "Select Colour",
  });
  const [productArticleNumber, setProductArticleNumber] = useState<string>("");
  const [productCategory, setProductCategory] = useState<
    SingleValue<CategorySelectData>
  >({
    value: 0,
    label: "Select Category",
  });
  const [productPrice, setProductPrice] = useState<string>("");
  const [productComissionCategory, setProductComissionCategory] = useState<
    SingleValue<ComissionCategorySelectData>
  >({
    value: 0,
    label: "Select Comission Category",
  });
  //

  // For Edit
  const [editProductName, setEditProductName] = useState<string>("");
  const [editProductCategory, setEditProductCategory] = useState<
    SingleValue<CategorySelectData>
  >({
    value: 0,
    label: "Select Category",
  });
  const [editProductPromoType, setEditProductPromoType] =
    useState<E_PROMO_TYPE>(E_PROMO_TYPE.ALL);
  const [editProductColour, setEditProductColour] = useState<
    SingleValue<ColourSelectData>
  >({
    value: 0,
    label: "Select Colour",
  });
  const [editProductArticleNumber, setEditProductArticleNumber] =
    useState<string>("");
  const [editProductPrice, setEditProductPrice] = useState<number>(0);
  const [editProductComissionCategory, setEditProductComissionCategory] =
    useState<SingleValue<ComissionCategorySelectData>>({
      value: 0,
      label: "Select Comission Category",
    });
  //

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const printPage = () => {
    window.print();
  };

  const revertEditState = () => {
    setEditProductName(productName);
    setEditProductColour(productColour);
    setEditProductArticleNumber(productArticleNumber);
    setEditProductCategory(productCategory);
    setEditProductPrice(Number(productPrice));
  };

  const cancelEdit = () => {
    revertEditState();
    setIsEditing(false);
  };

  const saveEditedData = () => {
    if (editProductName === "") {
      alert("Please fill the product name field");
      return false;
    }
    // if (editProductColour === "") {
    //   alert("Please fill the product colour field");
    //   return false;
    // }
    if (editProductArticleNumber === "") {
      alert("Please fill the product article number field");
      return false;
    }

    if (editProductCategory?.value === 0) {
      alert("Please fill the product category field");
      return false;
    }

    if (editProductPrice === 0) {
      alert("Please select the shop kabupaten");
      return false;
    }

    const bodyForm = new FormData();
    if (editProductCategory?.value)
      bodyForm.append("category", editProductCategory.value.toString());
    bodyForm.append("productName", editProductName);
    if (editProductColour?.value)
      bodyForm.append("colour", editProductColour.value.toString());
    bodyForm.append("articleNo", editProductArticleNumber);
    bodyForm.append("promoType", editProductPromoType.toString());
    if (editProductComissionCategory?.value)
      bodyForm.append(
        "comissionCategory",
        editProductComissionCategory.value.toString()
      );
    bodyForm.append("price", editProductPrice.toString());

    ProductService.editProduct(Number(idProduct) || 0, bodyForm).then((res) => {
      if (res.status == 1) {
        alert("Product has been sucessfully edited");
        window.location.reload();
      }
    });
  };

  const getCategoriesSelection = async () => {
    if (productBrand !== 0) {
      await CategoryService.getCategorySelect(productBrand).then((res) => {
        if (res.data) {
          const categorySelection: CategorySelectData[] = [];
          res.data.forEach((cat) => {
            categorySelection.push({
              value: cat.id,
              label: cat.categoryName,
            });
          });
          setCategorySelection(categorySelection);
        }
      });
    }
  };

  const getColourSelection = async () => {
    await ColourService.getColourSelect().then((res) => {
      if (res.data) {
        const colourSelection: ColourSelectData[] = [];
        res.data.forEach((col) => {
          colourSelection.push({
            value: col.id,
            label: col.colourName,
          });
        });
        setItemColours(colourSelection);
      }
    });
  };

  const getComissionCategorySelection = async () => {
    await ComissionCategoryService.getComissionCategorySelection().then(
      (res) => {
        if (res.data) {
          const comissionCategorySelection: ComissionCategorySelectData[] = [];
          res.data.forEach((com) => {
            comissionCategorySelection.push({
              value: com.id,
              label: com.comissionCategoryName,
            });
          });
          setComissionCategories(comissionCategorySelection);
        }
      }
    );
  };
  const getProductDetail = async () => {
    await ProductService.getProductDetail(Number(idProduct)).then((res) => {
      if (res.data) {
        const {
          productName,
          colour,
          articleNo,
          brandCategory,
          brandCategoryId,
          price,
          brandId,
          colourId,
          finishingName,
          promoType,
          comissionCategoryId,
          comissionCategory,
        } = res.data;
        // For View
        setProductBrand(brandId);
        setProductName(productName);
        setProductColour({
          value: colourId,
          label: `${finishingName} - ${colour}`,
        });
        setProductArticleNumber(articleNo);
        setProductCategory({
          value: brandCategoryId,
          label: brandCategory,
        });
        setProductPrice(price.toString());
        setProductPromoType(promoType);
        setProductComissionCategory({
          value: comissionCategoryId,
          label: comissionCategory,
        });
        //

        // For Edit
        setEditProductName(productName);
        setEditProductColour({
          value: colourId,
          label: `${finishingName} - ${colour}`,
        });
        setEditProductArticleNumber(articleNo);
        setEditProductCategory({
          value: brandCategoryId,
          label: brandCategory,
        });
        setEditProductPrice(price);
        setEditProductPromoType(promoType);
        setEditProductComissionCategory({
          value: comissionCategoryId,
          label: comissionCategory,
        });
        //
      }
    });
  };

  useEffect(() => {
    getProductDetail();
  }, [idProduct]);

  useEffect(() => {
    getCategoriesSelection();
    getColourSelection();
    getComissionCategorySelection();
  }, [productBrand]);

  const changeProductName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditProductName(value);
  };

  const changeProductColour = (e: SingleValue<ColourSelectData>) => {
    if (e) {
      setEditProductColour(e);
    }
  };

  const changeProductPromoType = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditProductPromoType(value as unknown as E_PROMO_TYPE);
  };

  const changeProductArticleNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditProductArticleNumber(value);
  };

  const changeProductCategory = (e: SingleValue<CategorySelectData>) => {
    if (e) {
      setEditProductCategory(e);
    }
  };

  const changeProductComissionCategory = (
    e: SingleValue<ComissionCategorySelectData>
  ) => {
    if (e) {
      setEditProductComissionCategory(e);
    }
  };

  const changeProductPrice = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditProductPrice(Number(newValue));
  };

  return (
    <>
      <NavBar customBrand={"Informasi Product"} />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            {!isEditing && (
              <>
                <Button
                  onClick={() => setIsEditing(true)}
                  className="notPrinted"
                >
                  Edit Product Data
                </Button>
                &nbsp;
                <Button onClick={printPage} className="notPrinted">
                  Print Data
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={() => cancelEdit()} variant="outline-primary">
                  Cancel
                </Button>
                &nbsp;
                <Button onClick={saveEditedData}>Save</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Form>
            <Form.Label>Brand</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={productBrand}
              disabled
            >
              <option value={1}>Blanco</option>
              <option value={2}>CGS</option>
              <option value={3}>Hansgrohe</option>
            </Form.Select>
            <Form.Label>Category</Form.Label>
            {!isEditing ? (
              <Select
                options={categorySelection}
                value={productCategory}
                onChange={changeProductCategory}
                isDisabled
              />
            ) : (
              <Select
                options={categorySelection}
                value={editProductCategory}
                onChange={changeProductCategory}
              />
            )}
            <Form.Label>Nama Product</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                placeholder="Product Name"
                value={productName}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Product Name"
                value={editProductName}
                onChange={(e) => changeProductName(e)}
              />
            )}
            <Form.Label>Warna Product</Form.Label>
            {!isEditing ? (
              <Select options={itemColours} value={productColour} isDisabled />
            ) : (
              <Select
                options={itemColours}
                value={editProductColour}
                onChange={changeProductColour}
              />
            )}
            <Form.Label>Article Number</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                placeholder="Article Number"
                value={productArticleNumber}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Article Number"
                value={editProductArticleNumber}
                onChange={(e) => changeProductArticleNumber(e)}
              />
            )}
            <Form.Label>Promo Type</Form.Label>
            {!isEditing ? (
              <Form.Select
                aria-label="Default select example"
                value={productPromoType}
                disabled
              >
                <option value={E_PROMO_TYPE.ALL}>Select Promo Type</option>
                <option value={E_PROMO_TYPE.PROMO_TOKO}>Promo Toko</option>
                <option value={E_PROMO_TYPE.PROMO_ONLINE}>Promo Online</option>
                <option value={E_PROMO_TYPE.NON_PROMO}>Non Promo </option>
              </Form.Select>
            ) : (
              <Form.Select
                aria-label="Default select example"
                value={editProductPromoType}
                onChange={changeProductPromoType}
              >
                <option value={E_PROMO_TYPE.ALL}>Select Promo Type</option>
                <option value={E_PROMO_TYPE.PROMO_TOKO}>Promo Toko</option>
                <option value={E_PROMO_TYPE.PROMO_ONLINE}>Promo Online</option>
                <option value={E_PROMO_TYPE.NON_PROMO}>Non Promo </option>
              </Form.Select>
            )}
            <Form.Label>Comission Category</Form.Label>
            {!isEditing ? (
              <Select
                options={comissionCategories}
                isDisabled
                value={productComissionCategory}
              />
            ) : (
              <Select
                options={comissionCategories}
                onChange={changeProductComissionCategory}
                value={editProductComissionCategory}
              />
            )}
            <Form.Label>Price</Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">Rp</InputGroup.Text>
              {!isEditing ? (
                <Form.Control
                  type="text"
                  placeholder="Price"
                  value={thousandSeparator(Number(productPrice))}
                  readOnly
                />
              ) : (
                <Form.Control
                  type="text"
                  placeholder="Price"
                  value={editProductPrice}
                  onChange={(e) => changeProductPrice(e)}
                />
              )}
            </InputGroup>
          </Form>
        </Row>
      </Container>
      <br />
      <style>
        {`
          @media print {
            @page {
              margin: 0;
            }
            .notPrinted {
              display: none;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductDetail;

import { AppStateType } from "../reducers";

export const categoryListSelector = (state: AppStateType) => {
  return state.category.categoriesList;
};

export const categoryTotalPageSelector = (state: AppStateType) => {
  return state.category.totalPage;
};

export const categoryCurrentPageSelector = (state: AppStateType) => {
  return state.category.currentPage;
};

export const categoryStatusLoadingSelector = (state: AppStateType) => {
  return state.category.statusLoading;
};

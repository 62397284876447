import React, { ChangeEvent, FC, Fragment } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
interface RepetitiveDetailCGSPackageProps {
  id: number;
  readonly?: boolean;
  target: number;
  comrate: number;
  removeFormId: (id: number) => void;
  changeTargetComission: (e: ChangeEvent, id: number) => void;
  changeTargetComRate: (e: ChangeEvent, id: number) => void;
}

const RepetitiveDetailCGSPackage: FC<RepetitiveDetailCGSPackageProps> = ({
  id,
  readonly,
  target,
  comrate,
  removeFormId,
  changeTargetComission,
  changeTargetComRate,
}) => {
  return (
    <Fragment key={id}>
      <div className="repetitive-form-wrapper">
        {id >= 1 && !readonly && (
          <div
            className="icon-delete-repetitive-form"
            onClick={() => removeFormId(id)}
          >
            <FaTrashAlt />
          </div>
        )}
        <Row>
          <Col md="6">
            <Form.Label>Target #{id + 1}(Lebih Dari)</Form.Label>
            <InputGroup>
              <InputGroup.Text>Rp</InputGroup.Text>
              {readonly ? (
                <Form.Control
                  placeholder={`Target #${id + 1}`}
                  value={target}
                  readOnly
                />
              ) : (
                <Form.Control
                  placeholder={`Target #${id + 1}`}
                  value={target}
                  onChange={(e) => changeTargetComission(e, id)}
                />
              )}
            </InputGroup>
          </Col>
          <Col md="6">
            <Form.Label>ComRate #{id + 1}</Form.Label>
            <InputGroup>
              <InputGroup.Text>Rp</InputGroup.Text>
              {readonly ? (
                <Form.Control
                  placeholder={`ComRate #${id + 1}`}
                  value={comrate}
                  readOnly
                />
              ) : (
                <Form.Control
                  placeholder={`ComRate #${id + 1}`}
                  value={comrate}
                  onChange={(e) => changeTargetComRate(e, id)}
                />
              )}
            </InputGroup>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default RepetitiveDetailCGSPackage;

import React, { FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaFileExcel, FaPlus } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FilterProducts from "../../component/FilterProducts";
import ModalAddNewProduct from "../../component/ModalAddNewProducts";
import NavBar from "../../component/Navbar";
import {
  BASE_URL,
  E_FETCH_STATUS,
  PROMO_TYPE,
  TEMPLATE_BRAND,
} from "../../constant";
import {
  loadProductList,
  setProductCurrentPage,
} from "../../redux/actions/product";
import {
  productCurrentPageSelector,
  productListSelector,
  productStatusLoadingSelector,
  productTotalPageSelector,
} from "../../redux/selectors/product";
import ProductService from "../../services/Products";
import thousandSeparator from "../../utils/thousandSeparator";

const Products: FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productList = useSelector(productListSelector);
  const statusLoading = useSelector(productStatusLoadingSelector);
  const currentPage = useSelector(productCurrentPageSelector);
  const totalPage = useSelector(productTotalPageSelector);
  const [isModalAddProductShowing, setIsModalAddProductShowing] =
    useState<boolean>(false);
  const [isModalDeleteProductShowing, setIsModalDeleteProductShowing] =
    useState<boolean>(false);
  const [idProductToBeDeleted, setIdProductToBeDeleted] = useState<number>(0);
  const [nameProductToBeDeleted, setNameProductToBeDeleted] =
    useState<string>("");
  const fileRef = useRef<HTMLInputElement>(null);
  const [isModalUploadShowing, setIsModalUploadShowing] =
    useState<boolean>(false);
  const [fileValue, setFileValue] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");

  const closeModal = () => {
    setIsModalUploadShowing(false);
  };

  const openUploadProductsModal = () => {
    setIsModalUploadShowing(true);
  };

  const triggerFileExplorer = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const uploadFile = () => {
    const body = new FormData();
    if (fileRef.current && fileRef.current.files) {
      body.append("file", fileRef.current.files[0]);
      ProductService.uploadProducts(body).then((data) => {
        if (data.status === 1) {
          alert("Data has been uploaded successfully");
          window.location.reload();
        }
      });
    }
  };

  const onFileChange = (event: any) => {
    event.preventDefault();
    if (fileRef.current !== null) {
      setFileName(event.target.files[0].name);
      setFileValue(fileRef.current.value);
    }
  };

  const openAddProductsModal = () => {
    setIsModalAddProductShowing(true);
  };

  const onModalAddProductClose = () => {
    setIsModalAddProductShowing(false);
  };

  const showModalDeleteProduct = (id: number, name: string) => {
    setIsModalDeleteProductShowing(true);
    setNameProductToBeDeleted(name);
    setIdProductToBeDeleted(id);
  };

  const deleteProduct = () => {
    ProductService.deleteProduct(idProductToBeDeleted).then((data) => {
      if (data.status === 1) {
        alert("Data has been deleted successfully");
        window.location.reload();
      }
    });
  };

  const getTableProduct = () => {
    return productList.map((product, index) => {
      return (
        <tr key={product.id}>
          <td>{(currentPage - 1) * 35 + index + 1}</td>
          <td>{product.productName}</td>
          <td>{product.brandName}</td>
          <td>{`${product.finishingName} - ${product.colour}`}</td>
          <td>{product.articleNo}</td>
          <td>{product.brandCategory}</td>
          <td>{PROMO_TYPE[product.promoType]}</td>
          <td>{product.comissionCategory}</td>
          <td>Rp {thousandSeparator(product.price)}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => {
                  navigate(product.id);
                }}
              >
                View Product
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() => {
                  showModalDeleteProduct(product.id, product.productName);
                }}
              >
                Delete Product
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableProducts = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={10} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          productList.length > 0 ? (
            getTableProduct()
          ) : (
            <tr>
              <td colSpan={10} style={{ textAlign: "center" }}>
                No product found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={10}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              No invoice found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Product Name</th>
            <th>Brand</th>
            <th>Colour</th>
            <th>Article Number</th>
            <th>Category</th>
            <th>Promo Type</th>
            <th>Comission Category</th>
            <th>Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const downloadTemplate = (template: TEMPLATE_BRAND) => {
    switch (template) {
      case TEMPLATE_BRAND.BLANCO:
        window.location.href = `${BASE_URL}assets/template/BLANCO.xlsx`;
        break;
      case TEMPLATE_BRAND.CGS:
        window.location.href = `${BASE_URL}assets/template/CGS.xlsx`;
        break;
      case TEMPLATE_BRAND.HG:
        window.location.href = `${BASE_URL}assets/template/HANSGROHE.xlsx`;
        break;
    }
  };

  useEffect(() => {
    dispatch(loadProductList());
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row>
          <Col
            md="6"
            xs="12"
            className="d-flex justify-content-center justify-content-md-start mb-2 mb-md-0"
          >
            <Button onClick={() => downloadTemplate(TEMPLATE_BRAND.BLANCO)}>
              <FaFileExcel /> Blanco
            </Button>
            &nbsp;
            <Button onClick={() => downloadTemplate(TEMPLATE_BRAND.CGS)}>
              <FaFileExcel /> CGS
            </Button>
            &nbsp;
            <Button onClick={() => downloadTemplate(TEMPLATE_BRAND.HG)}>
              <FaFileExcel /> HG
            </Button>
          </Col>
          <Col
            md="6"
            xs="12"
            className="d-flex justify-content-center justify-content-md-end"
          >
            <Button onClick={openUploadProductsModal}>
              <FaPlus /> Upload Products
            </Button>
            &nbsp;
            <Button onClick={openAddProductsModal}>
              <FaPlus /> Add New Products
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterProducts />
        <br />
        <Row>
          <Col>{renderTableProducts()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setProductCurrentPage(1));
                    dispatch(loadProductList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setProductCurrentPage(currentPage - 1));
                    dispatch(loadProductList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setProductCurrentPage(currentPage + 1));
                    dispatch(loadProductList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setProductCurrentPage(totalPage));
                    dispatch(loadProductList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <Modal show={isModalUploadShowing} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Products</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          {fileValue === "" ? (
            <Button variant="primary" onClick={triggerFileExplorer}>
              <FaFileExcel />
              Upload Products
            </Button>
          ) : (
            <>
              <div className="position-relative d-flex justify-content-center with-border-input w-100 pt-3">
                <div
                  className="d-flex flex-column align-items-center cursor-pointer"
                  onClick={triggerFileExplorer}
                >
                  <FaFileExcel size={60} color="green" className="mb-2" />
                  <p>{fileName}</p>
                </div>
                <div
                  className="deleteIcon cursor-pointer"
                  onClick={() => {
                    setFileValue("");
                    setFileName("");
                  }}
                >
                  <GrClose />
                </div>
              </div>
            </>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={onFileChange}
            ref={fileRef}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={uploadFile}
            disabled={fileValue === ""}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        onHide={() => {
          // setNewNameType("");
          setIsModalDeleteProductShowing(false);
        }}
        show={isModalDeleteProductShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete {nameProductToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Product?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsModalDeleteProductShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteProduct();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalAddNewProduct
        isShowing={isModalAddProductShowing}
        onModalAddProductClose={onModalAddProductClose}
      />
    </>
  );
};

export default Products;

import { AppStateType } from "../reducers";

export const colourListSelector = (state: AppStateType) => {
  return state.colour.colourList;
};

export const colourTotalPageSelector = (state: AppStateType) => {
  return state.colour.totalPage;
};

export const colourCurrentPageSelector = (state: AppStateType) => {
  return state.colour.currentPage;
};

export const colourStatusLoadingSelector = (state: AppStateType) => {
  return state.colour.statusLoading;
};

import { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Pagination,
  Modal,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import {
  setColourCurrentPage,
  loadColourList,
} from "../../redux/actions/colour";
import {
  colourListSelector,
  colourStatusLoadingSelector,
  colourCurrentPageSelector,
  colourTotalPageSelector,
} from "../../redux/selectors/colour";
import FinishingService from "../../services/Finishing";
import ColourService from "../../services/Colour";
import { FinishingSelectData } from "../../interfaces/finishing";
import { DefaultSelection } from "../../interfaces";
import FilterColour from "../../component/FilterColour";

const Colour = () => {
  const dispatch = useDispatch();
  const colourList = useSelector(colourListSelector);
  const statusLoading = useSelector(colourStatusLoadingSelector);
  const currentPage = useSelector(colourCurrentPageSelector);
  const totalPage = useSelector(colourTotalPageSelector);

  // Filter State
  const [filterFinishingName, setFilterFinishingName] =
    useState<DefaultSelection>({ label: "All Finishing", value: 0 });
  const [filterColourName, setFilterColourName] = useState<DefaultSelection>({
    label: "All Colour",
    value: 0,
  });
  //
  const [finishingOptions, setFinishingOptions] = useState<
    FinishingSelectData[]
  >([]);
  const [isAddModalShowing, setIsAddModalShowing] = useState<boolean>(false);
  const [isDeleteModalShowing, setIsDeleteModalShowing] =
    useState<boolean>(false);
  const [newColourName, setNewColourName] = useState<string>("");
  const [newFinishing, setNewFinishing] = useState<{
    value: number;
    label: string;
  }>({ value: 0, label: "Select Finishing" });
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");

  const openDeleteColourModal = (id: number, name: string) => {
    setIsDeleteModalShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
  };

  const getTableColour = () => {
    return colourList.map((colour, index) => {
      return (
        <tr key={colour.id}>
          <td>{colour.id}</td>
          <td>{colour.finishingName}</td>
          <td>{colour.colourName}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteColourModal(colour.id, colour.colourName)
                }
              >
                Delete Colour
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableColour = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={9} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          colourList.length > 0 ? (
            getTableColour()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No Colour Found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              No Colour Found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Finishing</th>
            <th>Colour Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const getFinishingSelection = async () => {
    await FinishingService.getFinishing().then((res) => {
      if (res.data) {
        const finishingSelection: FinishingSelectData[] = res.data.map(
          (finishing) => ({
            value: finishing.id,
            label: finishing.finishingName,
          })
        );
        setFinishingOptions(finishingSelection);
      }
    });
  };

  const openAddColourModal = () => {
    setIsAddModalShowing(true);
    getFinishingSelection();
  };

  const changeNewColourName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewColourName(value);
  };

  const changeNewFinishing = (e: SingleValue<FinishingSelectData>) => {
    if (e) {
      setNewFinishing(e);
    }
  };

  const createNewColour = () => {
    if (newFinishing.value === 0) {
      alert("Please select this colour Finishing");
      return false;
    }

    if (newColourName === "") {
      alert("Please fill the category name field");
    }
    const bodyForm = new FormData();
    bodyForm.append("colourName", newColourName);
    bodyForm.append("finishingId", newFinishing.value.toString());
    ColourService.createColour(bodyForm)
      .then((res) => {
        if (res.status !== 0) {
          alert("You have created the new colour succesfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const deleteColour = async () => {
    await ColourService.deleteColour(idToBeDeleted)
      .then((res) => {
        if (res.status !== 0) {
          alert("You have deleted one colour succesfully");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  // Filter Handler
  const handleFinishingNameFilterChange = (
    e: SingleValue<DefaultSelection>
  ) => {
    if (e) setFilterFinishingName(e);
  };

  const handleColourNameFilterChange = (e: SingleValue<DefaultSelection>) => {
    if (e) setFilterColourName(e);
  };

  const applyFilter = () => {
    dispatch(
      loadColourList(filterFinishingName?.value, filterColourName?.value)
    );
  };

  const resetFilter = () => {
    resetState();
    dispatch(loadColourList());
  };

  const resetState = () => {
    setFilterColourName({ label: "All Colour", value: 0 });
    setFilterFinishingName({ label: "All Finishing", value: 0 });
  };
  //

  useEffect(() => {
    dispatch(loadColourList());
    getFinishingSelection();
  }, []);

  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="10" xs="12" className="d-flex justify-content-end">
            <Button onClick={openAddColourModal}>
              <FaPlus /> Add New Colour
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterColour
          finishingName={filterFinishingName}
          colour={filterColourName}
          finishingList={finishingOptions}
          handleFinishingNameFilterChange={handleFinishingNameFilterChange}
          handleColourNameFilterChange={handleColourNameFilterChange}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableColour()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setColourCurrentPage(1));
                    dispatch(loadColourList());
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setColourCurrentPage(currentPage - 1));
                    dispatch(loadColourList());
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                    type="text"
                    className="pagination-number"
                    value={currentPage}
                    onChange={(e) => changeCurrentPageNumber(e)}
                  /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setColourCurrentPage(currentPage + 1));
                    dispatch(loadColourList());
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setColourCurrentPage(totalPage));
                    dispatch(loadColourList());
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        onHide={() => {
          // setNewNameType("");
          setIsAddModalShowing(false);
        }}
        show={isAddModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Colour
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={createNewColour}>
          <Modal.Body>
            <Form.Label>Finishing</Form.Label>
            <Select
              options={finishingOptions}
              value={newFinishing}
              onChange={(e) => changeNewFinishing(e)}
            />
            <Form.Label>Nama Colour</Form.Label>
            <Form.Control
              type="text"
              placeholder="Colour Name"
              value={newColourName}
              onChange={(e) => changeNewColourName(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setIsAddModalShowing(false);
              }}
              variant="outline-primary"
            >
              Close
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                createNewColour();
              }}
            >
              Create
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Modal Confirmation Delete */}
      <Modal
        onHide={() => {
          setIsDeleteModalShowing(false);
        }}
        show={isDeleteModalShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete {nameToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Colour?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsDeleteModalShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteColour();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Colour;

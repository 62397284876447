import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { SingleValue } from "react-select";
import FilterFinishing from "../../component/FilterFinishing";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { FinishingData } from "../../interfaces/finishing";
import FinishingService from "../../services/Finishing";

const Finishing = () => {
  // Filter State
  const [filterFinishingName, setFilterFinishingName] =
    useState<DefaultSelection>({ label: "All Finishing", value: 0 });
  //
  const [statusLoading, setStatusLoading] = useState<E_FETCH_STATUS>(
    E_FETCH_STATUS.INITIATE
  );
  const [finishingList, setFinishingList] = useState<FinishingData[]>([]);
  const [addModalFinishingShowing, setAddModalFinishingShowing] =
    useState<boolean>(false);
  const [deleteModalFinishingShowing, setDeleteModalFinishingShowing] =
    useState<boolean>(false);
  const [newFinishingName, setNewFinishingName] = useState<string>("");
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);

  const changeNewFinishingName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewFinishingName(value);
  };

  const openDeleteFinishingModal = (id: number, name: string) => {
    setDeleteModalFinishingShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
  };

  const openNewModalFinishingShowing = () => {
    setAddModalFinishingShowing(true);
  };

  const addNewFinishing = async () => {
    const body = new FormData();
    body.append("finishingName", newFinishingName);
    await FinishingService.createNewFinishing(body)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully create new finishing!");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const deleteFinishing = async () => {
    await FinishingService.deleteFinishing(idToBeDeleted)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully delete the selected finishing!");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const getTableFinishing = () => {
    return finishingList.map((finishing, index) => {
      return (
        <tr key={finishing.id}>
          <td>{index + 1}</td>
          <td>{finishing.finishingName}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteFinishingModal(
                    finishing.id,
                    finishing.finishingName
                  )
                }
              >
                Delete Finishing
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };
  const renderTableFinishing = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={3} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          finishingList.length > 0 ? (
            getTableFinishing()
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No Finishing found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={6} style={{ textAlign: "center" }}>
              No Finishing found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Finishing Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  const getAllFinishing = async (filterFinishingName?: number) => {
    setStatusLoading(E_FETCH_STATUS.FETCHING);
    if (filterFinishingName) {
      await FinishingService.getFinishing(filterFinishingName)
        .then((res) => {
          if (res.data) {
            setFinishingList(res.data);
          }
        })
        .catch((e) => {
          alert(e);
        });
    } else {
      await FinishingService.getFinishing()
        .then((res) => {
          if (res.data) {
            setFinishingList(res.data);
          }
        })
        .catch((e) => {
          alert(e);
        });
    }
    setStatusLoading(E_FETCH_STATUS.FETCHED);
  };

  // Filter Handler Change
  const handleFinishingNameFilterChange = (
    e: SingleValue<DefaultSelection>
  ) => {
    if (e) {
      setFilterFinishingName(e);
    }
  };
  const applyFilter = () => {
    getAllFinishing(filterFinishingName?.value);
  };

  const resetFilter = () => {
    setFilterFinishingName({ label: "All Finishing", value: 0 });
    getAllFinishing();
  };
  //

  useEffect(() => {
    getAllFinishing();
  }, []);

  return (
    <>
      <NavBar />

      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col
            md="2"
            xs="12"
            className="d-flex justify-content-md-end justify-content-center"
          >
            <Button onClick={() => openNewModalFinishingShowing()}>
              <FaPlus /> Create New Finishing
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterFinishing
          finishingName={filterFinishingName}
          handleFinishingNameFilterChange={handleFinishingNameFilterChange}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableFinishing()}</Col>
        </Row>
      </Container>
      {/* Add Modal */}
      <Modal
        onHide={() => {
          setAddModalFinishingShowing(false);
        }}
        show={addModalFinishingShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Finishing
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addNewFinishing}>
          <Modal.Body>
            <Form.Label>Finishing Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Finishing Name"
              value={newFinishingName}
              onChange={(e) => changeNewFinishingName(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setAddModalFinishingShowing(false);
              }}
              variant="outline-primary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                addNewFinishing();
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Modal  */}
      <Modal
        onHide={() => {
          setDeleteModalFinishingShowing(false);
        }}
        show={deleteModalFinishingShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete Finishing {nameToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this finishing?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteModalFinishingShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteFinishing();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Finishing;

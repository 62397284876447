import { API_URL, BASE_URL, E_PROMO_TYPE } from "../constant";
import { getCookie } from "../utils/cookies";
import {
  ProductCreateResponse,
  ProductDeleteResponse,
  ProductDetailResponse,
  ProductEditResponse,
  ProductFormSelectionResponse,
  ProductGetPriceResponse,
  ProductGetResponse,
  UploadProductsResponse,
} from "../interfaces/product";

class ProductService {
  public static async uploadProducts(
    body: FormData
  ): Promise<UploadProductsResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.uploadProducts}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: UploadProductsResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getProductsList(
    params: string
  ): Promise<ProductGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getProducts}/${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ProductGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getProductDetail(
    id: number
  ): Promise<ProductDetailResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getDetailProduct}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ProductDetailResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async editProduct(
    id: number,
    body: FormData
  ): Promise<ProductEditResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.editProduct}/${id}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: ProductEditResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteProduct(
    id: number
  ): Promise<ProductDeleteResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteProduct}/${id}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ProductDeleteResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createNewProduct(
    body: FormData
  ): Promise<ProductCreateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createProduct}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: ProductCreateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getFormProductSelection(
    promoType: E_PROMO_TYPE,
    brand: number
  ): Promise<ProductFormSelectionResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getFormProduct}?promo_type=${promoType}&brand=${brand}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ProductFormSelectionResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getProductPrice(
    body: FormData
  ): Promise<ProductGetPriceResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.getProductPrice}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: ProductGetPriceResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Error while getting product price");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ProductService;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Container,
  Form,
  InputGroup,
  Image,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { GrClose } from "react-icons/gr";
import NavBar from "../../component/Navbar";
import { E_PROMO_TYPE } from "../../constant";
import SalesReportService from "../../services/SalesReport";
import checkNumericInput from "../../utils/checkNumericInput";
import { SingleValue } from "react-select";
import {
  ProductColourSelectData,
  ProductSelectData,
} from "../../interfaces/product";
import checkNumericInputNumber from "../../utils/checkNumericInputNumber";
import RepetitiveFormSalesReport from "../../component/RepetitiveFormSalesReport";
import "./index.scss";
import { toast, ToastContainer } from "react-toastify";
import thousandSeparator from "../../utils/thousandSeparator";
import ProductService from "../../services/Products";

const maxDate: Date = new Date();
const minDate: Date = new Date(
  maxDate.getFullYear(),
  maxDate.getMonth(),
  maxDate.getDate() - 6
);

const FormSalesReport = () => {
  const fileAttachmentRef = useRef<HTMLInputElement>(null);
  const [formCount, setFormCount] = useState<number>(1);
  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [tglPenjualan, setTglPenjualan] = useState(maxDate);
  const [namaPembeli, setNamaPembeli] = useState<string>("");
  const [alamatPembeli, setAlamatPembeli] = useState<string>("");
  const [nomorPembeli, setNomorPembeli] = useState<string>("");
  const [emailPembeli, setEmailPembeli] = useState<string>("");
  const [attachmentFile, setAttachmentFile] = useState<string>("");
  const [promoType, setPromoType] = useState<E_PROMO_TYPE[]>([
    E_PROMO_TYPE.PROMO_TOKO,
  ]);
  const [productBrandChosen, setProductBrandChosen] = useState<number[]>([0]);
  const [productColourChosen, setProductColourChosen] = useState<
    SingleValue<ProductColourSelectData>[]
  >([
    {
      value: 0,
      label: "Select Colour",
    },
  ]);
  const [productPrice, setProductPrice] = useState<number[]>([0]);
  const [productChosen, setProductChosen] = useState<
    SingleValue<ProductSelectData>[]
  >([
    {
      value: 0,
      label: "Select Product",
      colourAvailable: false,
    },
  ]);
  const [productCount, setProductCount] = useState<number[]>([0]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    let total = 0;
    productPrice.forEach((price, index) => {
      total += price * productCount[index];
    });
    setTotalPrice(total);
  }, [productPrice, productCount]);

  const changeInvoiceNumber = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setInvoiceNumber(value);
  };
  const changeTglPenjualan = (e: any) => {
    setTglPenjualan(e);
  };

  const changeNamaPembeli = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNamaPembeli(value);
  };

  const changeAlamatPembeli = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setAlamatPembeli(value);
  };

  const changeNomorPembeli = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNomorPembeli(newValue);
  };

  const changeEmailPembeli = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEmailPembeli(value);
  };

  const changePromoType = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempPromoType = [...promoType];
    tempPromoType[id] = Number(value) as E_PROMO_TYPE;
    setPromoType(tempPromoType);
    const tempProductChosen = [...productChosen];
    const tempProductPrice = [...productPrice];
    tempProductChosen[id] = {
      value: 0,
      label: "Select Product",
      colourAvailable: false,
    };
    tempProductPrice[id] = 0;
    setProductChosen(tempProductChosen);
    setProductPrice(tempProductPrice);
  };

  const changeProductChosen = (
    e: SingleValue<ProductSelectData>,
    id: number
  ) => {
    if (e) {
      const tempProductChosen = [...productChosen];
      const tempProductColourChosen = [...productColourChosen];
      const tempProductPrice = [...productPrice];
      tempProductChosen[id] = e;
      tempProductColourChosen[id] = { value: 0, label: "Select Colour" };
      tempProductPrice[id] = 0;
      setProductChosen(tempProductChosen);
      setProductColourChosen(tempProductColourChosen);
      setProductPrice(tempProductPrice);
      if (!tempProductChosen[id]?.colourAvailable) {
        getProductPriceWithoutColour(id, e.value);
      }
    }
  };

  const getProductPriceWithColour = async (
    id: number,
    tempProductColourChosen: number
  ) => {
    const body = new FormData();
    body.append("productBrand", productBrandChosen[id].toString());
    body.append("productId", productChosen[id]?.value.toString() || "");
    body.append("promoType", promoType[id].toString());
    if (productColourChosen[id] && productChosen[id]?.colourAvailable)
      body.append("colour", tempProductColourChosen?.toString() || "");

    const tempProductPrice = [...productPrice];
    await ProductService.getProductPrice(body).then((res) => {
      if (res.status === 1) {
        tempProductPrice[id] = Number(res.data);
        setProductPrice(tempProductPrice);
      }
    });
  };

  const getProductPriceWithoutColour = async (
    id: number,
    productId?: number
  ) => {
    const body = new FormData();
    body.append("productBrand", productBrandChosen[id].toString());
    body.append("productId", productId?.toString() || "");
    body.append("promoType", promoType[id].toString());

    const tempProductPrice = [...productPrice];
    await ProductService.getProductPrice(body).then((res) => {
      if (res.status === 1) {
        tempProductPrice[id] = Number(res.data);
        setProductPrice(tempProductPrice);
      }
    });
  };

  const changeProductColourChosen = (
    e: SingleValue<ProductColourSelectData>,
    id: number
  ) => {
    if (e) {
      const tempProductColourChosen = [...productColourChosen];
      tempProductColourChosen[id] = e;
      setProductColourChosen(tempProductColourChosen);
      getProductPriceWithColour(id, e.value);
    }
  };

  const changeProductCount = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempProductCount = [...productCount];
    const newValue = checkNumericInputNumber(value, 100);
    tempProductCount[id] = newValue;

    setProductCount(tempProductCount);
  };

  const changeProductBrandChosen = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const tempProductBrandChosen = [...productBrandChosen];
    const tempProductChosen = [...productChosen];
    const tempProductPrice = [...productPrice];
    tempProductBrandChosen[id] = Number(value);
    tempProductChosen[id] = {
      value: 0,
      label: "Select Product",
      colourAvailable: false,
    };
    tempProductPrice[id] = 0;
    setProductBrandChosen(tempProductBrandChosen);
    setProductChosen(tempProductChosen);
    setProductPrice(tempProductPrice);
  };

  const removeFormId = (id: number) => {
    const tempPromoType = [...promoType];
    const tempProductBrandChosen = [...productBrandChosen];
    const tempProductCount = [...productCount];
    const tempProductChosen = [...productChosen];
    const tempProductColourChosen = [...productColourChosen];
    // const tempFormCount = [...formCount];
    tempPromoType.splice(id, 1);
    tempProductBrandChosen.splice(id, 1);
    tempProductCount.splice(id, 1);
    tempProductChosen.splice(id, 1);
    tempProductColourChosen.splice(id, 1);
    // tempFormCount.splice(id, 1);
    setPromoType(tempPromoType);
    setProductBrandChosen(tempProductBrandChosen);
    setProductCount(tempProductCount);
    setProductChosen(tempProductChosen);
    setProductColourChosen(tempProductColourChosen);
    setFormCount(formCount - 1);
  };

  const renderFormProduct = () => {
    // return formCount.map((id) => {
    let temp: JSX.Element | JSX.Element[] = [];
    for (let id = 0; id < formCount; id++) {
      temp.push(
        <RepetitiveFormSalesReport
          id={id}
          promoType={promoType[id]}
          productChosen={productChosen[id]}
          productCount={productCount[id]}
          productBrandChosen={productBrandChosen[id]}
          productPrice={productPrice[id]}
          productColourChosen={productColourChosen[id]}
          changePromoType={changePromoType}
          changeProductChosen={changeProductChosen}
          changeProductColourChosen={changeProductColourChosen}
          changeProductCount={changeProductCount}
          changeProductBrandChosen={changeProductBrandChosen}
          removeFormId={removeFormId}
        />
      );
    }
    return temp;

    // });
  };

  const addFormProduct = () => {
    setFormCount(formCount + 1);
    setPromoType([...promoType, E_PROMO_TYPE.PROMO_TOKO]);
    setProductCount([...productCount, 0]);
    setProductBrandChosen([...productBrandChosen, 0]);
    setProductChosen([
      ...productChosen,
      {
        value: 0,
        label: "Select Product",
        colourAvailable: false,
      },
    ]);
    setProductColourChosen([
      ...productColourChosen,
      {
        value: 0,
        label: "Select Colour",
      },
    ]);
    setProductPrice([...productPrice, 0]);
  };

  const submitForm = () => {
    if (invoiceNumber === "") {
      toast.error("Harap masukan nomor Invoice!");
      return false;
    }
    if (namaPembeli === "") {
      toast.error("Harap masukan nama pembeli!");
      return false;
    }
    if (alamatPembeli === "") {
      toast.error("Harap masukan alamat pembeli!");
      return false;
    }
    if (nomorPembeli === "") {
      toast.error("Harap masukan nomor telefon pembeli!");
      return false;
    }
    if (emailPembeli === "") {
      toast.error("Harap masukan alamat email pembeli!");
      return false;
    }
    const productsValue = [];
    for (let i = 0; i < formCount; i++) {
      if (productBrandChosen[i] === 0) {
        toast.error(`Harap pilih brand untuk item nomor ${i + 1}`);
        return false;
      }
      if (productChosen[i]?.value === 0) {
        toast.error(`Harap pilih produk untuk item nomor ${i + 1}`);
        return false;
      }
      if (
        productChosen[i]?.colourAvailable &&
        productColourChosen[i]?.value === 0
      ) {
        toast.error(`Harap pilih warna produk untuk item nomor ${i + 1}`);
        return false;
      }
      if (productCount[i] === 0) {
        toast.error(`Harap masukan jumlah produk untuk item nomor ${i + 1}`);
        return false;
      }
      productsValue.push(productChosen[i]?.value);
    }
    if (attachmentFile === "") {
      toast.error("Harap pilih bukti invoice untuk diupload!");
      return false;
    }
    const bodyForm = new FormData();
    bodyForm.append("invoiceNo", invoiceNumber);
    bodyForm.append(
      "tglPenjualan",
      `${tglPenjualan.getFullYear()}-${tglPenjualan.getMonth()}-${tglPenjualan.getDate()}`
    );
    bodyForm.append("customerName", namaPembeli);
    bodyForm.append("alamatPembeli", alamatPembeli);
    bodyForm.append("nomorPembeli", nomorPembeli);
    bodyForm.append("emailPembeli", emailPembeli);
    bodyForm.append("productBrands", productBrandChosen.toString());
    bodyForm.append("productPromoType", promoType.toString());
    bodyForm.append("products", productsValue.toString());
    bodyForm.append("productCounts", productCount.toString());
    if (fileAttachmentRef.current && fileAttachmentRef.current.files) {
      bodyForm.append("attachmentFile", fileAttachmentRef.current.files[0]);
    }
    SalesReportService.createSalesReport(bodyForm).then((res) => {
      if (res.status === 1) {
        alert("Laporan penjuaan telah berhasil terbuat!");
        window.location.href = "/dashboard";
        return;
      }
      toast.error(
        "Terjadi kesalahan dalam pembuatan laporan penjualan. Silahkan hubungi administrator"
      );
      return false;
    });
  };

  const openFileExplorer = () => {
    (fileAttachmentRef as any).current.click();
  };

  const changeAttachmentFile = (e: any) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const imageURL = (e as any).target.result;
      if (imageURL) {
        setAttachmentFile(imageURL);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <NavBar />
      <br />
      <Container>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Form>
          <h2>Data Pelanggan</h2>
          <Form.Label>No. Invoice</Form.Label>
          <Form.Control
            type="text"
            placeholder="No. Invoice"
            value={invoiceNumber}
            onChange={(e) => changeInvoiceNumber(e)}
          />
          <Form.Label>Tanggal Penjualan</Form.Label>
          <DatePicker
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            dateFormat="dd-MM-yyyy"
            selected={tglPenjualan}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={maxDate}
            minDate={minDate}
            onChange={(date) => changeTglPenjualan(date)}
          />
          <Form.Label>Nama Pembeli</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nama Pembeli"
            value={namaPembeli}
            onChange={(e) => changeNamaPembeli(e)}
          />
          <Form.Label>Alamat Pembeli</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            placeholder="Alamat Pembeli"
            value={alamatPembeli}
            onChange={(e) => changeAlamatPembeli(e)}
          />
          <Form.Label>No. HP Pembeli</Form.Label>
          <InputGroup>
            <InputGroup.Text>+62</InputGroup.Text>
            <Form.Control
              placeholder="No. HP Pembeli"
              value={nomorPembeli}
              onChange={(e) => changeNomorPembeli(e)}
            />
          </InputGroup>
          <Form.Label>Email Pembeli</Form.Label>
          <Form.Control
            type="text"
            placeholder="Email Pembeli"
            value={emailPembeli}
            onChange={(e) => changeEmailPembeli(e)}
          />
          <h2>Data Item</h2>
          {renderFormProduct()}
          <Button onClick={addFormProduct}>Add New Product</Button>
          <br />
          <Form.Label>Total Harga Penjualan (Brosur)</Form.Label>
          <Form.Control
            type="text"
            readOnly
            value={`Rp. ${thousandSeparator(totalPrice)}`}
          />
          <Form.Label>Bukti Pembelian (Invoice)</Form.Label>
          {attachmentFile === "" ? (
            <InputGroup className="cursor-pointer" onClick={openFileExplorer}>
              <InputGroup.Text>Choose File</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="No file chosen"
                readOnly
                className="cursor-pointer"
              />
            </InputGroup>
          ) : (
            <div className="position-relative d-flex justify-content-center with-border-input">
              <Image
                src={attachmentFile}
                onClick={openFileExplorer}
                className="imageInvoice"
              />
              <div
                className="deleteIcon cursor-pointer"
                onClick={() => setAttachmentFile("")}
              >
                <GrClose />
              </div>
            </div>
          )}
          <input
            type="file"
            ref={fileAttachmentRef}
            style={{ display: "none" }}
            accept="image/png, image/jpeg, image/jpg"
            onChange={(e) => changeAttachmentFile(e)}
          />
          <br />
          <Row>
            <Col md="12">
              <Button className="width-100" onClick={submitForm}>
                Submit
              </Button>
            </Col>
          </Row>
          <br />
        </Form>
      </Container>
    </>
  );
};

export default FormSalesReport;

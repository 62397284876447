import React, { FC, useEffect, useState } from "react";
import DashboardAdmin from "./Admin";
import DashboardSales from "./Sales";
import "./index.scss";
import { getCookie } from "../../utils/cookies";
import { E_AUTH_STATUS } from "../../constant";

const Dashboard: FC<{}> = () => {
  const statusUser = getCookie("userstatus");
  switch (Number(statusUser)) {
    case E_AUTH_STATUS.SUPERADMIN:
    case E_AUTH_STATUS.MANAGER:
    case E_AUTH_STATUS.SUPERVISOR:
    case E_AUTH_STATUS.AREA_SUPERVISOR:
      return <DashboardAdmin statusUser={Number(statusUser)} />;
    default:
      return <DashboardSales />;
  }
};

export default Dashboard;

import { FC, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MONTH_FULL } from "../../constant";
import { ComissionData } from "../../interfaces/comission";
import { SalesReportData, E_FILTER_STATUS } from "../../interfaces/salesreport";
import ComissionService from "../../services/Comission";
import SalesReportService from "../../services/SalesReport";
import thousandSeparator from "../../utils/thousandSeparator";
import "./index.scss";
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = today.getMonth(); //January is 0!
var yyyy = today.getFullYear();

const SalesComissionCount: FC = () => {
  const [dataComission, setDataComission] = useState<ComissionData>();
  const [unchangedSalesReportList, setUnchangedSalesReportList] = useState<
    SalesReportData[]
  >([]);
  const countComission = async () => {
    await ComissionService.getComissions(mm + 1, yyyy)
      .then((res) => {
        if (res.status === 1) {
          setDataComission(res.data);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const getUnchangedSalesReportListData = async () => {
    let params = `?month=${mm + 1}&year=${yyyy}`;
    await SalesReportService.getSalesReport(params)
      .then((res) => {
        if (res.status === 1) {
          setUnchangedSalesReportList(res.data);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const comissionTarget = useMemo(() => {
    return (
      Number(dataComission?.blanco ?? 0) +
      Number(dataComission?.cgs ?? 0) +
      Number(dataComission?.hg ?? 0) +
      Number(dataComission?.cgspackage ?? 0)
    );
  }, [dataComission]);

  const totalComissionApproved = useMemo(() => {
    let total = 0;
    if (unchangedSalesReportList) {
      unchangedSalesReportList
        .filter(
          (salesReport) =>
            Number(salesReport.status) === E_FILTER_STATUS.APPROVED_BY_ADMIN
        )
        .forEach((data) => {
          total += data.totalComission;
        });
    }
    return total;
  }, [unchangedSalesReportList]);

  const totalAllComission = useMemo(() => {
    return totalComissionApproved + comissionTarget;
  }, [comissionTarget, totalComissionApproved]);

  useEffect(() => {
    countComission();
    getUnchangedSalesReportListData();
  }, []);

  return (
    <>
      <div className="box-comission-count">
        <Row>
          <Col xs={6}>
            <strong>Perhitungan Komisi</strong>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <p>
              {dd} {MONTH_FULL[mm]} {yyyy}
            </p>
          </Col>
        </Row>
        <hr style={{ margin: "0px !important" }} />
        <Row>
          <Col xs={6}>
            <p>Komisi Penjualan</p>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <p>Rp {thousandSeparator(totalComissionApproved)}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <p>Komisi Target</p>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <p>Rp {thousandSeparator(comissionTarget)}</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={6}>
            <strong>Perhitungan Komisi</strong>
          </Col>
          <Col xs={6} className="d-flex justify-content-end">
            <strong>Rp {thousandSeparator(totalAllComission)}</strong>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SalesComissionCount;

import { AppStateType } from "../reducers";

export const salesReportListSelector = (state: AppStateType) => {
  return state.salesreport.salesReportList;
};

export const salesReportTotalPageSelector = (state: AppStateType) => {
  return state.salesreport.totalPage;
};

export const salesReportCurrentPageSelector = (state: AppStateType) => {
  return state.salesreport.currentPage;
};

export const salesReportStatusLoadingSelector = (state: AppStateType) => {
  return state.salesreport.statusLoading;
};

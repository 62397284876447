import "./App.scss";
import SignIn from "./container/SignIn";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { getCookie } from "./utils/cookies";
import Dashboard from "./container/Dashboard";
import Users from "./container/Users";
import Packages from "./container/Packages";
import Products from "./container/Products";
import ProductDetail from "./container/ProductDetail";
import Shops from "./container/Shops";
import ShopDetail from "./container/ShopDetail";
import UserDetail from "./container/UserDetail";
import Category from "./container/Category";
import Brand from "./container/Brand";
import Colour from "./container/Colour";
import FormSalesReport from "./container/FormSalesReport";
import SalesReportDetail from "./container/SalesReportDetail";
import Comission from "./container/Comission";
import ComissionDetail from "./container/ComissionDetail";
import ComissionCategory from "./container/ComissionCategory";
import ComissionCategoryDetail from "./container/ComissionCategoryDetail";
import Finishing from "./container/Finishing";
import ListSalesReport from "./container/ListSalesReport";
import ListTarget from "./container/ListTarget";
import ComissionCGSPackage from "./container/ComissionCGSPackage";
import ComissionCGSPackageDetail from "./container/ComissionCGSPackageDetail";
import loadable from "@loadable/component";

const UserProfile = loadable(() => import("./container/UserProfile"));

const App = () => {
  const cookies = getCookie("user");
  return (
    <Router>
      <Routes>
        <Route
          path={"/sign-in"}
          element={cookies !== null ? <Navigate to="/dashboard" /> : <SignIn />}
        />
        <Route
          path="/dashboard"
          element={cookies ? <Dashboard /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/user"
          element={cookies ? <Users /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/user/:idUser"
          element={cookies ? <UserDetail /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/packages"
          element={cookies ? <Packages /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/products"
          element={cookies ? <Products /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/products/:idProduct"
          element={cookies ? <ProductDetail /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/stores"
          element={cookies ? <Shops /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/stores/:idShop"
          element={cookies ? <ShopDetail /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/categories"
          element={cookies ? <Category /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/brand"
          element={cookies ? <Brand /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/finishing"
          element={cookies ? <Finishing /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/colour"
          element={cookies ? <Colour /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/create-sales-report"
          element={cookies ? <FormSalesReport /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/comission"
          element={cookies ? <Comission /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/comission-detail/:idComission"
          element={cookies ? <ComissionDetail /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/sales-report/:idSalesReport"
          element={cookies ? <SalesReportDetail /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/comission-category"
          element={cookies ? <ComissionCategory /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/comission-package-cgs"
          element={
            cookies ? <ComissionCGSPackage /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/comission-package-cgs/:idComissionCGS"
          element={
            cookies ? <ComissionCGSPackageDetail /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/comission-category-detail/:idComissionCategory"
          element={
            cookies ? <ComissionCategoryDetail /> : <Navigate to="/sign-in" />
          }
        />
        <Route
          path="/list-sales-report"
          element={cookies ? <ListSalesReport /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/list-target"
          element={cookies ? <ListTarget /> : <Navigate to="/sign-in" />}
        />
        <Route
          path="/"
          element={
            cookies !== null ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/profile"
          element={cookies ? <UserProfile /> : <Navigate to="/sign-in" />}
        />
      </Routes>
    </Router>
  );
};

export default App;

/* Notes

1. SKU kalau sama, timpa aja \\
2. Hanya sales saja yang bisa create sales report // \\
3. Attachment => Bukti Pembelian (Invoice) // \\
4. Tambahin harga produk dibawah form sales report // \\
5. manager and area supervisor cannot approve or reject //
6. tampilan sales //
  a. tanggal penjualan invoice
  b. nomor invoice
  c. jumlah total penjualan
  d. komisi yang diterima
  e. status
7. tambahin target sales di create user
*/

/*
1. Tambahin filter untuk sales target
2. Fixed div comission => tambahan target bulanan
3. Tambahan total penjualan blanco + cgs
4. benerin mapping warna di sales report
5. Tambahin warna di sales report
*/

// Blanco paket promo silgranit 10 paket > 100.000 x 10 = 1.000.000
// Blanco paket promo sink only 4 pcs > 50.000 x 4 = 200.000
// cgs promo 10 paket > 25.000 x 10 = 250.000
// cgs promo sink only 10pcs  >  12.500 x 10 = 125.000
// blanco 150 juta > target 1 = 250.000
// cgs 100 juta > target 2 = 500.000
// cgs kejual 20 unit > target 1= 100.000
// total komisi => 2.425.000

import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_AUTH_STATUS, E_FETCH_STATUS } from "../../constant";
import {
  ManagerGetResponse,
  UserData,
  UserGetResponse,
} from "../../interfaces/user";
import UserService from "../../services/User";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { UserType } from "../types/user";

export const setUserList = (users: UserData[]) => {
  return {
    type: UserType.USER_SET_LIST,
    payload: users,
  };
};

export const setUserStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: UserType.USER_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setUserCurrentPage = (currentPage: number) => {
  return {
    type: UserType.USER_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setUserTotalPage = (totalPage: number) => {
  return {
    type: UserType.USER_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const setUserAreaSupervisorList = (areaSupervisors: UserData[]) => {
  return {
    type: UserType.USER_SET_AREA_SUPERVISOR_LIST,
    payload: areaSupervisors,
  };
};

export const setUserSupervisorList = (supervisors: UserData[]) => {
  return {
    type: UserType.USER_SET_SUPERVISOR_LIST,
    payload: supervisors,
  };
};

export const setUserManagerList = (managers: UserData[]) => {
  return {
    type: UserType.USER_SET_MANAGER_LIST,
    payload: managers,
  };
};

export const loadUserList = (filterUserId?: number, status?: E_AUTH_STATUS) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setUserStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        user: { currentPage },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      if (filterUserId && filterUserId !== 0) {
        params += `&userId=${filterUserId}`;
      }
      if (status) {
        params += `&status=${status}`;
      }
      const res: UserGetResponse = await UserService.getUserList(params);
      if (res.data) {
        dispatch(setUserList(res.data));
        dispatch(setUserTotalPage(res.total_page));
        dispatch(setUserStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setUserStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setUserStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

export const loadAreaSupervisorList = (currentUserId: number) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ManagerGetResponse = await UserService.getAreaSupervisorList(
        currentUserId
      );
      if (res.data) {
        dispatch(setUserAreaSupervisorList(res.data));
      }
    } catch (e: any) {
      dispatch(setUserStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

export const loadSupervisorList = (currentUserId: number) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ManagerGetResponse = await UserService.getSupervisorList(
        currentUserId
      );
      if (res.data) {
        dispatch(setUserSupervisorList(res.data));
      }
    } catch (e: any) {
      dispatch(setUserStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

export const loadManagerList = (currentUserId: number) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      const res: ManagerGetResponse = await UserService.getManagerList(
        currentUserId
      );
      if (res.data) {
        dispatch(setUserManagerList(res.data));
      }
    } catch (e: any) {
      dispatch(setUserStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

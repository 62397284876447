import { ChangeEvent, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavBar from "../../component/Navbar";
import ComissionCategoryService from "../../services/ComissionCategory";

const ComissionCategoryDetail = () => {
  const { idComissionCategory } = useParams();
  const [editComissionCategoryName, setEditComissionCategoryName] =
    useState<string>("");
  const [editComissionCategoryPrice, setEditComissionCategoryPrice] =
    useState<number>(0);

  const getComissionCategoryDetail = async () => {
    await ComissionCategoryService.getComissionsCategoryDetail(
      Number(idComissionCategory) || 0
    )
      .then((res) => {
        if (res.data) {
          setEditComissionCategoryName(res.data.comissionCategoryName);
          setEditComissionCategoryPrice(res.data.comissionCategoryPrice);
        }
      })
      .catch((e) => alert(e));
  };

  const changeComissionCategoryName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditComissionCategoryName(value);
  };

  const changeComissionCategoryPrice = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditComissionCategoryPrice(Number(value));
  };

  const saveEditedData = async () => {
    if (editComissionCategoryName === "") {
      alert("Comission category name must not be empty!");
    }
    if (editComissionCategoryPrice <= 0) {
      alert("Comission category price must be greater than 0");
    }
    const bodyForm = new FormData();
    bodyForm.append("comissionCategoryName", editComissionCategoryName);
    bodyForm.append(
      "comissionCategoryPrice",
      editComissionCategoryPrice.toString()
    );
    await ComissionCategoryService.updateComissionCategory(
      Number(idComissionCategory) || 0,
      bodyForm
    ).then((res) => {
      if (res.status === 1) {
        alert("Successfully update the comission category");
        window.location.reload();
      }
    });
  };
  useEffect(() => {
    if (idComissionCategory) getComissionCategoryDetail();
  }, [idComissionCategory]);
  return (
    <>
      <NavBar customBrand={"Informasi Toko"} />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            <Button onClick={saveEditedData}>Save</Button>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Form>
            <Form.Label>Comission Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comission Category Name"
              value={editComissionCategoryName}
              onChange={(e) => changeComissionCategoryName(e)}
            />
            <Form.Label>Comission Category Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comission Category Price"
              value={editComissionCategoryPrice}
              onChange={(e) => changeComissionCategoryPrice(e)}
            />
          </Form>
        </Row>
      </Container>
      <br />
    </>
  );
};

export default ComissionCategoryDetail;

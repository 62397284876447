import { API_URL, BASE_URL } from "../constant";
import {
  CreateComissionCategoryResponse,
  DeleteComissionCategoryResponse,
  GetComissionCategoryDetailResponse,
  GetComissionCategoryResponse,
  GetComissionCategorySelectResponse,
  UpdateComissionCategoryResponse,
} from "../interfaces/comissioncategory";
import { getCookie } from "../utils/cookies";

class ComissionCategoryService {
  public static async getComissionsCategory(
    params?: string
  ): Promise<GetComissionCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getComissionCategory}${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionCategoryResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getComissionsCategoryDetail(
    comissionCategoryId: number
  ): Promise<GetComissionCategoryDetailResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getDetailComissionCategory}/${comissionCategoryId}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionCategoryDetailResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Comission category not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async updateComissionCategory(
    comissionCategoryId: number,
    body: FormData
  ): Promise<UpdateComissionCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.updateComissionCategory}/${comissionCategoryId}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: UpdateComissionCategoryResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Comission category not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createNewComissionCategory(
    body: FormData
  ): Promise<CreateComissionCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.createComissionCategory}`,
      {
        method: "POST",
        body,
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: CreateComissionCategoryResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Error while creating new comision category");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteComissionCategory(
    id: number
  ): Promise<DeleteComissionCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteComissionCategory}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DeleteComissionCategoryResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Error while creating new comision category");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getComissionCategorySelection(): Promise<GetComissionCategorySelectResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getComissionCategorySelect}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetComissionCategorySelectResponse =
      await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ComissionCategoryService;

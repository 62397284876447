import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { BrandSelectData } from "../../interfaces/brand";
import Select, { SingleValue } from "react-select";
import BrandService from "../../services/Brand";
import { ComissionUserSelection } from "../../interfaces/comission";
import UserService from "../../services/User";
import { DefaultSelection } from "../../interfaces";
import ShopService from "../../services/Shop";

interface FilterComissionCGSPackageProps {
  filterShop: DefaultSelection;
  changeFilterShop: (e: SingleValue<DefaultSelection>) => void;
  resetFilter: () => void;
  applyFilter: () => void;
}

const FilterComissionCGSpackage: FC<FilterComissionCGSPackageProps> = ({
  filterShop,
  changeFilterShop,
  resetFilter,
  applyFilter,
}) => {
  const [shopList, setShopList] = useState<DefaultSelection[]>([]);

  const getSelection = async () => {
    await ShopService.getShopSelectList().then((res) => {
      if (res.data) {
        const shopSelection: DefaultSelection[] = res.data.map((shop) => ({
          value: shop.id,
          label: shop.storeName,
        }));
        setShopList(shopSelection);
      }
    });
  };

  useEffect(() => {
    getSelection();
  }, []);

  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={6}>
            <Form.Label>Shop</Form.Label>
            <Select
              options={shopList}
              value={filterShop}
              onChange={changeFilterShop}
            />
          </Col>

          <Col
            md={{ offset: 3, span: 3 }}
            xs={12}
            className="col align-self-end d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterComissionCGSpackage;

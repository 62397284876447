import { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { FinishingData } from "../../interfaces/finishing";
import FinishingService from "../../services/Finishing";

interface FilterFinishingProps {
  finishingName: DefaultSelection;
  handleFinishingNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  applyFilter: () => void;
  resetFilter: () => void;
}

const FilterFinishing: FC<FilterFinishingProps> = (props) => {
  const {
    finishingName,
    handleFinishingNameFilterChange,
    applyFilter,
    resetFilter,
  } = props;
  const [finishingList, setFinishingList] = useState<DefaultSelection[]>([]);

  const getFinishingList = async () => {
    await FinishingService.getFinishing()
      .then((res) => {
        if (res.data) {
          const finishingListTemp: DefaultSelection[] = res.data.map(
            (finishing) => {
              return {
                value: finishing.id,
                label: finishing.finishingName,
              };
            }
          );
          setFinishingList(finishingListTemp);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  useEffect(() => {
    getFinishingList();
  }, []);
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Finishing</Form.Label>
              <Select
                options={finishingList}
                value={finishingName}
                onChange={handleFinishingNameFilterChange}
              />
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 8, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterFinishing;

const changeStatusSalesReport = (status: number) => {
  switch (status) {
    case 1:
      return "Processing";
    case 2:
      return "Supervisor Approved";
    case 3:
      return "Approved By Admin";
    case 4:
      return "Rejected By Supervisor";
    case 5:
      return "Rejected by Admin";
    default:
      return "hah?";
  }
};

export default changeStatusSalesReport;

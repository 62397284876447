import { useState, useEffect, ChangeEvent, useMemo, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS, MONTH_FULL } from "../../constant";
import {
  ComissionData,
  ComissionMappingData,
} from "../../interfaces/comission";
import { E_FILTER_STATUS, SalesReportData } from "../../interfaces/salesreport";
import {
  setSalesReportCurrentPage,
  loadSalesReportList,
} from "../../redux/actions/salesreport";
import {
  salesReportListSelector,
  salesReportCurrentPageSelector,
  salesReportTotalPageSelector,
  salesReportStatusLoadingSelector,
} from "../../redux/selectors/salesreport";
import ComissionService from "../../services/Comission";
import SalesReportService from "../../services/SalesReport";
import changeStatusSalesReport from "../../utils/changeStatusSalesReport";
import thousandSeparator from "../../utils/thousandSeparator";
import FilterDashboardSales from "../Dashboard/Sales/FilterDashboardSales";
import "./index.scss";

const dateObj = new Date();
const currentYear = dateObj.getFullYear();
const currentMonth = dateObj.getMonth() + 1;
const maximumYear = currentYear - 25;

interface StatusComissionDisplay {
  approved: number;
  processing: number;
  rejected: number;
}

const ListSalesReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salesReportList = useSelector(salesReportListSelector);
  const currentPage = useSelector(salesReportCurrentPageSelector);
  const totalPage = useSelector(salesReportTotalPageSelector);
  const statusLoading = useSelector(salesReportStatusLoadingSelector);
  const [filterMonth, setFilterMonth] = useState<number>(currentMonth);
  const [filterYear, setFilterYear] = useState<number>(currentYear);
  const [dataComission, setDataComission] = useState<ComissionData>();
  const [unchangedSalesReportList, setUnchangedSalesReportList] = useState<
    SalesReportData[]
  >([]);
  // const [dataComissionMapping, setDataComissionMapping] =
  //   useState<ComissionMappingData>();
  const viewSalesReport = (id: number) => {
    navigate(`/sales-report/${id}`);
  };

  useEffect(() => {
    getUnchangedSalesReportListData();
    dispatch(loadSalesReportList(filterMonth, filterYear));
  }, [filterMonth, filterYear]);

  const statusComission: StatusComissionDisplay = useMemo(() => {
    return {
      approved: unchangedSalesReportList.filter(
        (data) => Number(data.status) === E_FILTER_STATUS.APPROVED_BY_ADMIN
      ).length,
      processing: unchangedSalesReportList.filter(
        (data) =>
          Number(data.status) === E_FILTER_STATUS.PENDING ||
          Number(data.status) === E_FILTER_STATUS.APPROVED_BY_SUPERVISOR
      ).length,
      rejected: unchangedSalesReportList.filter(
        (data) =>
          Number(data.status) === E_FILTER_STATUS.REJECTED_BY_ADMIN ||
          Number(data.status) === E_FILTER_STATUS.REJECTED_BY_SUPERVISOR
      ).length,
    };
  }, [unchangedSalesReportList]);

  const getUnchangedSalesReportListData = async () => {
    let params = `?month=${filterMonth}&year=${filterYear}`;
    await SalesReportService.getSalesReport(params)
      .then((res) => {
        if (res.status === 1) {
          setUnchangedSalesReportList(res.data);
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const renderYear = () => {
    let elementReturn: JSX.Element | JSX.Element[] = [];
    for (let i = currentYear; i >= maximumYear; i--) {
      elementReturn.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return elementReturn;
  };

  const changeFilterYear = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterYear(Number(value));
  };

  const changeFilterMonth = (e: ChangeEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setFilterMonth(Number(value));
  };

  const getTableSalesReport = () => {
    return salesReportList.map((salesReport, index) => {
      return (
        <tr key={salesReport.id}>
          <td>{index + 1}</td>
          <td>{salesReport.invoiceDate}</td>
          <td>{salesReport.invoiceNumber}</td>
          <td>{changeStatusSalesReport(Number(salesReport.status))}</td>
          <td>{salesReport.salesReportNumber}</td>
          <td>{thousandSeparator(salesReport.totalPrice)}</td>
          <td>{thousandSeparator(salesReport.totalComission)}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => viewSalesReport(salesReport.id)}
              >
                View Sales Report
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };
  const renderTableSalesReport = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          salesReportList.length > 0 ? (
            getTableSalesReport()
          ) : (
            <tr>
              <td colSpan={9} style={{ textAlign: "center" }}>
                No Sales Report found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              No Shops found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Tanggal Penjualan Invoice</th>
            <th>Nomor Invoice</th>
            <th>Status</th>
            <th>Nomor Sales Report</th>
            <th>Jumlah Total Penjualan</th>
            <th>Komisi Yang Diterima</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };
  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row>
          <Col xs={12}>Periode Laporan Penjualan:</Col>
        </Row>
        <br />
        <Row>
          <Col xs={6}>
            <Row className="align-items-center d-flex">
              <Col xs={4}>Tahun</Col>
              <Col xs={8}>
                <Form.Select onChange={changeFilterYear} value={filterYear}>
                  {renderYear()}
                </Form.Select>
              </Col>
            </Row>
          </Col>

          <Col xs={6}>
            <Row className="align-items-center d-flex">
              <Col xs={4}>Bulan</Col>
              <Col xs={8}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={changeFilterMonth}
                  value={filterMonth}
                >
                  {MONTH_FULL.filter((dat, index) =>
                    /* Kalau bulan ditahun terpilih belom sampe desember, ya gak usah ditampilin semua */
                    filterYear === currentYear ? index < currentMonth : dat
                  ).map((month, index) => (
                    <option value={index + 1} key={month}>
                      {month}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row>
          <div className="big-box">
            <p>
              <strong>Status Laporan Penjualan</strong>
            </p>
            <hr />
            <Row>
              <Col xs="4">
                <Row>
                  <Col className="d-flex justify-content-center">Approved</Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <p className="amount">{statusComission.approved}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs="4">
                <Row>
                  <Col className="d-flex justify-content-center">
                    Dalam Proses
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <p className="amount">{statusComission.processing}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs="4">
                <Row>
                  <Col className="d-flex justify-content-center">Ditolak</Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <p className="amount">{statusComission.rejected}</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Row>
        <br />
        <FilterDashboardSales />
        <Row>
          <Col>{renderTableSalesReport()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(1));
                    dispatch(loadSalesReportList(filterMonth, filterYear));
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(currentPage - 1));
                    dispatch(loadSalesReportList(filterMonth, filterYear));
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                    type="text"
                    className="pagination-number"
                    value={currentPage}
                    onChange={(e) => changeCurrentPageNumber(e)}
                  /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(currentPage + 1));
                    dispatch(loadSalesReportList(filterMonth, filterYear));
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    dispatch(setSalesReportCurrentPage(totalPage));
                    dispatch(loadSalesReportList(filterMonth, filterYear));
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default ListSalesReport;

export interface SalesReportCreateResponse {
  status: number;
  error?: string;
}

export interface SalesReportProductData {
  count: number;
  id: number;
  productId: number;
  productName: string;
  productBrandName: string;
  productPrice: number;
  productCategory: string;
  productFinishingName: string;
  productColour: string;
  productPromoType: number;
  productArticleNo: string;
}

export interface SalesReportDetailData {
  invoiceNumber: string;
  invoiceDate: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  customerEmail: string;
  attachmentFile: string;
  createdBy: string;
  message: string;
  status: number;
  products: SalesReportProductData[];
}
export interface SalesReportDetailResponse {
  status: number;
  error?: string;
  data: SalesReportDetailData;
}

export interface SalesReportData {
  id: number;
  invoiceNumber: string;
  invoiceDate: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  customerEmail: string;
  attachmentFile: string;
  createdBy: string;
  message: string;
  status: number;
  totalPrice: number;
  totalComission: number;
  salesReportNumber: string;
  products: SalesReportProductData[];
}

export interface SalesReportGetResponse {
  status: number;
  error?: string;
  total_page: number;
  data: SalesReportData[];
}

export interface SalesReportRejectResponse {
  status: number;
  error?: string;
}

export interface SalesReportApproveResponse {
  status: number;
  error?: string;
}

// Filter needs
export enum E_FILTER_STATUS {
  ALL = 0,
  PENDING = 1,
  APPROVED_BY_SUPERVISOR = 2,
  APPROVED_BY_ADMIN = 3,
  REJECTED_BY_SUPERVISOR = 4,
  REJECTED_BY_ADMIN = 5,
}

export interface SalesReportFilterType {
  filterStatus: E_FILTER_STATUS;
  filterSalesReportNumber: string;
}

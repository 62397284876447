import { ChangeEvent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Spinner,
  Pagination,
} from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import FilterComissionCategory from "../../component/FilterComissionCategory";
import NavBar from "../../component/Navbar";
import { E_FETCH_STATUS } from "../../constant";
import { DefaultSelection } from "../../interfaces";
import { ComissionCategoryData } from "../../interfaces/comissioncategory";
import { setUserCurrentPage, loadUserList } from "../../redux/actions/user";
import ComissionCategoryService from "../../services/ComissionCategory";
import checkNumericInput from "../../utils/checkNumericInput";
import thousandSeparator from "../../utils/thousandSeparator";

const ComissionCategory = () => {
  const navigate = useNavigate();

  // Filter State
  const [filterComissionCategoryName, setFilterComissionCategoryName] =
    useState<DefaultSelection>({ label: "All Comission Category", value: 0 });
  //

  // Pagination State
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  //
  const [comissionCategoryList, setComissionCategoryList] = useState<
    ComissionCategoryData[]
  >([]);
  const [
    addModalComissionCategoryShowing,
    setAddModalComissionCategoryShowing,
  ] = useState<boolean>(false);
  const [
    deleteModalComissionCategoryShowing,
    setDeleteModalComissionCategoryShowing,
  ] = useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0);
  const [nameToBeDeleted, setNameToBeDeleted] = useState<string>("");
  const [statusLoading, setStatusLoading] = useState<E_FETCH_STATUS>(
    E_FETCH_STATUS.INITIATE
  );
  const [newComissionCategoryName, setNewComissionCategoryName] =
    useState<string>("");
  const [newComissionCategoryPrice, setNewComissionCategoryPrice] =
    useState<number>(0);

  const changeNewComissionCategoryName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewComissionCategoryName(value);
  };

  const changeNewComissionCategoryPrice = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewComissionCategoryPrice(Number(newValue));
  };

  const getComissionCategoryList = async (
    filterComissionCategoryName?: number
  ) => {
    setStatusLoading(E_FETCH_STATUS.FETCHING);
    let params = `?page_number=${currentPage}`;
    if (filterComissionCategoryName) {
      params += `&category_name=${filterComissionCategoryName}`;
    }
    await ComissionCategoryService.getComissionsCategory(params)
      .then((res) => {
        if (res.status === 1 && res.data) {
          setComissionCategoryList(res.data);
          setTotalPage(res.total_page);
        }
        setStatusLoading(E_FETCH_STATUS.FETCHED);
      })
      .catch((e) => {
        alert(e);
        setStatusLoading(E_FETCH_STATUS.ERROR);
      });
  };

  const addNewComissionCategory = () => {
    const body = new FormData();
    if (newComissionCategoryName === "") {
      alert("Nama kategori komisi tidak boleh kosong!");
      return false;
    }
    body.append("comissionName", newComissionCategoryName);
    body.append("comissionPrice", newComissionCategoryPrice.toString());
    ComissionCategoryService.createNewComissionCategory(body)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully create new comission category");
          window.location.reload();
        }
      })
      .catch((e) => alert(e));
  };

  const deleteComission = async () => {
    await ComissionCategoryService.deleteComissionCategory(idToBeDeleted)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully delete chosen comission category");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };
  const openNewModalComissionCategoryShowing = () => {
    setAddModalComissionCategoryShowing(true);
  };

  const openDeleteComissionCategoryModal = (id: number, name: string) => {
    setDeleteModalComissionCategoryShowing(true);
    setIdToBeDeleted(id);
    setNameToBeDeleted(name);
  };

  const openDetailComissionCategory = (id: number) => {
    navigate(`/comission-category-detail/${id}`);
  };

  const getTableComissionCategory = () => {
    return comissionCategoryList.map((com, index) => {
      return (
        <tr key={com.id}>
          <td>{index + 1}</td>
          <td>{com.comissionCategoryName}</td>
          <td>{`Rp. ${thousandSeparator(com.comissionCategoryPrice)}`}</td>
          <td>
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                onClick={() => openDetailComissionCategory(com.id)}
              >
                Edit Comission
              </Button>
              &nbsp;
              <Button
                variant="danger"
                onClick={() =>
                  openDeleteComissionCategoryModal(
                    com.id,
                    com.comissionCategoryName
                  )
                }
              >
                Delete Comission
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderTableComissionCategory = () => {
    let tableBody: JSX.Element[] | JSX.Element;
    switch (statusLoading) {
      case E_FETCH_STATUS.FETCHING:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              <Spinner animation="border" variant="primary" />
            </td>
          </tr>
        );
        break;
      case E_FETCH_STATUS.FETCHED:
        tableBody =
          comissionCategoryList.length > 0 ? (
            getTableComissionCategory()
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No Comission Category found.
              </td>
            </tr>
          );
        break;
      case E_FETCH_STATUS.ERROR:
        tableBody = (
          <tr>
            <td
              style={{
                textAlign: "center",
              }}
              colSpan={4}
            >
              Error
            </td>
          </tr>
        );
        break;
      default:
        tableBody = (
          <tr>
            <td colSpan={4} style={{ textAlign: "center" }}>
              No Comission Category found.
            </td>
          </tr>
        );
        break;
    }
    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>No</th>
            <th>Comission Category Name</th>
            <th>Comission Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{tableBody}</tbody>
      </Table>
    );
  };

  // Filter Handler
  const handleComissionCategoryNameFilterChange = (
    e: SingleValue<DefaultSelection>
  ) => {
    if (e) setFilterComissionCategoryName(e);
  };

  const applyFilter = () => {
    getComissionCategoryList(filterComissionCategoryName?.value);
  };

  const resetFilter = () => {
    setFilterComissionCategoryName({
      label: "All Comission Category",
      value: 0,
    });
    getComissionCategoryList();
  };

  useEffect(() => {
    getComissionCategoryList();
  }, []);
  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col
            md="2"
            xs="12"
            className="d-flex justify-content-md-end justify-content-center"
          >
            <Button onClick={() => openNewModalComissionCategoryShowing()}>
              <FaPlus /> Create New Comission Category
            </Button>
          </Col>
        </Row>
        <hr />
        <FilterComissionCategory
          comissionCategoryData={comissionCategoryList}
          comissionCategoryName={filterComissionCategoryName}
          handleComissionCategoryNameFilterChange={
            handleComissionCategoryNameFilterChange
          }
          applyFilter={applyFilter}
          resetFilter={resetFilter}
        />
        <br />
        <Row>
          <Col>{renderTableComissionCategory()}</Col>
        </Row>
        {statusLoading === E_FETCH_STATUS.FETCHED && (
          <Row>
            <Col className="pagination">
              <Pagination>
                <Pagination.First
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(1);
                    getComissionCategoryList(
                      filterComissionCategoryName?.value
                    );
                  }}
                />
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                    getComissionCategoryList(
                      filterComissionCategoryName?.value
                    );
                  }}
                />
                <Pagination.Item active>
                  {currentPage}
                  {/* <Form.Control
                  type="text"
                  className="pagination-number"
                  value={currentPage}
                  onChange={(e) => changeCurrentPageNumber(e)}
                /> */}
                </Pagination.Item>
                <Pagination.Next
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                    getComissionCategoryList(
                      filterComissionCategoryName?.value
                    );
                  }}
                />
                <Pagination.Last
                  disabled={currentPage === totalPage}
                  onClick={() => {
                    setCurrentPage(totalPage);
                    getComissionCategoryList(
                      filterComissionCategoryName?.value
                    );
                  }}
                />
              </Pagination>
            </Col>
          </Row>
        )}
      </Container>
      {/* Add Modal */}
      <Modal
        onHide={() => {
          setAddModalComissionCategoryShowing(false);
        }}
        show={addModalComissionCategoryShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Comission Mapping
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={addNewComissionCategory}>
          <Modal.Body>
            <Form.Label>Comission Category Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comission Category Name"
              value={newComissionCategoryName}
              onChange={(e) => changeNewComissionCategoryName(e)}
            />
            <Form.Label>Comission Category Price</Form.Label>
            <Form.Control
              type="text"
              placeholder="Comission Category Price"
              value={newComissionCategoryPrice}
              onChange={(e) => changeNewComissionCategoryPrice(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setAddModalComissionCategoryShowing(false);
              }}
              variant="outline-primary"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                addNewComissionCategory();
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Modal  */}
      <Modal
        onHide={() => {
          setDeleteModalComissionCategoryShowing(false);
        }}
        show={deleteModalComissionCategoryShowing}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Delete comission category {nameToBeDeleted}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this comission?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setDeleteModalComissionCategoryShowing(false);
            }}
            variant="outline-primary"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              deleteComission();
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComissionCategory;

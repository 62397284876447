import { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { E_DISCOUNT_SELECTION } from "../../constant";
import {
  loadKabupatenList,
  loadKecamatanList,
  loadKelurahanList,
  loadProvinsiList,
  loadZipcode,
  setZipcode,
} from "../../redux/actions/daerah";
import { loadBranches } from "../../redux/actions/shop";
import {
  listKabupatenSelector,
  listKecamatanSelector,
  listKelurahanSelector,
  listProvinsiSelector,
  zipcodeSelector,
} from "../../redux/selectors/daerah";
import { branchListSelector } from "../../redux/selectors/shop";
import ShopService from "../../services/Shop";
import checkDecimalInput from "../../utils/checkDecimalInput";
import checkNumericInput from "../../utils/checkNumericInput";
import "./index.scss";

interface ModalAddNewUserProps {
  isShowing: boolean;
  onModalAddShopClose: () => void;
}
const ModalAddNewShop: FC<ModalAddNewUserProps> = (props) => {
  const dispatch = useDispatch();
  const branchesList = useSelector(branchListSelector);
  const provinsiList = useSelector(listProvinsiSelector);
  const kabupatenList = useSelector(listKabupatenSelector);
  const kelurahanList = useSelector(listKelurahanSelector);
  const kecamatanList = useSelector(listKecamatanSelector);
  const zipCode = useSelector(zipcodeSelector);

  const { isShowing, onModalAddShopClose } = props;
  const [newShopName, setNewShopName] = useState<string>("");
  const [newShopOwnerName, setNewShopOwnerName] = useState<string>("");
  const [newShopContact, setNewShopContact] = useState<string>("");
  const [newShopNPWP, setNewShopNPWP] = useState<string>("");
  const [newShopAddress, setNewShopAddress] = useState<string>("");
  const [newShopBranchId, setNewShopBranchId] = useState<number>(0);
  const [newShopDiscountBlancoNormal, setNewShopDiscountBlancoNormal] =
    useState<string>("");
  const [newShopDiscountBlancoPromo, setNewShopDiscountBlancoPromo] =
    useState<string>("");
  const [newShopDiscountCGSNormal, setNewShopDiscountCGSNormal] =
    useState<string>("");
  const [newShopDiscountCGSPromo, setNewShopDiscountCGSPromo] =
    useState<string>("");
  const [newShopDiscountHGNormal, setNewShopDiscountHGNormal] =
    useState<string>("");
  const [newShopDiscountHGPromo, setNewShopDiscountHGPromo] =
    useState<string>("");
  const [newShopProvince, setNewShopProvince] = useState<string>("");
  const [newShopKabupaten, setNewShopKabupaten] = useState<string>("");
  const [newShopKelurahan, setNewShopKelurahan] = useState<string>("");
  const [newShopKecamatan, setNewShopKecamatan] = useState<string>("");

  const createNewShop = () => {
    if (newShopName === "") {
      alert("Please fill the shop name field");
      return false;
    }
    if (newShopOwnerName === "") {
      alert("Please fill the shop owner name field");
      return false;
    }
    if (newShopContact === "") {
      alert("Please fill the shop contact field");
      return false;
    }

    if (newShopProvince === "") {
      alert("Please select the shop province");
      return false;
    }

    if (newShopKabupaten === "") {
      alert("Please select the shop kabupaten");
      return false;
    }

    if (newShopKelurahan === "") {
      alert("Please select the shop kelurahan");
      return false;
    }

    if (newShopKecamatan === "") {
      alert("Please select the shop kecamatan");
      return false;
    }

    if (newShopAddress === "") {
      alert("Please fill the shop address field");
      return false;
    }
    if (newShopNPWP === "") {
      alert("Please fill the shop NPWP field");
      return false;
    }
    const bodyForm = new FormData();
    bodyForm.append("storeName", newShopName);
    bodyForm.append("pemilikToko", newShopOwnerName);
    bodyForm.append("contactToko", newShopContact);
    bodyForm.append("alamatToko", newShopAddress);
    bodyForm.append("npwpToko", newShopNPWP);
    if (newShopBranchId !== 0) {
      bodyForm.append("cabangId", newShopBranchId.toString());
    }
    bodyForm.append(
      "discountBlancoNormal",
      newShopDiscountBlancoNormal.toString()
    );
    bodyForm.append(
      "discountBlancoPromo",
      newShopDiscountBlancoPromo.toString()
    );
    bodyForm.append("discountCGSNormal", newShopDiscountCGSNormal.toString());
    bodyForm.append("discountCGSPromo", newShopDiscountCGSPromo.toString());
    bodyForm.append("discountHGNormal", newShopDiscountHGNormal.toString());
    bodyForm.append("discountHGPromo", newShopDiscountHGPromo.toString());
    bodyForm.append("daerahId", zipCode.id.toString());
    ShopService.createNewShop(bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Sucessfully create new shop");
          window.location.reload();
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const revertNewState = () => {
    setNewShopName("");
    setNewShopOwnerName("");
    setNewShopAddress("");
    setNewShopContact("");
    setNewShopNPWP("");
    setNewShopBranchId(0);
    setNewShopDiscountBlancoNormal("");
    setNewShopDiscountBlancoPromo("");
    setNewShopDiscountCGSNormal("");
    setNewShopDiscountCGSPromo("");
    setNewShopDiscountHGNormal("");
    setNewShopDiscountHGPromo("");
    setNewShopProvince("");
    setNewShopKabupaten("");
    setNewShopKelurahan("");
    setNewShopKecamatan("");
    dispatch(loadProvinsiList());
  };

  const changeNewShopName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopName(value);
  };

  const changeNewShopOwnerName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopOwnerName(value);
  };

  const changeNewShopContact = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewShopContact(newValue);
  };

  const changeNewShopAddress = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopAddress(value);
  };

  const changeNewShopNPWP = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setNewShopNPWP(newValue);
  };

  const changeNewShopBranchId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopBranchId(Number(value));
  };

  const changeProvince = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopProvince(value);
    dispatch(loadKabupatenList(value));
    setNewShopKabupaten("");
    setNewShopKecamatan("");
    setNewShopKelurahan("");
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKabupaten = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopKabupaten(value);
    dispatch(loadKecamatanList(newShopProvince, value));
    setNewShopKecamatan("");
    setNewShopKelurahan("");
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKecamatan = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopKecamatan(value);
    setNewShopKelurahan("");
    dispatch(loadKelurahanList(newShopProvince, newShopKabupaten, value));
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKelurahan = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setNewShopKelurahan(value);
    dispatch(
      loadZipcode(newShopProvince, newShopKabupaten, newShopKecamatan, value)
    );
  };

  const changeShopDiscount = (
    e: ChangeEvent,
    discountType: E_DISCOUNT_SELECTION
  ) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkDecimalInput(value, 100);
    switch (discountType) {
      case E_DISCOUNT_SELECTION.BLANCO_NORMAL:
        setNewShopDiscountBlancoNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.BLANCO_PROMO:
        setNewShopDiscountBlancoPromo(newValue);
        break;
      case E_DISCOUNT_SELECTION.CGS_NORMAL:
        setNewShopDiscountCGSNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.CGS_PROMO:
        setNewShopDiscountCGSPromo(newValue);
        break;
      case E_DISCOUNT_SELECTION.HG_NORMAL:
        setNewShopDiscountHGNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.HG_PROMO:
        setNewShopDiscountHGPromo(newValue);
        break;
      default:
        break;
    }
    // setShopDiscount(Number(newValue));
  };

  useEffect(() => {
    if (isShowing) {
      dispatch(loadBranches());
      dispatch(loadProvinsiList());
    }
  }, [isShowing]);

  return (
    <>
      <Form onSubmit={createNewShop}>
        <Modal
          onHide={() => {
            revertNewState();
            onModalAddShopClose();
          }}
          show={isShowing}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add New Store
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Nama Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Name"
              value={newShopName}
              onChange={(e) => changeNewShopName(e)}
            />
            <Form.Label>Nama Pemilik Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Owner Name"
              value={newShopOwnerName}
              onChange={(e) => changeNewShopOwnerName(e)}
            />
            <Form.Label>Kontak Toko</Form.Label>
            <InputGroup>
              <InputGroup.Text id="basic-addon1">+62</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Shop Contact"
                value={newShopContact}
                onChange={(e) => changeNewShopContact(e)}
              />
            </InputGroup>

            <Form.Label>Provinsi</Form.Label>
            <Form.Select
              value={newShopProvince}
              onChange={(e) => changeProvince(e)}
            >
              <option value={0}>-</option>
              {provinsiList.length > 0 &&
                provinsiList.map((provinsi) => {
                  return (
                    <option value={provinsi} key={provinsi}>
                      {provinsi}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Kotamadya/Kabupaten</Form.Label>
            <Form.Select
              value={newShopKabupaten}
              onChange={(e) => changeKabupaten(e)}
              disabled={newShopProvince === ""}
            >
              <option value={0}>-</option>
              {kabupatenList.length > 0 &&
                kabupatenList.map((kab) => {
                  return (
                    <option value={kab} key={kab}>
                      {kab}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Kecamatan</Form.Label>
            <Form.Select
              value={newShopKecamatan}
              onChange={(e) => changeKecamatan(e)}
              disabled={newShopProvince === "" || newShopKabupaten === ""}
            >
              <option value={0}>-</option>
              {kecamatanList.length > 0 &&
                kecamatanList.map((kec) => {
                  return (
                    <option value={kec} key={kec}>
                      {kec}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Kelurahan</Form.Label>
            <Form.Select
              value={newShopKelurahan}
              onChange={(e) => changeKelurahan(e)}
              disabled={
                newShopProvince === "" ||
                newShopKabupaten === "" ||
                newShopKecamatan === ""
              }
            >
              <option value={0}>-</option>
              {kelurahanList.length > 0 &&
                kelurahanList.map((kel) => {
                  return (
                    <option value={kel} key={kel}>
                      {kel}
                    </option>
                  );
                })}
            </Form.Select>

            <Form.Label>Kode Pos</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={zipCode.kodePos}
              readOnly
            />
            <Form.Label>Alamat Toko</Form.Label>
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder="Shop Address"
              value={newShopAddress}
              onChange={(e) => changeNewShopAddress(e)}
            />
            <Form.Label>NPWP Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Tax Number."
              value={newShopNPWP}
              onChange={(e) => changeNewShopNPWP(e)}
            />
            <Form.Label>Cabang Dari Toko</Form.Label>
            <Form.Select
              value={newShopBranchId}
              onChange={(e) => changeNewShopBranchId(e)}
            >
              <option value={0}>-</option>
              {branchesList.length > 0 &&
                branchesList.map((branch) => {
                  return (
                    <option value={branch.id} key={branch.id}>
                      {branch.storeName}
                    </option>
                  );
                })}
            </Form.Select>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount Blanco Normal</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount Blanco normal"
                    value={newShopDiscountBlancoNormal}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.BLANCO_NORMAL)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount Blanco Promo</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount Blanco Promo"
                    value={newShopDiscountBlancoPromo}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.BLANCO_PROMO)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount CGS Normal</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount CGS normal"
                    value={newShopDiscountCGSNormal}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.CGS_NORMAL)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount CGS Promo</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount CGS Promo Toko"
                    value={newShopDiscountCGSPromo}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.CGS_PROMO)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount HG Normal</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount HG normal"
                    value={newShopDiscountHGNormal}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.HG_NORMAL)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount HG Promo</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Discount HG Promo Toko"
                    value={newShopDiscountHGPromo}
                    onChange={(e) =>
                      changeShopDiscount(e, E_DISCOUNT_SELECTION.HG_PROMO)
                    }
                  />
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                revertNewState();
                onModalAddShopClose();
              }}
              variant="outline-primary"
            >
              Close
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                createNewShop();
              }}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default ModalAddNewShop;

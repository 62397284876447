import { API_URL, BASE_URL } from "../constant";
import {
  BranchGetResponse,
  ShopCreateResponse,
  ShopDeleteResponse,
  ShopDetailResponse,
  ShopEditResponse,
  ShopGetResponse,
  ShopSelectResponse,
} from "../interfaces/shop";
import { getCookie } from "../utils/cookies";

class ShopService {
  public static async getShopList(params?: string): Promise<ShopGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getShopList}${params ? params : ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ShopGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getShopSelectList(): Promise<ShopSelectResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getShopSelectList}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ShopSelectResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getBranchList(id?: number): Promise<BranchGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getBranchList}${id ? `/${id}` : ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: BranchGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createNewShop(
    body: FormData
  ): Promise<ShopCreateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createNewShop}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: ShopCreateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getShopDetail(id: number): Promise<ShopDetailResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getShopDetail}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ShopDetailResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async editShop(
    id: number,
    body: FormData
  ): Promise<ShopEditResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.editShop}/${id}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: ShopEditResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteShop(id: number): Promise<ShopDeleteResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteShop}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: ShopDeleteResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default ShopService;

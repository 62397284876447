import { API_URL, BASE_URL } from "../constant";
import {
  CreateCategoryResponse,
  DeleteCategoryResponse,
  GetCategoryResponse,
  GetCategorySelectResponse,
} from "../interfaces/categories";
import { getCookie } from "../utils/cookies";

class CategoryService {
  public static async getCategory(
    params?: string
  ): Promise<GetCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getCategories}${params ? params : ""}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetCategoryResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getCategorySelect(
    brand: number
  ): Promise<GetCategorySelectResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getCategoriesSelect}${
        brand !== 0 ? `?brand=${brand}` : ``
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetCategoryResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createCategory(
    body: FormData
  ): Promise<CreateCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createCategory}`, {
      method: "POST",
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
      body,
    });

    const response: CreateCategoryResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
  public static async deleteCategory(
    id: number
  ): Promise<DeleteCategoryResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteCategory}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DeleteCategoryResponse = await fetchResponse.json();
    if (response.error) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default CategoryService;

import { ChangeEvent, FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { ShopData } from "../../interfaces/shop";
import ShopService from "../../services/Shop";

interface FilterStoreProps {
  storeName: DefaultSelection;
  storeOwnerName: string;
  storeNPWP: string;
  handleStoreNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  handleStoreOwnerNameFilterChange: (e: ChangeEvent) => void;
  handleStoreNPWPFilterChange: (e: ChangeEvent) => void;
  applyFilter: () => void;
  resetFilter: () => void;
}

const FilterStore: FC<FilterStoreProps> = (props) => {
  const {
    storeName,
    storeOwnerName,
    storeNPWP,
    handleStoreNameFilterChange,
    handleStoreOwnerNameFilterChange,
    handleStoreNPWPFilterChange,
    applyFilter,
    resetFilter,
  } = props;
  const [storeList, setStoreList] = useState<DefaultSelection[]>([]);

  const getStoreList = async () => {
    await ShopService.getShopList().then((res) => {
      if (res.data) {
        const storeistTemp: DefaultSelection[] = res.data.map((store) => {
          return {
            value: store.id,
            label: store.storeName,
          };
        });
        setStoreList(storeistTemp);
      }
    });
  };
  useEffect(() => {
    getStoreList();
  }, []);
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Toko</Form.Label>
              <Select
                options={storeList}
                value={storeName}
                onChange={handleStoreNameFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Pemilik Toko</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nama Pemilik Toko"
                value={storeOwnerName}
                onChange={handleStoreOwnerNameFilterChange}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>NNPWP Toko</Form.Label>
              <Form.Control
                type="text"
                placeholder="NPWP Toko"
                value={storeNPWP}
                onChange={handleStoreNPWPFilterChange}
              />
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 4, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterStore;

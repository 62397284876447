import React, { FC } from "react";

interface UserDetailReportInterface {
  userId: string;
}

const UserDetailReport: FC<UserDetailReportInterface> = (props) => {
  return (
    <>
      <p>test</p>
    </>
  );
};

export default UserDetailReport;

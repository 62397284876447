import { FC, useEffect, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { DefaultSelection } from "../../interfaces";
import { BrandData } from "../../interfaces/brand";
import BrandService from "../../services/Brand";

interface FilterBrandProps {
  brandName: DefaultSelection;
  handleBrandNameFilterChange: (e: SingleValue<DefaultSelection>) => void;
  applyFilter: () => void;
  resetFilter: () => void;
}

const FilterBrand: FC<FilterBrandProps> = (props) => {
  const { brandName, handleBrandNameFilterChange, applyFilter, resetFilter } =
    props;
  const [brandList, setBrandList] = useState<DefaultSelection[]>([]);

  const getBrandData = async () => {
    await BrandService.getBrand().then((res) => {
      if (res.data) {
        const brandListTemp: DefaultSelection[] = res.data.map((brand) => {
          return {
            label: brand.name,
            value: brand.id,
          };
        });
        setBrandList(brandListTemp);
      }
    });
  };
  useEffect(() => {
    getBrandData();
  }, []);
  return (
    <>
      <Form autoComplete="off">
        <Row>
          <Col md={4}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nama Brand</Form.Label>
              <Select
                options={brandList}
                value={brandName}
                onChange={handleBrandNameFilterChange}
              />
            </Form.Group>
          </Col>

          <Col
            md={{ offset: 6, span: 2 }}
            xs={12}
            className="d-flex align-items-end btn-action mt-2 mt-md-0"
          >
            <Button
              variant="outline-primary"
              onClick={resetFilter}
              className="flex-grow-1"
            >
              Reset
            </Button>
            &nbsp;
            <Button
              className="flex-grow-1"
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                applyFilter();
              }}
              type="submit"
            >
              Apply
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FilterBrand;

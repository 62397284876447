import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ShopService from "../../services/Shop";
import { useParams } from "react-router-dom";
import NavBar from "../../component/Navbar";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import checkNumericInput from "../../utils/checkNumericInput";
import { loadBranches } from "../../redux/actions/shop";
import { branchListSelector } from "../../redux/selectors/shop";
import { E_DISCOUNT_SELECTION } from "../../constant";
import "./index.scss";
import {
  loadKabupatenList,
  loadKecamatanList,
  loadKelurahanList,
  loadProvinsiList,
  loadZipcode,
  setZipcode,
} from "../../redux/actions/daerah";
import {
  listKabupatenSelector,
  listKecamatanSelector,
  listKelurahanSelector,
  listProvinsiSelector,
  zipcodeSelector,
} from "../../redux/selectors/daerah";
import checkDecimalInput from "../../utils/checkDecimalInput";

const ShopDetail: FC = () => {
  let { idShop } = useParams();
  const dispatch = useDispatch();
  const branchesList = useSelector(branchListSelector);
  const provinsiList = useSelector(listProvinsiSelector);
  const kabupatenList = useSelector(listKabupatenSelector);
  const kelurahanList = useSelector(listKelurahanSelector);
  const kecamatanList = useSelector(listKecamatanSelector);
  const zipCode = useSelector(zipcodeSelector);

  // For View
  const [shopName, setShopName] = useState<string>("");
  const [shopOwnerName, setShopOwnerName] = useState<string>("");
  const [shopContact, setShopContact] = useState<string>("");
  const [shopNPWP, setShopNPWP] = useState<string>("");
  const [shopAddress, setShopAddress] = useState<string>("");
  const [shopBranchId, setShopBranchId] = useState<number>(0);
  const [shopDiscountBlancoNormal, setShopDiscountBlancoNormal] =
    useState<string>("");
  const [shopDiscountBlancoPromo, setShopDiscountBlancoPromo] =
    useState<string>("");
  const [shopDiscountCGSNormal, setShopDiscountCGSNormal] =
    useState<string>("");
  const [shopDiscountCGSPromo, setShopDiscountCGSPromo] = useState<string>("");
  const [shopDiscountHGNormal, setShopDiscountHGNormal] = useState<string>("");
  const [shopDiscountHGPromo, setShopDiscountHGPromo] = useState<string>("");
  const [shopProvince, setShopProvince] = useState<string>("");
  const [shopKabupaten, setShopKabupaten] = useState<string>("");
  const [shopKelurahan, setShopKelurahan] = useState<string>("");
  const [shopKecamatan, setShopKecamatan] = useState<string>("");
  //

  // For Edit
  const [editShopName, setEditShopName] = useState<string>("");
  const [editShopOwnerName, setEditShopOwnerName] = useState<string>("");
  const [editShopContact, setEditShopContact] = useState<string>("");
  const [editShopNPWP, setEditShopNPWP] = useState<string>("");
  const [editShopAddress, setEditShopAddress] = useState<string>("");
  const [editShopBranchId, setEditShopBranchId] = useState<number>(0);
  const [editShopDiscountBlancoNormal, setEditShopDiscountBlancoNormal] =
    useState<string>("");
  const [editShopDiscountBlancoPromo, setEditShopDiscountBlancoPromo] =
    useState<string>("");
  const [editShopDiscountCGSNormal, setEditShopDiscountCGSNormal] =
    useState<string>("");
  const [editShopDiscountCGSPromo, setEditShopDiscountCGSPromo] =
    useState<string>("");
  const [editShopDiscountHGNormal, setEditShopDiscountHGNormal] =
    useState<string>("");
  const [editShopDiscountHGPromo, setEditShopDiscountHGPromo] =
    useState<string>("");
  const [editShopProvince, setEditShopProvince] = useState<string>("");
  const [editShopKabupaten, setEditShopKabupaten] = useState<string>("");
  const [editShopKelurahan, setEditShopKelurahan] = useState<string>("");
  const [editShopKecamatan, setEditShopKecamatan] = useState<string>("");
  // const [editShopZipcode, setEditShopZipcode] = useState<string>("");
  //

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const printPage = () => {
    window.print();
  };

  const revertEditState = () => {
    setEditShopName(shopName);
    setEditShopOwnerName(shopOwnerName);
    setEditShopAddress(shopAddress);
    setEditShopContact(shopContact);
    setEditShopNPWP(shopNPWP);
    setEditShopBranchId(shopBranchId);
    setEditShopDiscountBlancoNormal(shopDiscountBlancoNormal);
    setEditShopDiscountBlancoPromo(shopDiscountBlancoPromo);
    setEditShopDiscountCGSNormal(shopDiscountCGSNormal);
    setEditShopDiscountCGSPromo(shopDiscountCGSPromo);
    setEditShopDiscountHGNormal(shopDiscountHGNormal);
    setEditShopDiscountHGPromo(shopDiscountHGPromo);
    setEditShopProvince(shopProvince);
    setEditShopKabupaten(shopKabupaten);
    setEditShopKelurahan(shopKelurahan);
    setEditShopKecamatan(shopKecamatan);
    dispatch(loadProvinsiList());
    dispatch(loadKabupatenList(shopProvince));
    dispatch(loadKecamatanList(shopProvince, shopKabupaten));
    dispatch(loadKelurahanList(shopProvince, shopKabupaten, shopKecamatan));
  };

  const cancelEdit = () => {
    revertEditState();
    setIsEditing(false);
  };

  const saveEditedData = () => {
    if (editShopName === "") {
      alert("Please fill the shop name field");
      return false;
    }
    if (editShopOwnerName === "") {
      alert("Please fill the shop owner name field");
      return false;
    }
    if (editShopContact === "") {
      alert("Please fill the shop contact field");
      return false;
    }

    if (editShopProvince === "") {
      alert("Please select the shop province");
      return false;
    }

    if (editShopKabupaten === "") {
      alert("Please select the shop kabupaten");
      return false;
    }

    if (editShopKelurahan === "") {
      alert("Please select the shop kelurahan");
      return false;
    }

    if (editShopKecamatan === "") {
      alert("Please select the shop kecamatan");
      return false;
    }

    if (editShopAddress === "") {
      alert("Please fill the shop address field");
      return false;
    }
    if (editShopNPWP === "") {
      alert("Please fill the shop NPWP field");
      return false;
    }

    const bodyForm = new FormData();
    bodyForm.append("storeName", shopName);
    bodyForm.append("pemilikToko", shopOwnerName);
    bodyForm.append("contactToko", shopContact);
    bodyForm.append("alamatToko", shopAddress);
    bodyForm.append("npwpToko", shopNPWP);
    if (shopBranchId !== 0) {
      bodyForm.append("cabangId", shopBranchId.toString());
    }
    bodyForm.append(
      "discountBlancoNormal",
      editShopDiscountBlancoNormal.toString()
    );
    bodyForm.append(
      "discountBlancoPromo",
      editShopDiscountBlancoPromo.toString()
    );
    bodyForm.append("discountCGSNormal", editShopDiscountCGSNormal.toString());
    bodyForm.append("discountCGSPromo", editShopDiscountCGSPromo.toString());
    bodyForm.append("discountHGNormal", editShopDiscountHGNormal.toString());
    bodyForm.append("discountHGPromo", editShopDiscountHGPromo.toString());
    bodyForm.append("daerahId", zipCode.id.toString());

    ShopService.editShop(idShop ? Number(idShop) : 0, bodyForm).then((res) => {
      if (res.status === 1) {
        alert("Shop has been sucessfully edited");
        window.location.reload();
      }
    });
  };

  useEffect(() => {
    ShopService.getShopDetail(Number(idShop)).then((res) => {
      if (res.data) {
        // For View
        setShopName(res.data.storeName);
        setShopOwnerName(res.data.pemilikToko);
        setShopAddress(res.data.alamatToko);
        setShopContact(res.data.contactToko);
        setShopNPWP(res.data.npwpToko);
        if (res.data.cabangId) {
          setShopBranchId(res.data.cabangId);
        } else {
          setShopBranchId(0);
        }
        setShopProvince(res.data.detailAlamat.provinsi);
        setShopKabupaten(res.data.detailAlamat.kabupaten);
        setShopKelurahan(res.data.detailAlamat.kelurahan);
        setShopKecamatan(res.data.detailAlamat.kecamatan);
        dispatch(
          setZipcode({
            id: res.data.detailAlamat.id,
            kodePos: res.data.detailAlamat.kodePos,
          })
        );
        setShopDiscountBlancoNormal(res.data.discountBlancoNormal);
        setShopDiscountBlancoPromo(res.data.discountBlancoPromo);
        setShopDiscountCGSNormal(res.data.discountCGSNormal);
        setShopDiscountCGSPromo(res.data.discountCGSPromo);
        setShopDiscountHGNormal(res.data.discountHGNormal);
        setShopDiscountHGPromo(res.data.discountHGPromo);
        //

        // For Edit
        setEditShopName(res.data.storeName);
        setEditShopOwnerName(res.data.pemilikToko);
        setEditShopAddress(res.data.alamatToko);
        setEditShopContact(res.data.contactToko);
        setEditShopNPWP(res.data.npwpToko);
        if (res.data.cabangId) {
          setEditShopBranchId(res.data.cabangId);
        } else {
          setEditShopBranchId(0);
        }
        setEditShopProvince(res.data.detailAlamat.provinsi);
        setEditShopKabupaten(res.data.detailAlamat.kabupaten);
        setEditShopKelurahan(res.data.detailAlamat.kelurahan);
        setEditShopKecamatan(res.data.detailAlamat.kecamatan);
        setEditShopDiscountBlancoNormal(res.data.discountBlancoNormal);
        setEditShopDiscountBlancoPromo(res.data.discountBlancoPromo);
        setEditShopDiscountCGSNormal(res.data.discountCGSNormal);
        setEditShopDiscountCGSPromo(res.data.discountCGSPromo);
        setEditShopDiscountHGNormal(res.data.discountHGNormal);
        setEditShopDiscountHGPromo(res.data.discountHGPromo);
        //

        dispatch(loadProvinsiList());
        dispatch(loadKabupatenList(res.data.detailAlamat.provinsi));
        dispatch(
          loadKecamatanList(
            res.data.detailAlamat.provinsi,
            res.data.detailAlamat.kabupaten
          )
        );
        dispatch(
          loadKelurahanList(
            res.data.detailAlamat.provinsi,
            res.data.detailAlamat.kabupaten,
            res.data.detailAlamat.kecamatan
          )
        );
      }
    });
    dispatch(loadBranches(Number(idShop)));
  }, [idShop]);

  const changeShopName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopName(value);
  };

  const changeShopOwnerName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopName(value);
  };

  const changeShopContact = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditShopContact(newValue);
  };

  const changeShopAddress = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopAddress(value);
  };

  const changeShopNPWP = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setEditShopNPWP(newValue);
  };

  const changeShopBranchId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopBranchId(Number(value));
  };

  const changeProvince = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopProvince(value);
    dispatch(loadKabupatenList(value));
    setEditShopKabupaten("");
    setEditShopKelurahan("");
    setEditShopKecamatan("");
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKabupaten = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopKabupaten(value);
    dispatch(loadKecamatanList(editShopProvince, value));
    setEditShopKecamatan("");
    setEditShopKelurahan("");
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKecamatan = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopKecamatan(value);
    setEditShopKelurahan("");
    dispatch(loadKelurahanList(editShopProvince, editShopKabupaten, value));
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeKelurahan = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setEditShopKelurahan(value);
    dispatch(
      loadZipcode(editShopProvince, editShopKabupaten, editShopKecamatan, value)
    );
    dispatch(setZipcode({ id: 0, kodePos: "" }));
  };

  const changeShopDiscount = (
    e: ChangeEvent,
    discountType: E_DISCOUNT_SELECTION
  ) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkDecimalInput(value, 100);
    switch (discountType) {
      case E_DISCOUNT_SELECTION.BLANCO_NORMAL:
        setEditShopDiscountBlancoNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.BLANCO_PROMO:
        setEditShopDiscountBlancoPromo(newValue);
        break;
      case E_DISCOUNT_SELECTION.CGS_NORMAL:
        setEditShopDiscountCGSNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.CGS_PROMO:
        setEditShopDiscountCGSPromo(newValue);
        break;
      case E_DISCOUNT_SELECTION.HG_NORMAL:
        setEditShopDiscountHGNormal(newValue);
        break;
      case E_DISCOUNT_SELECTION.HG_PROMO:
        setEditShopDiscountHGPromo(newValue);
        break;
      default:
        break;
    }
    // setShopDiscount(Number(newValue));
  };

  return (
    <>
      <NavBar customBrand={"Informasi Toko"} />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            {!isEditing && (
              <>
                <Button
                  onClick={() => setIsEditing(true)}
                  className="notPrinted"
                >
                  Edit Shop Data
                </Button>
                &nbsp;
                <Button onClick={printPage} className="notPrinted">
                  Print Data
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={() => cancelEdit()} variant="outline-primary">
                  Cancel
                </Button>
                &nbsp;
                <Button onClick={saveEditedData}>Save</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Form>
            <Form.Label>Nama Toko</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                placeholder="Shop Name"
                value={shopName}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Shop Name"
                value={editShopName}
                onChange={(e) => changeShopName(e)}
              />
            )}
            <Form.Label>Nama Pemilik Toko</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                placeholder="Shop Owner Name"
                value={shopOwnerName}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Shop Owner Name"
                value={editShopOwnerName}
                onChange={(e) => changeShopOwnerName(e)}
              />
            )}
            <Form.Label>Kontak Toko</Form.Label>
            {!isEditing ? (
              <InputGroup>
                <InputGroup.Text>+62</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Shop Contact"
                  value={shopContact}
                  readOnly
                />
              </InputGroup>
            ) : (
              <InputGroup>
                <InputGroup.Text>+62</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Shop Contact"
                  value={editShopContact}
                  onChange={(e) => changeShopContact(e)}
                />
              </InputGroup>
            )}
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Provinsi</Form.Label>
                {!isEditing ? (
                  <Form.Select value={shopProvince} disabled>
                    <option value={0}>-</option>
                    {provinsiList.length > 0 &&
                      provinsiList.map((provinsi) => {
                        return (
                          <option value={provinsi} key={provinsi}>
                            {provinsi}
                          </option>
                        );
                      })}
                  </Form.Select>
                ) : (
                  <Form.Select
                    value={editShopProvince}
                    onChange={(e) => changeProvince(e)}
                  >
                    <option value={0}>-</option>
                    {provinsiList.length > 0 &&
                      provinsiList.map((provinsi) => {
                        return (
                          <option value={provinsi} key={provinsi}>
                            {provinsi}
                          </option>
                        );
                      })}
                  </Form.Select>
                )}
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Kotamadya/Kabupaten</Form.Label>
                {!isEditing ? (
                  <Form.Select value={shopKabupaten} disabled>
                    <option value={0}>-</option>
                    {kabupatenList.length > 0 &&
                      kabupatenList.map((kab) => {
                        return (
                          <option value={kab} key={kab}>
                            {kab}
                          </option>
                        );
                      })}
                  </Form.Select>
                ) : (
                  <Form.Select
                    value={editShopKabupaten}
                    onChange={(e) => changeKabupaten(e)}
                    disabled={editShopProvince === ""}
                  >
                    <option value={0}>-</option>
                    {kabupatenList.length > 0 &&
                      kabupatenList.map((kab) => {
                        return (
                          <option value={kab} key={kab}>
                            {kab}
                          </option>
                        );
                      })}
                  </Form.Select>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Kecamatan</Form.Label>
                {!isEditing ? (
                  <Form.Select value={shopKecamatan} disabled>
                    <option value={0}>-</option>
                    {kecamatanList.length > 0 &&
                      kecamatanList.map((kec) => {
                        return (
                          <option value={kec} key={kec}>
                            {kec}
                          </option>
                        );
                      })}
                  </Form.Select>
                ) : (
                  <Form.Select
                    value={editShopKecamatan}
                    onChange={(e) => changeKecamatan(e)}
                    disabled={
                      editShopProvince === "" || editShopKabupaten === ""
                    }
                  >
                    <option value={0}>-</option>
                    {kecamatanList.length > 0 &&
                      kecamatanList.map((kec) => {
                        return (
                          <option value={kec} key={kec}>
                            {kec}
                          </option>
                        );
                      })}
                  </Form.Select>
                )}
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Kelurahan</Form.Label>
                {!isEditing ? (
                  <Form.Select value={shopKelurahan} disabled>
                    <option value={0}>-</option>
                    {kelurahanList.length > 0 &&
                      kelurahanList.map((kel) => {
                        return (
                          <option value={kel} key={kel}>
                            {kel}
                          </option>
                        );
                      })}
                  </Form.Select>
                ) : (
                  <Form.Select
                    value={editShopKelurahan}
                    onChange={(e) => changeKelurahan(e)}
                    disabled={
                      editShopProvince === "" ||
                      editShopKabupaten === "" ||
                      editShopKecamatan === ""
                    }
                  >
                    <option value={0}>-</option>
                    {kelurahanList.length > 0 &&
                      kelurahanList.map((kel) => {
                        return (
                          <option value={kel} key={kel}>
                            {kel}
                          </option>
                        );
                      })}
                  </Form.Select>
                )}
              </Col>
            </Row>
            <Form.Label>Kode Pos</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={zipCode.kodePos}
              readOnly
            />
            <Form.Label>Alamat Toko</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                as={"textarea"}
                placeholder="Shop Address"
                value={shopAddress}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                as={"textarea"}
                placeholder="Shop Address"
                value={editShopAddress}
                onChange={(e) => changeShopAddress(e)}
              />
            )}
            <Form.Label>NPWP Toko</Form.Label>
            {!isEditing ? (
              <Form.Control
                type="text"
                placeholder="Shop Tax Number."
                value={shopNPWP}
                readOnly
              />
            ) : (
              <Form.Control
                type="text"
                placeholder="Shop Tax Number."
                value={editShopNPWP}
                onChange={(e) => changeShopNPWP(e)}
              />
            )}
            <Form.Label>Cabang Dari Toko</Form.Label>
            {!isEditing ? (
              <Form.Select value={shopBranchId} disabled>
                <option value={0}>-</option>
                {branchesList.length > 0 &&
                  branchesList.map((shop) => {
                    return (
                      <option value={shop.id} key={shop.id}>
                        {shop.storeName}
                      </option>
                    );
                  })}
              </Form.Select>
            ) : (
              <Form.Select
                value={editShopBranchId}
                onChange={(e) => changeShopBranchId(e)}
              >
                <option value={0}>-</option>
                {branchesList.length > 0 &&
                  branchesList.map((shop) => {
                    return (
                      <option value={shop.id} key={shop.id}>
                        {shop.storeName}
                      </option>
                    );
                  })}
              </Form.Select>
            )}
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount Blanco Normal</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount"
                      readOnly
                      value={shopDiscountBlancoNormal}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount Blanco normal"
                      value={editShopDiscountBlancoNormal}
                      onChange={(e) =>
                        changeShopDiscount(
                          e,
                          E_DISCOUNT_SELECTION.BLANCO_NORMAL
                        )
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount Blanco Promo</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount Blanco Promo"
                      readOnly
                      value={shopDiscountBlancoPromo}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount Blanco Promo"
                      value={editShopDiscountBlancoPromo}
                      onChange={(e) =>
                        changeShopDiscount(e, E_DISCOUNT_SELECTION.BLANCO_PROMO)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount CGS Normal</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount"
                      readOnly
                      value={shopDiscountCGSNormal}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount"
                      value={editShopDiscountCGSNormal}
                      onChange={(e) =>
                        changeShopDiscount(e, E_DISCOUNT_SELECTION.CGS_NORMAL)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount CGS Promo</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount CGS Promo Toko"
                      readOnly
                      value={shopDiscountCGSPromo}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount CGS Promo Toko"
                      value={editShopDiscountCGSPromo}
                      onChange={(e) =>
                        changeShopDiscount(e, E_DISCOUNT_SELECTION.CGS_PROMO)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
            </Row>
            <Row>
              <Col md="6" xs="6">
                <Form.Label>Discount Hansgrohe Normal</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount"
                      readOnly
                      value={shopDiscountHGNormal}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount"
                      value={editShopDiscountHGNormal}
                      onChange={(e) =>
                        changeShopDiscount(e, E_DISCOUNT_SELECTION.HG_NORMAL)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
              <Col md="6" xs="6">
                <Form.Label>Discount Hansgrohe Promo</Form.Label>
                {!isEditing ? (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount Hansgrohe Promo Toko"
                      readOnly
                      value={shopDiscountHGPromo}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Discount Hansgrohe Promo Toko"
                      value={editShopDiscountHGPromo}
                      onChange={(e) =>
                        changeShopDiscount(e, E_DISCOUNT_SELECTION.HG_PROMO)
                      }
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                )}
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>
      <br />
      <style>
        {`
          @media print {
            @page {
              margin: 0;
            }
            .notPrinted {
              display: none;
            }
            body {
              -webkit-print-color-adjust: exact;
            }
          }
        `}
      </style>
    </>
  );
};

export default ShopDetail;

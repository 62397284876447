import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { E_FETCH_STATUS } from "../../constant";
import { ColourData, GetColourResponse } from "../../interfaces/colour";
import ColourService from "../../services/Colour";
import { AppStateType } from "../reducers";
import { stores } from "../stores";
import { ColourType } from "../types/colour";

export const setColourList = (colourList: ColourData[]) => {
  return {
    type: ColourType.COLOUR_SET_LIST,
    payload: colourList,
  };
};

export const setColourStatusLoading = (statusLoading: E_FETCH_STATUS) => {
  return {
    type: ColourType.COLOUR_SET_STATUS_LOADING,
    payload: statusLoading,
  };
};

export const setColourCurrentPage = (currentPage: number) => {
  return {
    type: ColourType.COLOUR_SET_CURRENT_PAGE,
    payload: currentPage,
  };
};

export const setColourTotalPage = (totalPage: number) => {
  return {
    type: ColourType.COLOUR_SET_TOTAL_PAGE,
    payload: totalPage,
  };
};

export const loadColourList = (
  filterFinishing?: number,
  filterColour?: number
) => {
  return async (dispatch: ThunkDispatch<AppStateType, null, AnyAction>) => {
    try {
      dispatch(setColourStatusLoading(E_FETCH_STATUS.FETCHING));
      const reduxState = stores.getState();
      const {
        colour: { currentPage },
      } = reduxState;
      let params = `?page_number=${currentPage}`;
      if (filterFinishing) {
        params += `&finishingId=${filterFinishing}`;
      }
      if (filterColour) {
        params += `&colourId=${filterColour}`;
      }
      const res: GetColourResponse = await ColourService.getColour(params);
      if (res.data) {
        dispatch(setColourList(res.data));
        dispatch(setColourTotalPage(res.total_page));
        dispatch(setColourStatusLoading(E_FETCH_STATUS.FETCHED));
      } else {
        dispatch(setColourStatusLoading(E_FETCH_STATUS.ERROR));
      }
    } catch (e: any) {
      dispatch(setColourStatusLoading(E_FETCH_STATUS.ERROR));
      // throw new Error(e);
    }
  };
};

import React, { FC, Fragment } from "react";
import { Form } from "react-bootstrap";
import { PROMO_TYPE } from "../../constant";
import thousandSeparator from "../../utils/thousandSeparator";
import "./index.scss";

interface RepetitiveDetailSalesReportProps {
  id: number;
  index: number;
  productName: string;
  brand: string;
  categoryName: string;
  price: number;
  promoType: number;
  articleNo: string;
  count: number;
}

const RepetitiveDetailSalesReport: FC<RepetitiveDetailSalesReportProps> = ({
  id,
  index,
  promoType,
  brand,
  productName,
  categoryName,
  articleNo,
  price,
  count,
}) => {
  return (
    <Fragment key={id}>
      <div className="repetitive-product">
        <Form.Label>Brand Produk #{index + 1}</Form.Label>
        <Form.Control type="text" readOnly value={brand} />
        <Form.Label>Tipe Produk #{index + 1}</Form.Label>
        <Form.Control
          type="text"
          readOnly
          value={PROMO_TYPE[promoType]}
        />
        <Form.Label>Kategori Produk #{index + 1}</Form.Label>
        <Form.Control type="text" readOnly value={categoryName} />
        <Form.Label>Nomor Artikel Produk #{index + 1}</Form.Label>
        <Form.Control type="text" readOnly value={articleNo} />
        <Form.Label>Nama Produk #{index + 1}</Form.Label>
        <Form.Control type="text" readOnly value={productName} />
        <Form.Label>Harga Satuan Produk #{index + 1}</Form.Label>
        <Form.Control
          type="text"
          readOnly
          value={`Rp ${thousandSeparator(price)}`}
        />
        <Form.Label>Jumlah Produk #{index + 1}</Form.Label>
        <Form.Control type="text" readOnly value={count} />
      </div>
    </Fragment>
  );
};

export default RepetitiveDetailSalesReport;

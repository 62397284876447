import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NEW_USER_STATUS } from "../../constant";
import { UserData } from "../../interfaces/user";
import { loadBranches } from "../../redux/actions/shop";
import {
  branchListSelector,
  shopListSelector,
} from "../../redux/selectors/shop";
import ShopService from "../../services/Shop";
import UserService from "../../services/User";
import checkNumericInput from "../../utils/checkNumericInput";
import checkNumericInputNumber from "../../utils/checkNumericInputNumber";

interface ModalEditShopProps {
  isShowing: boolean;
  onModalEditShopClose: () => void;
  idEdited: number;
}
const ModalEditShop: FC<ModalEditShopProps> = (props) => {
  const dispatch = useDispatch();
  const branchesList = useSelector(branchListSelector);
  const { isShowing, onModalEditShopClose, idEdited } = props;
  const [shopName, setShopName] = useState<string>("");
  const [shopOwnerName, setShopOwnerName] = useState<string>("");
  const [shopContact, setShopContact] = useState<string>("");
  const [shopNPWP, setShopNPWP] = useState<string>("");
  const [shopAddress, setShopAddress] = useState<string>("");
  const [shopBranchId, setShopBranchId] = useState<number>(0);
  const [shopDiscount, setShopDiscount] = useState<number>(0);
  const editShop = () => {
    if (shopName === "") {
      alert("Please fill the shop name field");
      return false;
    }
    if (shopOwnerName === "") {
      alert("Please fill the shop owner name field");
      return false;
    }
    if (shopContact === "") {
      alert("Please fill the shop contact field");
      return false;
    }
    if (shopAddress === "") {
      alert("Please fill the shop address field");
      return false;
    }
    if (shopNPWP === "") {
      alert("Please fill the shop NPWP field");
      return false;
    }
    const bodyForm = new FormData();
    bodyForm.append("storeName", shopName);
    bodyForm.append("pemilikToko", shopOwnerName);
    bodyForm.append("contactToko", shopContact);
    bodyForm.append("alamatToko", shopAddress);
    bodyForm.append("npwpToko", shopNPWP);
    if (shopBranchId !== 0) {
      bodyForm.append("cabangId", shopBranchId.toString());
    }
    bodyForm.append("discountToko", shopDiscount.toString());
    ShopService.editShop(idEdited, bodyForm).then((res) => {
      if (res.status == 1) {
        alert("Shop has been sucessfully edited");
        window.location.reload();
      }
    });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (idEdited !== 0) {
      ShopService.getShopDetail(idEdited).then((res) => {
        if (res.data) {
          setShopName(res.data.storeName);
          setShopOwnerName(res.data.pemilikToko);
          setShopAddress(res.data.alamatToko);
          setShopContact(res.data.contactToko);
          setShopNPWP(res.data.npwpToko);
          if (res.data.cabangId) {
            setShopBranchId(res.data.cabangId);
          } else {
            setShopBranchId(0);
          }
          // setShopDiscount(res.data.discountToko);
        }
      });
      dispatch(loadBranches(idEdited));
    }
  }, [idEdited]);

  const changeShopName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setShopName(value);
  };

  const changeShopOwnerName = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setShopName(value);
  };

  const changeShopContact = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setShopContact(newValue);
  };

  const changeShopAddress = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setShopAddress(value);
  };

  const changeShopNPWP = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    setShopNPWP(newValue);
  };

  const changeShopBranchId = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setShopBranchId(Number(value));
  };

  const changeShopDiscount = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInputNumber(value, 100);
    setShopDiscount(Number(newValue));
  };

  return (
    <>
      <Form onSubmit={editShop}>
        <Modal
          onHide={onModalEditShopClose}
          show={isShowing}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Shop
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Nama Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Name"
              value={shopName}
              onChange={(e) => changeShopName(e)}
            />
            <Form.Label>Nama Pemilik Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Owner Name"
              value={shopOwnerName}
              onChange={(e) => changeShopOwnerName(e)}
            />
            <Form.Label>Kontak Toko</Form.Label>
            <InputGroup>
              <InputGroup.Text>+62</InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Shop Contact"
                value={shopContact}
                onChange={(e) => changeShopContact(e)}
              />
            </InputGroup>
            <Form.Label>Alamat Toko</Form.Label>
            <Form.Control
              type="text"
              as={"textarea"}
              placeholder="Shop Address"
              value={shopAddress}
              onChange={(e) => changeShopAddress(e)}
            />
            <Form.Label>NPWP Toko</Form.Label>
            <Form.Control
              type="text"
              placeholder="Shop Tax Number."
              value={shopNPWP}
              onChange={(e) => changeShopNPWP(e)}
            />
            <Form.Label>Cabang Dari Toko</Form.Label>
            <Form.Select
              value={shopBranchId}
              onChange={(e) => changeShopBranchId(e)}
            >
              <option value={0}>-</option>
              {branchesList.length > 0 &&
                branchesList.map((shop) => {
                  return (
                    <option value={shop.id} key={shop.id}>
                      {shop.storeName}
                    </option>
                  );
                })}
            </Form.Select>
            <Form.Label>Discount Toko</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Discount"
                value={shopDiscount}
                onChange={(e) => changeShopDiscount(e)}
              />
              <InputGroup.Text>%</InputGroup.Text>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onModalEditShopClose} variant="outline-primary">
              Close
            </Button>
            <Button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                editShop();
              }}
            >
              Edit
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default ModalEditShop;

import { E_FETCH_STATUS } from "../../constant";
import { CategoryData } from "../../interfaces/categories";

export enum CategoryType {
  CATEGORY_SET_LIST = "CATEGORY_SET_LIST",
  CATEGORY_SET_STATUS_LOADING = "CATEGORY_SET_STATUS_LOADING",
  CATEGORY_SET_CURRENT_PAGE = "CATEGORY_SET_CURRENT_PAGE",
  CATEGORY_SET_TOTAL_PAGE = "CATEGORY_SET_TOTAL_PAGE",
}

export interface CategorySetList {
  type: CategoryType.CATEGORY_SET_LIST;
  payload: CategoryData[];
}

export interface CategorySetStatusLoading {
  type: CategoryType.CATEGORY_SET_STATUS_LOADING;
  payload: E_FETCH_STATUS;
}

export interface CategorySetCurrentPage {
  type: CategoryType.CATEGORY_SET_CURRENT_PAGE;
  payload: number;
}

export interface CategorySetTotalPage {
  type: CategoryType.CATEGORY_SET_TOTAL_PAGE;
  payload: number;
}

export type CategoryAction =
  | CategorySetList
  | CategorySetStatusLoading
  | CategorySetCurrentPage
  | CategorySetTotalPage;

import { API_URL, BASE_URL } from "../constant";
import { getCookie } from "../utils/cookies";
import {
  KabupatenGetResponse,
  KecamatanGetResponse,
  KelurahanGetResponse,
  ProvinceGetResponse,
  UploadDaerahResponse,
  ZipCodeGetResponse,
} from "../interfaces/util";

class UtilService {
  public static async uploadDaerah(
    body: FormData
  ): Promise<UploadDaerahResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.uploadDaerahList}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: UploadDaerahResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getProvince(): Promise<ProvinceGetResponse> {
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.getProvince}`, {
      method: "GET",
    });

    const response: ProvinceGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getKabupaten(
    provinsi: string
  ): Promise<KabupatenGetResponse> {
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getKabupaten}?provinsi=${provinsi}`,
      {
        method: "GET",
      }
    );

    const response: KabupatenGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getKecamatan(
    provinsi: string,
    kabupaten: string
  ): Promise<KecamatanGetResponse> {
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getKecamatan}?provinsi=${provinsi}&kabupaten=${kabupaten}`,
      {
        method: "GET",
      }
    );

    const response: KecamatanGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getKelurahan(
    provinsi: string,
    kabupaten: string,
    kecamatan: string
  ): Promise<KelurahanGetResponse> {
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getKelurahan}?provinsi=${provinsi}&kabupaten=${kabupaten}&kecamatan=${kecamatan}`,
      {
        method: "GET",
      }
    );

    const response: KelurahanGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getZipcode(
    provinsi: string,
    kabupaten: string,
    kecamatan: string,
    kelurahan: string
  ): Promise<ZipCodeGetResponse> {
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getZipcode}?provinsi=${provinsi}&kabupaten=${kabupaten}&kecamatan=${kecamatan}&kelurahan=${kelurahan}`,
      {
        method: "GET",
      }
    );

    const response: ZipCodeGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default UtilService;

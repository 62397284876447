import React, { ChangeEvent, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import NavBar from "../../component/Navbar";
import SalesTargetService from "../../services/SalesTarget";
import RepetitiveDetailSalesTarget from "../../component/RepetitiveDetailSalesTarget";
import checkNumericInput from "../../utils/checkNumericInput";

const ComissionDetail = () => {
  const { idComission } = useParams();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [comissionName, setComissionName] = useState<string>("");
  const [comissionBrand, setComissionBrand] = useState<string>("");
  const [comissionTarget, setComissionTarget] = useState<number[]>([0]);
  const [comissionRate, setComissionRate] = useState<number[]>([0]);
  const [editComissionTarget, setEditComissionTarget] = useState<number[]>([0]);
  const [editComissionRate, setEditComissionRate] = useState<number[]>([0]);

  const addNewTargetForm = () => {
    setEditComissionTarget([...editComissionTarget, 0]);
    setEditComissionRate([...editComissionRate, 0]);
  };

  const removeFormId = (id: number) => {
    const tempEditTargetComission = [...editComissionTarget];
    const tempEditTargetComrate = [...editComissionRate];
    tempEditTargetComission.splice(id, 1);
    tempEditTargetComrate.splice(id, 1);
    setEditComissionTarget(tempEditTargetComission);
    setEditComissionRate(tempEditTargetComrate);
  };

  const changeTargetComission = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    const tempEditTargetComission = [...editComissionTarget];
    tempEditTargetComission[id] = Number(newValue);
    setEditComissionTarget(tempEditTargetComission);
  };

  const changeTargetComRate = (e: ChangeEvent, id: number) => {
    const { value } = e.target as HTMLInputElement;
    const newValue = checkNumericInput(value);
    const tempEditTargetComRate = [...editComissionRate];
    tempEditTargetComRate[id] = Number(newValue);
    setEditComissionRate(tempEditTargetComRate);
  };

  const revertEditState = () => {
    setEditComissionRate(comissionRate);
    setEditComissionTarget(comissionTarget);
  };

  const saveEditedData = () => {
    for (let i = 0; i < editComissionTarget.length; i++) {
      if (editComissionTarget[i] === 0) {
        alert(`Please input target comission number #${i + 1}`);
        return false;
      }
      if (editComissionRate[i] === 0) {
        alert(`Please input comission rate for target number #${i + 1}`);
        return false;
      }
      if (i > 0 && editComissionTarget[i] <= editComissionTarget[i - 1]) {
        alert(`Please input a bigger value for target #${i + 1}`);
        return false;
      }
      if (i > 0 && editComissionRate[i] <= editComissionRate[i - 1]) {
        alert(`Please input a bigger value for comrate #${i + 1}`);
        return false;
      }
    }
    const bodyForm = new FormData();
    bodyForm.append("comissionTarget", editComissionTarget.toString());
    bodyForm.append("comissionRate", editComissionRate.toString());
    SalesTargetService.editSalesTarget(Number(idComission) || 0, bodyForm)
      .then((res) => {
        if (res.status === 1) {
          alert("Successfully edit the sales target");
          window.location.reload();
          return true;
        }
      })
      .catch((e) => alert(e));
  };

  const cancelEdit = () => {
    setIsEditing(false);
    revertEditState();
  };

  const renderSalesTarget = () => {
    if (isEditing) {
      return editComissionTarget.map((com, index) => {
        return (
          <RepetitiveDetailSalesTarget
            id={index}
            brandName={comissionBrand}
            target={com}
            comrate={editComissionRate[index]}
            removeFormId={removeFormId}
            changeTargetComission={changeTargetComission}
            changeTargetComRate={changeTargetComRate}
          />
        );
      });
    }
    return comissionTarget.map((com, index) => {
      return (
        <RepetitiveDetailSalesTarget
          id={index}
          brandName={comissionBrand}
          target={com}
          readonly={!isEditing}
          comrate={comissionRate[index]}
          removeFormId={removeFormId}
          changeTargetComission={changeTargetComission}
          changeTargetComRate={changeTargetComRate}
        />
      );
    });
    // return comissionTarget.map((com, index) => {
    //   return (
    //     <RepetitiveDetailSalesTarget
    //     id={index}
    //     readonly={isEd}
    //     brandName,
    //     target,
    //     comrate,
    //     removeFormId,
    //     changeTargetComission,
    //     changeTargetComRate,
    //     />
    //   );
    // });
  };

  useEffect(() => {
    if (idComission) {
      SalesTargetService.getSalesTargetDetail(Number(idComission)).then(
        (res) => {
          if (res.data) {
            setComissionName(res.data.name);
            setComissionBrand(res.data.brand);
            const salesTargetTemp = res.data.salesTargetMapping.map(
              (stm) => stm.target
            );
            const salesComRateTemp = res.data.salesTargetMapping.map(
              (stm) => stm.comrate
            );
            setComissionTarget(salesTargetTemp);
            setComissionRate(salesComRateTemp);
            setEditComissionTarget(salesTargetTemp);
            setEditComissionRate(salesComRateTemp);
          }
        }
      );
    }
  }, []);
  return (
    <>
      <NavBar />
      <br />
      <Container fluid>
        <Row className="justify-content-end">
          <Col md="4" xs="12" className="d-flex justify-content-end">
            {!isEditing && (
              <>
                <Button
                  onClick={() => setIsEditing(true)}
                  className="notPrinted"
                >
                  Edit Sales Target &amp; Comission
                </Button>
              </>
            )}
            {isEditing && (
              <>
                <Button onClick={() => cancelEdit()} variant="outline-primary">
                  Cancel
                </Button>
                &nbsp;
                <Button onClick={saveEditedData}>Save</Button>
              </>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Form.Label>Sales Name</Form.Label>
        <Form.Control type="text" value={comissionName} readOnly />
        <Form.Label>Brand</Form.Label>
        <Form.Control type="text" value={comissionBrand} readOnly />
        {renderSalesTarget()}
        {isEditing && (
          <Button onClick={addNewTargetForm}>Add New Target</Button>
        )}
      </Container>
    </>
  );
};

export default ComissionDetail;

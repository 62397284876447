import { API_URL, BASE_URL } from "../constant";
import {
  SalesTargetCreateResponse,
  SalesTargetDeleteResponse,
  SalesTargetDetailResponse,
  SalesTargetEditResponse,
  SalesTargetGetResponse,
  SalesTargetUserGetResponse,
} from "../interfaces/salestarget";
import { getCookie } from "../utils/cookies";

class SalesTargetService {
  public static async createSalesTarget(
    body: FormData
  ): Promise<SalesTargetCreateResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.createSalesTarget}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: SalesTargetCreateResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error(response.error);
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getSalesTarget(
    params: string
  ): Promise<SalesTargetGetResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getSalesTarget}${params}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: SalesTargetGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getUserSalesTarget(): Promise<SalesTargetUserGetResponse> {
    const cookiesUser = getCookie("user");
    const userId = getCookie("userId");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getFullSalesTargetUser}/${userId}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: SalesTargetUserGetResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async getSalesTargetDetail(
    id: number
  ): Promise<SalesTargetDetailResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getSalesTargetDetail}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: SalesTargetDetailResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async editSalesTarget(
    id: number,
    body: FormData
  ): Promise<SalesTargetEditResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.editSalesTarget}/${id}`,
      {
        method: "POST",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
        body,
      }
    );

    const response: SalesTargetEditResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteSalesTarget(
    id: number
  ): Promise<SalesTargetDeleteResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteSalesTarget}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: SalesTargetDeleteResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default SalesTargetService;

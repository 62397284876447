import { API_URL, BASE_URL } from "../constant";
import {
  GetFinishingResponse,
  CreateFinishingResponse,
  DeleteFinishingResponse,
} from "../interfaces/finishing";
import { getCookie } from "../utils/cookies";

class FinishingService {
  public static async getFinishing(
    filterFinishingName?: number
  ): Promise<GetFinishingResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.getFinishing}${
        filterFinishingName ? `?finishingId=${filterFinishingName}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: GetFinishingResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("User not found");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async createNewFinishing(
    body: FormData
  ): Promise<CreateFinishingResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(`${BASE_URL}${API_URL.createFinishing}`, {
      method: "POST",
      body,
      headers: {
        "x-custom-header": cookiesUser ? cookiesUser : "",
      },
    });

    const response: CreateFinishingResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Error while creating new comision category");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }

  public static async deleteFinishing(
    id: number
  ): Promise<DeleteFinishingResponse> {
    const cookiesUser = getCookie("user");
    const fetchResponse = await fetch(
      `${BASE_URL}${API_URL.deleteFinishing}/${id}`,
      {
        method: "GET",
        headers: {
          "x-custom-header": cookiesUser ? cookiesUser : "",
        },
      }
    );

    const response: DeleteFinishingResponse = await fetchResponse.json();
    if (response.status === 0) {
      throw new Error("Error while creating new comision category");
    }
    if (response.status !== 0) {
      return response;
    }
    throw new Error("Abnormal response");
  }
}

export default FinishingService;
